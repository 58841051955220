import React, { useEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import moment from 'moment';
const useStyles = makeStyles(styles);


function descendingComparator(a, b, orderBy) {

  var formats = [
    moment.ISO_8601,
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "YYYY/MM/DD",
    "DD/MM/YYYY,HH:mm:ss",
    "MM/DD/YYYY,HH:mm:ss",
    "YYYY/MM/DD,HH:mm:ss",
]


debugger;
  if ((a[orderBy] != null && a[orderBy] != undefined) && (b[orderBy] != null && b[orderBy] != undefined)
    && (a[orderBy].props != null && a[orderBy].props != undefined)
    && (b[orderBy].props != null && b[orderBy].props != undefined)
    && (a[orderBy].props.children != null && a[orderBy].props.children != undefined)
    && (b[orderBy].props.children != null && b[orderBy].props.children != undefined)) {

    if (a[orderBy].props.type === "date" || b[orderBy].props.type === "date") {

      var d1Object = moment(a[orderBy].props.children, "DD/MM/YYYY,hh:mm:ss"); // 1st argument - string, 2nd argument - format
      var d1 = d1Object.toDate();

      var d2Object = moment(b[orderBy].props.children, "DD/MM/YYYY,hh:mm:ss"); // 1st argument - string, 2nd argument - format
      var d2 = d2Object.toDate();
      if (d1.getTime() > d2.getTime()) {
        return -1;
      } else if (d1.getTime() < d2.getTime()) {
        return 1;
      } else {
        return 0;
      }

    }
    else {
      var isValidA = moment(a[orderBy].props.children,formats, true).isValid()
      var isValidB = moment(b[orderBy].props.children,formats, true).isValid()
    if(isValidA && isValidB){
      var d1Object = moment(a[orderBy].props.children !== undefined ?a[orderBy].props.children: new Date(), "DD/MM/YYYY,hh:mm:ss");
      var d1 = d1Object.toDate();

      var d2Object = moment(b[orderBy].props.children !== undefined ? b[orderBy].props.children : new Date(), "DD/MM/YYYY,hh:mm:ss"); // 1st argument - string, 2nd argument - format
      var d2 = d2Object.toDate();

      if (d1.getTime() > d2.getTime()) {
        return -1;
      } else if (d1.getTime() < d2.getTime()) {
        return 1;
      } else {
        return 0;
      }
    }else {
      if (b[orderBy].props.children < a[orderBy].props.children) {
        return -1;
      }
      if (b[orderBy].props.children > a[orderBy].props.children) {
        return 1;
      } else {
        return 0;
      }
    }}
  }else if ((a[orderBy] != null && a[orderBy] != undefined) && (b[orderBy] != null && b[orderBy] != undefined)
    && (a[orderBy].props != null && a[orderBy].props != undefined)
    && (b[orderBy].props != null && b[orderBy].props != undefined)) {
    if (a[orderBy].props.type === "date" || b[orderBy].props.type === "date") {

      var d1Object = moment(a[orderBy].props.children !== undefined ? a[orderBy].props.children : new Date(), "DD/MM/YYYY,hh:mm:ss");
      var d1 = d1Object.toDate();

      var d2Object = moment(b[orderBy].props.children !== undefined ? b[orderBy].props.children : new Date(), "DD/MM/YYYY,hh:mm:ss"); // 1st argument - string, 2nd argument - format
      var d2 = d2Object.toDate();

      console.log(d1, d2)
      if (d1.getTime() > d2.getTime()) {
        return -1;
      } else if (d1.getTime() < d2.getTime()) {
        return 1;
      } else {
        return 0;
      }

    }
  } else if (typeof a[orderBy] == "string" || typeof a[orderBy] == "number" && typeof b[orderBy] == "string" || typeof b[orderBy] == "number") {

    var isValidA = moment(a[orderBy],formats, true).isValid()
    var isValidB = moment(b[orderBy],formats, true).isValid()

  if(isValidA && isValidB){
    var d1Object = moment(a[orderBy] !== undefined ?a[orderBy]: new Date(), "DD/MM/YYYY,hh:mm:ss");
    var d1 = d1Object.toDate();

    var d2Object = moment(b[orderBy] !== undefined ? b[orderBy] : new Date(), "DD/MM/YYYY,hh:mm:ss"); // 1st argument - string, 2nd argument - format
    var d2 = d2Object.toDate();

    if (d1.getTime() > d2.getTime()) {
      return -1;
    } else if (d1.getTime() < d2.getTime()) {
      return 1;
    } else {
      return 0;
    }
  }

    else{

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    } else {
      return 0;
    }}
  }
}
function getComparator(order, orderBy) {

  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}




export default function CustomTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState(props.order ? props.order : "desc");
  const [orderBy, setOrderBy] = React.useState(props.orderBy ? props.orderBy : 3);


  useEffect(() => {
    let rowsPerPage = localStorage.getItem('rowsPerPage');
    if (rowsPerPage) {
      setRowsPerPage(rowsPerPage);
    }
  }, []);

  const handleChangePage = (event, newPage) => {

    setPage(newPage);
    if (listener !== undefined && listener.handleSelected) {
      listener.handlePgeChaned(event, newPage, rowsPerPage);
    }

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);

    let rowsPerPage = localStorage.getItem('rowsPerPage');
    if (rowsPerPage) {
      localStorage.removeItem('rowsPerPage');
      localStorage.setItem('rowsPerPage', event.target.value);
    } else {
      localStorage.setItem('rowsPerPage', event.target.value);
    }
  };


  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const {
    tableHead,
    tableData,
    tableHeaderColor,
    hover,
    colorsColls,
    coloredColls,
    customCellClasses,
    customClassesForCells,
    striped,
    tableShopping,
    customHeadCellClasses,
    customHeadClassesForCells,
    listener,
    totalElements,
    offset,
    pageNumber
  } = props;
  if (tableHead) {
    for (let i = 0; i < tableHead.length; i++) {
      tableHead[i] = <div style={{ fontFamily: 'Helvetica', fontSize: '20px', textAlign: 'center' }}>{tableHead[i]}</div>
    }
  }

  return (
    <div className={classes.tableResponsive}>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table className={classes.table}>
            {tableHead !== undefined ? (
              <TableHead className={classes[tableHeaderColor]}>
                <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
                  {tableHead.map((prop, key) => {
                    const tableCellClasses =
                      classes.tableHeadCell +
                      " " +
                      classes.tableCell +
                      " " +
                      cx({
                        [customHeadCellClasses[
                          customHeadClassesForCells.indexOf(key)
                        ]]: customHeadClassesForCells.indexOf(key) !== -1,
                        [classes.tableShoppingHead]: tableShopping,
                        [classes.tableHeadFontSize]: !tableShopping
                      });
                    return (
                      <TableCell className={tableCellClasses} key={key} sortDirection={order}>
                        <TableSortLabel active={orderBy === key}
                          // direction={orderBy === key? order : "asc"}
                          direction={order != undefined ? order : "asc"}
                          onClick={createSortHandler(key)}
                        >
                          {prop.label}
                          {prop}
                        </TableSortLabel>
                      </TableCell>
                    );

                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy)).slice(Number(page) * Number(rowsPerPage), Number(page) * Number(rowsPerPage) + Number(rowsPerPage)).map((prop, key) => {
                var rowColor = "";
                var rowColored = false;
                if (prop.color !== undefined) {
                  rowColor = prop.color;
                  rowColored = true;
                  prop = prop.data;
                }
                const tableRowClasses = cx({
                  [classes.tableRowBody]: true,
                  [classes.tableRowHover]: hover,
                  [classes[rowColor + "Row"]]: rowColored,
                  [classes.tableStripedRow]: striped && key % 2 === 0
                });
                if (prop.total) {
                  return (
                    <TableRow key={key} hover={hover} className={tableRowClasses}>
                      <TableCell
                        className={classes.tableCell}
                        colSpan={prop.colspan}
                      />
                      <TableCell
                        className={classes.tableCell + " " + classes.tableCellTotal}
                      >
                        Total
                  </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableCellAmount
                        }
                      >
                        {prop.amount}
                      </TableCell>
                      {tableHead.length - (prop.colspan - 0 + 2) > 0 ? (
                        <TableCell
                          className={classes.tableCell}
                          colSpan={tableHead.length - (prop.colspan - 0 + 2)}
                        />
                      ) : null}
                    </TableRow>
                  );
                }
                if (prop.purchase) {
                  return (
                    <TableRow key={key} hover={hover} className={tableRowClasses}>
                      <TableCell
                        className={classes.tableCell}
                        colSpan={prop.colspan}
                      />
                      <TableCell
                        className={classes.tableCell + " " + classes.right}
                        colSpan={prop.col.colspan}
                      >
                        {prop.col.text}
                      </TableCell>
                    </TableRow>
                  );
                }
                return (
                  <TableRow
                    key={key}
                    hover={hover}
                    className={classes.tableRow + " " + tableRowClasses}
                  >
                    {prop.map((prop, key) => {
                      const tableCellClasses =
                        classes.tableCell +
                        " " +
                        cx({
                          [classes[colorsColls[coloredColls.indexOf(key)]]]:
                            coloredColls.indexOf(key) !== -1,
                          [customCellClasses[customClassesForCells.indexOf(key)]]:
                            customClassesForCells.indexOf(key) !== -1
                        });
                      return (
                        <TableCell className={tableCellClasses} key={key}>
                          {prop}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalElements != null ? totalElements : tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: []
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool,
  // onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
};
