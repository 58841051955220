import React from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTabs from "components/COMMON/Tabs/CustomTabs";
import BarChart from "@material-ui/icons/BarChart";

import * as AuthService from 'services/AuthService.js';

import CustomersList from 'components/COMMON/CustomersList';
import ScoreVisualizer from 'components/COMMON/ScoreVisualizer';

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "26px",
    fontWeight: "450",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

export const CUSTOMERS_LIST_TAB_INDEX = 0;
export const CUSTOMER_SCORING_TAB_INDEX = 1;

class CustomersManagement extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.customTabsRef = React.createRef();
  }

  getInitialState() {
    return {
      selectedTab: (CUSTOMERS_LIST_TAB_INDEX),
      currentLoggedUserCanManageScoring: (AuthService.authenticathedUserCanManageCustomersScoring()),
      showCustomerScoringTab: false,
      selectedCustomerForScoring: '',
      iconToShowOnScoringTab: null
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  getDynamicTabs() {
    let tabs = [];
    tabs.push(
      {
        tabName: "Scoring del cliente",
        tabIcon: null,
        tabContent: (
          <ScoreVisualizer readOnly={!this.state.currentLoggedUserCanManageScoring}
                    customTabsRef={this.customTabsRef}/>
        )
      }
    );
    return tabs;
  }


  getActiveTabs() {
    let tabs = [];
    tabs.push(
      {
        tabName: "Lista dei clienti valutati",
        tabIcon: BarChart,
        tabContent: (
          <CustomersList readOnly={!this.state.currentLoggedUserCanManageScoring}
                         customTabsRef={this.customTabsRef}/>
        )
      }
    );
    return tabs;
  }

 
  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>   
          <CustomTabs style={styles.cardTitleWhite}
            selectedTabIndex={this.state.selectedTab}
            headerColor="info"
            tabs={this.getActiveTabs()}
            dynamicTabs={this.getDynamicTabs()}
            showDynamicTabs={this.state.showCustomerScoringTab}
            itemForDynamicTabs={this.state.selectedCustomerForScoring}
            iconForDynamicTabs={this.state.iconToShowOnScoringTab}
            ref={this.customTabsRef}
          />      
        </GridItem>
      </GridContainer>
    );
  }
}

export default CustomersManagement;
