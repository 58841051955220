
/*
<path fill="#C9D3D7" d="M62.446,117.558c-31.706,0-57.5-25.57-57.5-57s25.794-57,57.5-57s57.5,25.57,57.5,57
    S94.151,117.558,62.446,117.558z M62.446,18.558c-23.435,0-42.5,18.841-42.5,42s19.065,42,42.5,42s42.5-18.841,42.5-42
    S85.88,18.558,62.446,18.558z"/> */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

import Description from "@material-ui/icons/Description";
//import Logo from 'assets/img/TEE/logo.svg';
import Soci from 'assets/img/TEE/soci.svg';
import Processo from 'assets/img/TEE/processo.svg';
import Posizione from 'assets/img/TEE/posizione.svg';
import Anagrafica from 'assets/img/TEE/anagrafica.svg';
import Info from "@material-ui/icons/SettingsApplications";
import Logo from "assets/img/common/reporting-mini-logo.jpg";

import {
    grayColor,
    roseColor,
    primaryColor,
    infoColor,
    cascoInfoColor,
    cascoNeutralColor,
    appMainColors,
    successColor,
    warningColor,
    dangerColor,
    whiteColor,
    blackColor,
    twitterColor,
    facebookColor,
    googleColor,
    linkedinColor,
    pinterestColor,
    youtubeColor,
    tumblrColor,
    behanceColor,
    dribbbleColor,
    redditColor,
    hexToRgb
  } from "assets/jss/material-dashboard-pro-react.js";

function CommonCustomIcon(props) {

    const scoringImages = [
        { name: 'Description', icon: Description, "scoreType": 0, "id": 1 },
        { name: 'Logo', icon: Logo, "scoreType": 0, "id": 2 },
        { name: 'Soci', icon: Soci, "scoreType": 0, "id": 3 },
        { name: 'Processo', icon: Processo, "scoreType": 0, "id": 4 },
        { name: 'Posizione', icon: Posizione, "scoreType": 0, "id": 5 },
        { name: 'Anagrafica', icon: Anagrafica, "scoreType": 0, "id": 6 },

    ];


    function getScoringImage(icon) {
        for (var i = 0; i < scoringImages.length; i++) {
            if (icon == scoringImages[i].name) {
                return scoringImages[i].icon;
            }
        }
    }

    function getSizeImage(type) {
        if (type != null && type == "large") {
            return { width: '230px', height: '69px' };
        }
        return { width: '140px', height: 'auto' };
    }

    return (
        <img src={getScoringImage(props.icon)}  style={getSizeImage(props.type)} />
    );

}

export default CommonCustomIcon;