import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTabs from "components/COMMON/Tabs/CustomTabs";

import NewRequest from "components/COMMON/NewRequest";
import NewSaveIcon from "@material-ui/icons/Save";
import SearchResultIcon from "@material-ui/icons/Pageview";

import ScoreVisualizer from 'components/COMMON/ScoreVisualizer';

const localStyles = {
  icon: {
      verticalAlign: "middle",
      textAlign: "center",
      width: "23px",
      height: "23px",
      top: "0px",
      left: "2px",
      position: "relative"
  },
  actionButton: {
      margin: "5px 5px 5px 5px",
      padding: "0px",
      width: "30px",
      height: "30px",
      minWidth: "auto",
      fontSize: "20px"
  },
  dialogConfirmButton: {
      margin: "0 0 0 5px",
      padding: "20px",
      width: "80px",
      height: "auto",
      minWidth: "auto"
  },
  dialogCancelButton: {
      margin: "0 0 0 5px",
      padding: "7px",
      width: "auto",
      height: "auto",
      minWidth: "auto"
  }
}


export const SEARCH_TAB_INDEX = 0;
export const SEARCH_RESULT_TAB_INDEX = 1;

const styles = {
  ...localStyles,
  ...dashboardStyles
}


class TeeSingleRequest extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.customTabsRef = React.createRef();
  }

  getInitialState() {
    return {
      selectedTab: (SEARCH_TAB_INDEX)
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }


  getDynamicTabs() {
    let tabs = [];

    return tabs;
  }

  getActiveTabs() {
    let tabs = [];
    tabs.push(
      {
        tabName: "Nuova richiesta",
        tabIcon: NewSaveIcon,
        tabContent: (
          <NewRequest readOnly={false}
                    createOrModify={true}
                    customTabsRef={this.customTabsRef}/>
        )
      }
    );    
    return tabs;
  }

 
  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs style={styles.cardTitleWhite}
            headerColor="info"
            tabs={this.getActiveTabs()}
            dynamicTabs={this.getDynamicTabs()}
            selectedTabIndex={this.state.selectedTab}
            showDynamicTabs={false}
            itemForDynamicTabs={null}
            ref={this.customTabsRef}
            //property to show inforamtion on dynamic tab
            itemObjForDynamicTabs={null}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(TeeSingleRequest);
