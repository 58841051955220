import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
//import Chartist from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";
import ResetFilters from "@material-ui/icons/LayersClear";
import ReloadData from "@material-ui/icons/CloudDownload";
import ToggleOn from "@material-ui/icons/ToggleOn";
import ToggleOff from "@material-ui/icons/ToggleOff";
import SweetAlert from "react-bootstrap-sweetalert";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Tooltip from '@material-ui/core/Tooltip';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CommonDropdown from 'components/COMMON/CommonDropDown.js';
import * as AuthService from 'services/AuthService.js';
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import checkBoxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import FilterableMultiRequestsManagement from "components/COMMON/FilterableMultiRequestManagement";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from '@material-ui/core/FormControl';
import CustomInput from "components/CustomInput/CustomInput.js";
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';

import axios from "axios"

import UploadFile from "@material-ui/icons/InsertDriveFile";
import * as AxiosService from 'layouts/AxiosService.js';

import {axiosApiCaller} from 'layouts/AxiosService';
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
// import DateFnsUtils from '@date-io/date-fns';

import moment from "moment";


const localStyles = {
    icon: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "23px",
        height: "23px",
        top: "0px",
        left: "2px",
        position: "relative"
    },
    actionButton: {
        margin: "5px 5px 5px 5px",
        padding: "0px",
        width: "30px",
        height: "30px",
        minWidth: "auto",
        fontSize: "20px"
    },
    dialogConfirmButton: {
        margin: "0 0 0 5px",
        padding: "20px",
        width: "80px",
        height: "auto",
        minWidth: "auto"
    },
    dialogCancelButton: {
        margin: "0 0 0 5px",
        padding: "7px",
        width: "auto",
        height: "auto",
        minWidth: "auto"
    }
}

const styles = {
    ...localStyles,
    ...dashboardStyles,
    ...checkBoxStyles
}

// COSTANTI PER I VALORI NON DEFINITI O NULLI
export const NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE = '(Non disponibile)';
export const NULL_STRATEGIA_SUBSTITUTION_VALUE = 'Nessuna Strategia';
export const NULL_STATO_SUBSTITUTION_VALUE = 'Nessun Stato di Elaborazione';

const AUTO_CLOSE_TIME_DENIED_OPERATION = 4000;

var delays = 80,
    durations = 500;
var delays2 = 80,
    durations2 = 500;


class Accounting extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.lastRefreshTime = new Date();

    this.onKeyUp = this.onKeyUp.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.deniedOperation = this.deniedOperation.bind(this);
  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.extractAllCustomersListFromBackend();
    }
  }

    getInitialState() {
        let startDate=new Date();
        startDate.setHours(0,0,0,0);
        return {
            alert: null,
            usersSessionsList: [],
            customersList: [],
            currentIncompletedRequestList: [],
            currentIncompletedRequestId: "",
            incompletesReqDivIsVisible: false,
            usersSessionsChartLabels: [],
            usersSessionsChartSeries: [],
            usersSessionsChartMax: 100,
            currentMinutesRefreshDelay: 0,
            currentLoggedUserCanManageVisualizeResult: (AuthService.authenticathedUserCanManageUsers()),
            forzaInvio: false,
            startDate: startDate,
            endDate: new Date()
        };
    }

    hideAlert  ()  {
        this.setState({ alert: null });
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.extractAllCustomersListFromBackend();
    }

    componentWillUnmount() {
    }


handleChangeName(event) {
  let newValue = event.target.value.trim();
  this.setState({ name: newValue });
  if (newValue!= null) {
    if (newValue.length<3) {
      this.setState({ nameValid: false });
      this.setState({ errorMessage: 'La lunghezza della denominazione non è valida' });
    } else {
      this.setState({ nameValid: true });
      this.setState({ errorMessage: null });
    }
  } else {
    this.setState({ nameValid: false });
    this.setState({ errorMessage: 'La denominazione non può essere vuota' });
  }
}
    handleShowMonitoredChange  (event)  {
        this.setState({ showMonitored: event.target.checked });
        if (event.target.checked) {
            this.showMonitoredLabel = "Includi le posizioni monitorate";
        } else {
            this.showMonitoredLabel = "Escludi le posizioni monitorate";
        }
    };


    handleFiltering(filter) {
    }

    resetFilters() {
        this.setState(
            {
                showMonitored: true
            }
        );
    }

    render() {
        const { classes } = this.props;
        const uploadDivStyle = {margin: "40px 0px 40px 8px", paddingBottom:"28px", background:"white", boxShadow: "0px 0px 6px rgb(210, 210, 210)", borderRadius:"7px"};
        const uploadDivHeaderStyle = {margin: "auto", position: "relative", bottom:"16px", width:"97%", height:"45px", background:"linear-gradient(to right, rgb(140, 177, 73),rgb(192, 213, 149))", borderRadius:"4px"};
        const pHeaderStyle = {paddingTop:"10px", color: "white", fontSize: "15px", fontWeight: "450", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", textAlign: "center"};
        //Attenzione il bottone dettaglio non viene passato nella lista dei fields
        //let headersList=["Dowload file", "Responses", "ID Request","State","Creation Date", "Last Modified","Requests Number","Results Number", "Results"];
        let headersList;
        if(this.state.currentLoggedUserCanManageVisualizeResult){
            headersList=["Codice Fiscale","Ragione sociale","Stato","Data caricamento",  "Risultati",  "Dossier", "Conservatoria","V2","Xls V2"];
        } else{
            headersList=["Codice Fiscale","Ragione sociale","Stato","Data caricamento", "Risultati",  "Dossier", "Conservatoria","V2","Xls V2"];
        }
        let fieldsList=["codiceFiscale","ragioneSociale","elaborationState","receivedDate","responseDownload","responsePdf","responsePdfConservatoria","responseV2","responseXlsV2"];


        let showMonitoredControl = null;
        if (this.state.showMonitored === true) {
            showMonitoredControl = (<a href="#"><ToggleOn onClick={(event) => this.handleShowNotMonitoredChange(event)} style={{ float: 'right', margin: '0px 0px 0px 0px', width: '34px', height: '34px' }} /></a>);
        }

        let incompletesDivStyle = {
            display: this.state.incompletesReqDivIsVisible? "block" : "none"
        }

        const incompletesHeaderStyle = {
            margin: "auto",
            position: "relative",
            bottom:"16px",
            width:"97%",
            height:"45px",
            background:"linear-gradient(to right, rgb(23, 105, 255),rgb(156, 191, 255))",
            borderRadius:"4px"
        };

        let annulledList = this.state.currentIncompletedRequestList.filter( req => req.elaborationState == "ANNULLATO" )
        let rowsAnnulledList = [];
        rowsAnnulledList = annulledList.map(req =>                     
            <tr>
                <td style={{paddingBottom:"10px"}}>{req.ragioneSociale == null || req.ragioneSociale == ""? <b style={{color:"red"}}>MISSING</b>: req.ragioneSociale}</td>&nbsp;&nbsp;&nbsp;
                <td style={{paddingBottom:"10px"}}>{req.customerId == null || req.ragioneSociale == ""? <b style={{color:"red"}}>MISSING</b>: req.customerId}</td>&nbsp;&nbsp;&nbsp;
                <td style={{paddingBottom:"10px"}}>{req.elaborationMessage}</td>&nbsp;&nbsp;&nbsp;
            </tr>
        );

        let annulledReqTable = <table style={{fontSize:"0.9em"}}>
            <tr>
                <th style={{paddingBottom:"14px"}}>Ragione Sociale</th>&nbsp;&nbsp;&nbsp;
                <th style={{paddingBottom:"14px"}}>Customer ID</th>&nbsp;&nbsp;&nbsp;
                <th style={{paddingBottom:"14px"}}>Causa Annullamento</th>&nbsp;&nbsp;&nbsp;
            </tr>
            {rowsAnnulledList}
        </table>


        let suspendedList = this.state.currentIncompletedRequestList.filter( req => req.elaborationState == "SOSPESO" )
        let rowsSuspendedList = [];
        rowsSuspendedList = suspendedList.map(req =>                     
            <tr>
                <td style={{paddingBottom:"10px"}}>{req.ragioneSociale == null || req.ragioneSociale == ""? <b style={{color:"red"}}>MISSING</b>: req.ragioneSociale}</td>&nbsp;&nbsp;&nbsp;
                <td style={{paddingBottom:"10px"}}>{req.paeseProvincia == null || req.paeseProvincia == ""? <b style={{color:"red"}}>MISSING</b>: req.paeseProvincia}</td>&nbsp;&nbsp;&nbsp;
                <td style={{paddingBottom:"10px"}}>{req.localita == null || req.localita == ""? <b style={{color:"red"}}>MISSING</b>: req.localita}</td>&nbsp;&nbsp;&nbsp;
                <td style={{paddingBottom:"10px"}}>{req.indirizzo == null || req.indirizzo == ""? <b style={{color:"red"}}>MISSING</b>: req.indirizzo}</td>&nbsp;&nbsp;&nbsp;
            </tr>
        );

        let suspendedReqTable = <table style={{fontSize:"0.9em"}}>
            <tr>
                <th style={{paddingBottom:"14px"}}>Ragione Sociale</th>&nbsp;&nbsp;&nbsp;
                <th style={{paddingBottom:"14px"}}>Paese</th>&nbsp;&nbsp;&nbsp;
                <th style={{paddingBottom:"14px"}}>Località</th>&nbsp;&nbsp;&nbsp;
                <th style={{paddingBottom:"14px"}}>Indirizzo</th>&nbsp;&nbsp;&nbsp;
            </tr>
            {rowsSuspendedList}
        </table>

        let anulledRequestCommentP =<p style={{fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize:"0.95em", fontWeight:"bold"}}>
                                         Non sono presenti richieste annullate
                                    </p>
        let suspendedRequestCommentP =<p style={{fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize:"0.95em", fontWeight:"bold"}}>
                                        Non sono presenti richieste sospese
                                    </p> 
        return (
            <div>
                {this.state.alert}

                <div style={incompletesDivStyle} className="incompletes-div-style">
                    
                    <div style={incompletesHeaderStyle}>
                        <p style={pHeaderStyle}>LISTA RICHIESTE INCOMPLETE</p>
                    </div>
                    
                    <p style={{fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize:"1.03em", fontWeight:"bold", textAlign:"center"}}>RICHIESTE ANNULLATE:</p>
                    { annulledList.length > 0 ? annulledReqTable : anulledRequestCommentP}

                    <br />

                    <p style={{fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize:"1.03em", fontWeight:"bold", textAlign:"center"}}>RICHIESTE SOSPESE:</p>
                    { suspendedList.length > 0 ? suspendedReqTable : suspendedRequestCommentP}
                    
                    
                    <br />
                    <div style={{display: suspendedList.length > 0 ? "block" : "none"}}>
                        <p style={{textAlign:"center", fontSize:"0.9em", fontWeight:"bold"}}>
                            Vuoi processare le richieste sospese nonostante i dati siano incompleti?
                        </p>
                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                            <Button 
                                style={{marginLeft: "70px", fontSize: '13px'}}                   
                                color="secondary"
                                onClick={() => { this.setState({incompletesReqDivIsVisible:false}) }}
                                round>
                                Chiudi&nbsp;
                            </Button>
                            &nbsp;&nbsp;&nbsp;

                            <Button 
                                disabled={this.state.forzaInvio}
                                style={{marginRight: "70px", fontSize: '13px'}}                   
                                color="behance"
                                 onClick={() => 
                                    {
                                         this.sendSuspendedRequests(this.state.currentIncompletedRequestId)
                                         this.setState({forzaInvio:true})
                                        }}
                                round>
                                Forza Invio&nbsp;
                             </Button>
                        </div>
                    </div>
                    <div style={{display: suspendedList.length > 0 ? "none" : "block", textAlign:"center"}}>
                            <Button 
                                style={{fontSize: '13px'}}                   
                                color="secondary"
                                onClick={() => { this.setState({incompletesReqDivIsVisible:false}) }}
                                round>
                                Chiudi&nbsp;
                            </Button>
                    </div>
                </div>

                <GridContainer>
                    <FormControl fullWidth
                              onKeyPress={this.onKeyUp}>
                        <GridItem xs={12} sm={12} md={12} lg={12}
                            style={{fontSize: '15px', margin: '0px 30px 0px 30px'}}>
                            {
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="Data inizio"
                    value={this.state.startDate}
                    maxDate={this.state.endDate}
                    onChange={(event) => {
                      this.handleChangeStartDate(event);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="Data fine"
                    value={this.state.endDate}
                    maxDate={new Date()}
                    minDate={this.state.startDate}
                    onChange={(event) => {
                      this.handleChangeEndDate(event);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />

                  <Button
                    style={{ fontSize: "15px", margin: "0px 30px 0px 30px" }}
                    color="success"
                    onClick={() => {
                      this.extractAllCustomersListFromBackend();
                    }}
                    onK
                    round
                    class="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-91 makeStyles-success-101 makeStyles-round-122"
                  >
                    Filtra&nbsp;&nbsp;&nbsp;
                    <SearchIcon style={{ fontSize: "28px" }} />
                  </Button>
                </MuiPickersUtilsProvider>
              }
                        </GridItem>
                    </FormControl>
                    <GridItem xs={12} sm={12} md={12}>
                        <FilterableMultiRequestsManagement
                            allItemsList={this.state.customersList}
                            headersList={headersList}
                            fieldsList={fieldsList}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

    deniedOperation  (primaryMsg, secondaryMsg)  {
        this.setState({
            alert:
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "140px" }}
                    title={primaryMsg}
                    customButtons={
                        <React.Fragment>
                            <div style={{ margin: 'auto', textAlign: "center" }}>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                                    color="success"
                                    onClick={() => { this.hideAlert() }}
                                    round>
                                    <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                    </Button>
                            </div>
                        </React.Fragment>
                    }
                >
                    {secondaryMsg}<br />
                </SweetAlert>
        });
        setTimeout(this.hideAlert, AUTO_CLOSE_TIME_DENIED_OPERATION, false);
    }

    sendSuspendedRequests(suspendedReqId){
        let address = AuthService.getSuspendedReqAddress();

        axiosApiCaller.get(address, {params:
            {
                idRequest: suspendedReqId,
                applicationName: AuthService.getApplicationName()
            }
        }).then((res) => {
            console.log(res.data);

            return window.location.href = 'storico-richieste';
            
        })
        .catch((error) => {
            console.log("dentro axios, catch");
            console.log(error);
        });
    }


    extractAllCustomersListFromBackend() {
        let querySearch=this.state.name; //querySearch è quello che ha inserito l'utente in alto a sx (il periodo)
        let appName = AuthService.getApplicationName();
        // let headerWithAuth = AuthService.getAuthHeaderAsObj();
        // fetch(AuthService.BACKEND_API_TEE_DISCOVERED_SUBJECTS, {
        //     method: 'POST',
        //     headers: headerWithAuth,
        //     body: JSON.stringify({
        //         applicationName: appName,
        //         querySearch: querySearch
        //     })
        // })
        axiosApiCaller.post(AuthService.getBackendApiBppbAccountingList(), {
            applicationName: appName,
            querySearch: querySearch,
            start: this.state.startDate,
            end: this.state.endDate

        })
            .then((res) => {//il then/catch è quello della vecchia fetch, va cambiato (eventualmente)
                if (res.data.success === true) {
                    console.log('POSITION LIST SUCCESSFULLY........');
                    //console.log(JSON.stringify(res.data.payload));
                    //let customers = this.cleanData(res.data.payload);
                    //debugger;
                    let customers = this.formatData(res.data.payload);
                    //debugger;
                    customers = this.setResponseDownload(customers);
                    customers = this.setResponsePdf(customers);
                    customers = this.setResponseXml(customers);
                    customers = this.setResponsePdfConservatoria(customers);
                    if(this.state.currentLoggedUserCanManageVisualizeResult){
                        customers = this.setRequestClickable(customers);
                    }
                    customers = this.setResponseV2(customers);
                    customers = this.setResponseXlsV2(customers);
                    this.setState({ customersList: customers });
                    this.resetFilters();
                    this.setState({ currentMinutesRefreshDelay: 0 });
                    this.lastRefreshTime = new Date();
                    setInterval(() => {
                        this.setState({ currentMinutesRefreshDelay: Math.floor((((new Date()) - this.lastRefreshTime) / 1000) / 60) });
                    }, 30001);
                    this.hideAlert();
                } else {
                    console.log('FLAG ERROR ON RESPONSE: ' + res.data.message);
                    this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.');
                }
            })
            .catch((error) => {
                console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
                console.log(JSON.stringify(error.message));
                this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
            });
    }

    // CLEAN DATA FROM NULL VALUE
    cleanData(rawData) {
        let cleanedData = [];
        if (!rawData || (rawData == null)) {
            return cleanedData;
        }
        rawData.forEach(rawElement => {
            cleanedData.push(rawElement);
        });
        return cleanedData;
    }

    formatData(list){
        for(let i = 0; i< list.length; i++){
            list[i].receivedDate = this.formatSessionDateTime(list[i].receivedDate);
        }

        return list;
    }

    formatSessionDateTime(dateTime) {
        //console.log(JSON.stringify(dateTime));
        var date = new Date(dateTime);
        return (new Intl.DateTimeFormat("it", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric"
        }).format(date));

    }

    setRequestClickable(list){

        for(let i = 0; i < list.length; i++){

            list[i].clickableId =   <Link to={ {pathname: '/consolle/request-result', state: { idRequest: list[i].id } } }>
                                        <span>{list[i].id}</span>
                                    </Link>
        }

        return list;
    }

    setResponseDownload(list){

        for(let i = 0; i< list.length;i++){

            list[i].responseDownload =  <IconButton
                                            aria-label="download" onClick={() => { this.getFileWithResults(list[i].id, list[i].codiceFiscale, list[i].ragioneSociale) }}>
                                            <CloudDownloadIcon />
                                        </IconButton>
        }        
        
        return list;
    }

    setResponsePdf(list){
        for(let i = 0; i< list.length;i++){
            list[i].responsePdf =  <IconButton
                                            aria-label="download" onClick={() => { this.getFilePdf(list[i].id, list[i].codiceFiscale, list[i].ragioneSociale) }}>
                                            <CloudDownloadIcon />
                                        </IconButton>
        }

        return list;
    }

    setResponseXml(list){

        for(let i = 0; i< list.length;i++){
            list[i].responseXml =  <IconButton
                                            aria-label="download" onClick={() => { this.getFileXml(list[i].id) }}>
                                            <CloudDownloadIcon />
                                        </IconButton>
        }

        return list;
    }

    setResponsePdfConservatoria(list){
        for(let i = 0; i< list.length;i++){
            list[i].responsePdfConservatoria =  <IconButton
                                            aria-label="download" onClick={() => { this.getFilePdfConservatoria(list[i].id, list[i].codiceFiscale, list[i].ragioneSociale) }}>
                                            <CloudDownloadIcon />
                                        </IconButton>
        }

        return list;
    }

    setResponseV2(list){
        for(let i = 0; i< list.length;i++){
            list[i].responseV2 =  <IconButton
                                            aria-label="download" onClick={() => { this.getFileV2(list[i].id, list[i].codiceFiscale, list[i].ragioneSociale) }}>
                                            <CloudDownloadIcon />
                                        </IconButton>
        }

        return list;
    }

    setResponseXlsV2(list){
        for(let i = 0; i< list.length;i++){
            list[i].responseXlsV2 =  <IconButton
                                            aria-label="download" onClick={() => { this.getFileXlsV2(list[i].id, list[i].codiceFiscale, list[i].ragioneSociale) }}>
                                            <CloudDownloadIcon />
                                        </IconButton>
        }

        return list;
    }
    getFileWithResults(idRequest, codiceFiscale, ragioneSociale){
        axios.get(AuthService.getBackendApiTeeResponseFileDownload() + "?idRequest=" + idRequest,
        {
            responseType: 'blob',
            headers: {
                'Accept': 'application/octet-stream',
                'Authorization' : AuthService.getLoggedUserJwtToken()
            }
        })
        .then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', codiceFiscale+'_'+ragioneSociale+'.docx');
            //document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            console.log(error);
            console.log(error.response);
            if(error.response && error.response.status === AxiosService.HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED) {
                AuthService.setLoggedUserJwtToken(null);
                return window.location.href = '/home'; 
            }
        });
    }


    getFilePdf(idRequest, codiceFiscale, ragioneSociale){
        axios.get(AuthService.getBackendApiTeeResponseFilePdfDownload() + "?idRequest=" + idRequest,
        {
            responseType: 'blob',
            headers: {
                'Accept': 'application/octet-stream',
                'Authorization' : AuthService.getLoggedUserJwtToken()
            }
        })
        .then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', codiceFiscale+'_'+ragioneSociale+'.pdf');
            //document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            console.log(error);
            console.log(error.response);
            if(error.response && error.response.status === AxiosService.HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED) {
                AuthService.setLoggedUserJwtToken(null);
                return window.location.href = '/home'; 
            }
        });
    }


    getFilePdfConservatoria(idRequest, codiceFiscale, ragioneSociale){
        axios.get(AuthService.getBackendApiTeeResponseFilePdfDownloadConservatoria() + "?idRequest=" + idRequest,
        {
            responseType: 'blob',
            headers: {
                'Accept': 'application/octet-stream',
                'Authorization' : AuthService.getLoggedUserJwtToken()
            }
        })
        .then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', codiceFiscale+'_'+ragioneSociale+'.pdf');
            //document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            console.log(error);
            console.log(error.response);
            if(error.response && error.response.status === AxiosService.HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED) {
                AuthService.setLoggedUserJwtToken(null);
                return window.location.href = '/home';
            }
            alert("File non presente");
        });
    }


    getFileV2(idRequest, codiceFiscale, ragioneSociale){
        axios.get(AuthService.getBackendApiTeeRequestFileV2Download() + "?idRequest=" + idRequest,
        {
            responseType: 'blob',
            headers: {
                'Accept': 'application/octet-stream',
                'Authorization' : AuthService.getLoggedUserJwtToken()
            }
        })
        .then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', codiceFiscale+'_'+ragioneSociale+'.docx');
            //document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            console.log(error);
            console.log(error.response);
            if(error.response && error.response.status === AxiosService.HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED) {
                AuthService.setLoggedUserJwtToken(null);
                return window.location.href = '/home';
            }
            alert("File non presente");
        });
    }

    getFileXlsV2(idRequest, codiceFiscale, ragioneSociale){
        axios.get(AuthService.getBackendApiTeeRequestFileXlsV2Download() + "?idRequest=" + idRequest,
        {
            responseType: 'blob',
            headers: {
                'Accept': 'application/octet-stream',
                'Authorization' : AuthService.getLoggedUserJwtToken()
            }
        })
        .then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', codiceFiscale+'_'+ragioneSociale+'.xlsx');
            //document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            console.log(error);
            console.log(error.response);
            if(error.response && error.response.status === AxiosService.HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED) {
                AuthService.setLoggedUserJwtToken(null);
                return window.location.href = '/home';
            }
            alert("File non presente");
        });
    }
    getFileXml(idRequest){
        axios.get(AuthService.getBackendApiTeeResponseFileXmlDownload() + "?idRequest=" + idRequest,
        {
            responseType: 'blob',
            headers: {
                'Accept': 'application/octet-stream',
                'Authorization' : AuthService.getLoggedUserJwtToken()
            }
        })
        .then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'dossier.xml');
            //document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            console.log(error);
            console.log(error.response);
            if(error.response && error.response.status === AxiosService.HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED) {
                AuthService.setLoggedUserJwtToken(null);
                return window.location.href = '/home'; 
            }
        });
    }

    formatSessionDateTime(dateTime) {
    //console.log(JSON.stringify(dateTime));
    var date = new Date(dateTime);
    return (new Intl.DateTimeFormat("it", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
    }).format(date));
    }

  showTimeNoticeAlert(primaryMsg, secondaryMsg){
    this.setState({alert: 
      <SweetAlert
        info
        style={{ display: "block", marginTop: "140px" }}
        title={primaryMsg}
        customButtons={
          <React.Fragment>
              <div style={{margin: 'auto', textAlign: "center"}}>
                
                <Button 
                  style={{fontSize: '13px', margin: '0px 0px 0px 10px'}}
                  color="success"
                  onClick={() => { this.hideAlert() }}
                  round>
                    <CheckCircle style={{fontSize: '28px'}} />&nbsp;Ok
                </Button>
                
              </div>
          </React.Fragment>
        }
      >
        {secondaryMsg}<br/>
      </SweetAlert>  
    });
  }

  showRedirectAlert(primaryMsg, secondaryMsg){
    this.setState({alert: 
      <SweetAlert
        success
        style={{ display: "block", marginTop: "140px" }}
        title={primaryMsg}
        customButtons={
          <React.Fragment>
              <div style={{margin: 'auto', textAlign: "center"}}>
                <Link to='/consolle/storico-richieste'>
                <Button 
                  style={{fontSize: '13px', margin: '0px 0px 0px 10px'}}
                  color="success"
                  onClick={() => { this.hideAlert() }}
                  round>
                    <CheckCircle style={{fontSize: '28px'}} />&nbsp;Ok
                </Button>
                </Link>
              </div>
          </React.Fragment>
        }
      >
        {secondaryMsg}<br/>
      </SweetAlert>  
    });
  }

   handleChangeStartDate(date) {

        date.setHours(0,0,0,0);
    this.setState({ startDate: date });
    
  }

  handleChangeEndDate(date) {
    this.setState({ endDate: date });
  }

}



export default withStyles(styles)(Accounting);
