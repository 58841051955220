import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
//import Chartist from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";
import ResetFilters from "@material-ui/icons/LayersClear";
import ReloadData from "@material-ui/icons/CloudDownload";
import ToggleOn from "@material-ui/icons/ToggleOn";
import ToggleOff from "@material-ui/icons/ToggleOff";
import SweetAlert from "react-bootstrap-sweetalert";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Tooltip from '@material-ui/core/Tooltip';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CommonDropdown from 'components/COMMON/CommonDropDown.js';
import * as AuthService from 'services/AuthService.js';
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
//import dashboardStyles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import checkBoxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import FilterableMultiCustomersManagement from "components/COMMON/FilterableMultiCustomersManagement"

import {axiosApiCaller} from 'layouts/AxiosService';

const localStyles = {
    icon: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "23px",
        height: "23px",
        top: "0px",
        left: "2px",
        position: "relative"
    },
    actionButton: {
        margin: "5px 5px 5px 5px",
        padding: "0px",
        width: "30px",
        height: "30px",
        minWidth: "auto",
        fontSize: "20px"
    },
    dialogConfirmButton: {
        margin: "0 0 0 5px",
        padding: "20px",
        width: "80px",
        height: "auto",
        minWidth: "auto"
    },
    dialogCancelButton: {
        margin: "0 0 0 5px",
        padding: "7px",
        width: "auto",
        height: "auto",
        minWidth: "auto"
    }
}

const styles = {
    ...localStyles,
    ...dashboardStyles,
    ...checkBoxStyles
}

const AUTO_CLOSE_TIME_DENIED_OPERATION = 4000;

export const SCORING_BASE_ARROW = 0;
export const SCORING_INTERMEDIATE_FLAG = 1;
export const SCORING_ADVANCED_NUMERIC = 2;
export const SCORING_DEEP_REPORT = 3;
//FILTRI
export const FILTER_GEO_REPORT = 0;
export const FILTER_TIPOLOGIA_REPORT = 10;
export const FILTER_STATE_REPORT = 20;
export const FILTER_STRATEGIA_REPORT = 30;

// LABEL per TIPOLOGIE (persona fisica = false o true)
export const PERSONA_GIURIDICA_LABEL = "PERSONE GIURIDICHE";
export const PERSONA_FISICA_LABEL = "PERSONE FISICHE";

// COSTANTI PER I VALORI NON DEFINITI O NULLI
export const NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE = '(Non disponibile)';
export const NULL_STRATEGIA_SUBSTITUTION_VALUE = 'Nessuna Strategia';
export const NULL_STATO_SUBSTITUTION_VALUE = 'Nessun Stato di Elaborazione';
export const NULL_BOOLEAN_DATA_SUBSTITUTION_VALUE = false;

export const ALL_CITTA_LABEL = 'Tutte le Province o Nazioni';
export const ALL_TIPOLOGIA_LABEL = 'Tutte le Tipologie';
export const ALL_STATI_LABEL = 'Tutti gli Stati';
export const ALL_STRATEGIA_LABEL = 'Tutte le Strategie';


var delays = 80,
    durations = 500;
var delays2 = 80,
    durations2 = 500;

var Chartist = require("chartist");

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.lastRefreshTime = new Date();
        this.showMonitoredLabel = "Includi le posizioni monitorate";
        this.showNotMonitoredLabel = "Includi le posizioni non monitorate";
    this.hideAlert = this.hideAlert.bind(this);
    this.handleShowMonitoredChange = this.handleShowMonitoredChange.bind(this);
    this.handleShowNotMonitoredChange = this.handleShowNotMonitoredChange.bind(this);
    this.deniedOperation = this.deniedOperation.bind(this);
    }

    getInitialState() {
        return {
            alert: null,
            usersSessionsList: [],
            customersList: [],
            showMonitored: true,
            showNotMonitored: true,
            numOfGeo: 0,
            geoName: (ALL_CITTA_LABEL),
            geoList: [],
            geoListValue: [],
            geoChartLabels: [],
            geoChartSeries: [],
            geoChartMaxY: 1000,
            numOfTipologia: 0,
            tipologiaName: (ALL_TIPOLOGIA_LABEL),
            tipologiaList: [],
            tipologiaListValue: [],
            tipologiaChartLabels: [],
            tipologiaChartSeries: [],
            tipologiaChartTotal: 100,
            numOfStati: 0,
            statiName: (ALL_STATI_LABEL),
            statiList: [],
            statiListValue: [],
            statiChartLabels: [],
            statiChartSeries: [],
            statiChartMaxY: 1000,
            /*
            numOfStrategia: 0,
            strategiaName: (ALL_STRATEGIA_LABEL),
            strategiaList: [],
            strategiaListValue: [],
            strategiaChartLabels: [],
            strategiaChartSeries: [],
            strategiaChartMaxY: 1000,*/
            usersSessionsChartLabels: [],
            usersSessionsChartSeries: [],
            usersSessionsChartMax: 100,
            currentMinutesRefreshDelay: 0
        };
    }

    hideAlert ()  {
        this.setState({ alert: null });
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.extractAllCustomersListFromBackend();
        this.extractAllUsersSessionFromBackend();
    }

    componentWillUnmount() {
    }

    /*
    handleScoringTypeFiltering(scoringType) {
        this.setState({ showScoringType: scoringType });
        this.resetFilter();
    }

    resetScoringType() {
        this.setState({ showScoringType: null });
    }*/


    handleShowMonitoredChange  (event)  {
        this.setState({ showMonitored: event.target.checked });
        if (event.target.checked) {
            this.showMonitoredLabel = "Includi le posizioni monitorate";
        } else {
            this.showMonitoredLabel = "Escludi le posizioni monitorate";
        }
    };

    handleShowNotMonitoredChange  (event)  {
        this.setState({ showNotMonitored: event.target.checked });
        if (event.target.checked) {
            this.showNotMonitoredLabel = "Includi le posizioni non monitorate";
        } else {
            this.showNotMonitoredLabel = "Escludi le posizioni non monitorate";
        }
    };

    handleSelected(param, filterType) {
        if (filterType == FILTER_GEO_REPORT) {
            let index = this.state.geoList.indexOf(param);
            let value = this.state.geoListValue[index];
            this.setState({ numOfGeo: value, geoName: param });
        } else if (filterType == FILTER_TIPOLOGIA_REPORT) {
            let index = this.state.tipologiaList.indexOf(param);
            let value = this.state.tipologiaListValue[index];
            this.setState({ numOfTipologia: value, tipologiaName: param });
        } else if (filterType == FILTER_STATE_REPORT) {
            let index = this.state.statiList.indexOf(param);
            let value = this.state.statiListValue[index];
            this.setState({ numOfStati: value, statiName: param });
        } else if (filterType == FILTER_STRATEGIA_REPORT) {
            let index = this.state.strategiaList.indexOf(param);
            let value = this.state.strategiaListValue[index];
            this.setState({ numOfStrategia: value, strategiaName: param });
        }
    }


    handleFiltering(filter) {
    }

    resetGeoFilter() {
        let index = this.state.geoList.indexOf(ALL_CITTA_LABEL);
        let value = this.state.geoListValue[index];
        this.setState({ numOfGeo: value, geoName: ALL_CITTA_LABEL });
    }

    resetTipologiaFilter() {
        let index = this.state.tipologiaList.indexOf(ALL_TIPOLOGIA_LABEL);
        let value = this.state.tipologiaListValue[index];
        this.setState({ numOfTipologia: value, tipologiaName: ALL_TIPOLOGIA_LABEL });
    }

    
    resetStatiFilter() {
        let index = this.state.statiList.indexOf(ALL_STATI_LABEL);
        let value = this.state.statiListValue[index];
        this.setState({ numOfStati: value, statiName: ALL_STATI_LABEL });
    }
    /*
    resetStrategiaFilter() {
        let index = this.state.strategiaList.indexOf(ALL_STRATEGIA_LABEL);
        let value = this.state.strategiaListValue[index];
        this.setState({ numOfStrategia: value, strategiaName: ALL_STRATEGIA_LABEL });
    }
    */

    resetFilters() {
        this.setState(
            {
                showMonitored: true,
                showNotMonitored: true
            }
        );
        this.resetGeoFilter();
        this.resetTipologiaFilter();
        this.resetStatiFilter();
        //this.resetStrategiaFilter();
    }

    prepareGeoChartLegend() {
        let geoChartLegend = '';
        for (let i = 0; i < this.state.geoChartLabels.length; i++) {
            if (i > 0) {
                geoChartLegend = geoChartLegend + ', ';
            }
            geoChartLegend = geoChartLegend + this.state.geoChartLabels[i] + ' (' + this.state.geoChartSeries[0][i] + ')';
        }
        return geoChartLegend;
    }

    prepareTipologiaChartLegend() {
        let tipologiaChartLegend = '';
        for (let i = 0; i < this.state.tipologiaChartLabels.length; i++) {
            if (i > 0) {
                tipologiaChartLegend = tipologiaChartLegend + ', ';
            }
            tipologiaChartLegend = tipologiaChartLegend + this.state.tipologiaChartLabels[i];
        }
        return tipologiaChartLegend;
    }

    prepareUsersSessionsChartLegend() {
        let usersSessionsChartLegend = '';
        for (let i = 0; i < this.state.usersSessionsChartLabels.length; i++) {
            if (i > 0) {
                usersSessionsChartLegend = usersSessionsChartLegend + ', ';
            }
            usersSessionsChartLegend = usersSessionsChartLegend + this.state.usersSessionsChartLabels[i];
        }
        return usersSessionsChartLegend;
    }

    
    prepareStatiChartLegend() {
        let statiChartLegend = '';
        for (let i = 0; i < this.state.statiChartLabels.length; i++) {
            if (i > 0) {
                statiChartLegend = statiChartLegend + ', ';
            }
            statiChartLegend = statiChartLegend + this.state.statiChartLabels[i] + ' (' + this.state.statiChartSeries[0][i] + ')';
        }
        return statiChartLegend;
    }
    /*
    prepareStrategiaChartLegend() {
        let strategiaChartLegend = '';
        for (let i = 0; i < this.state.strategiaChartLabels.length; i++) {
            if (i > 0) {
                strategiaChartLegend = strategiaChartLegend + ', ';
            }
            strategiaChartLegend = strategiaChartLegend + this.state.strategiaChartLabels[i] + ' (' + this.state.strategiaChartSeries[0][i] + ')';
        }
        return strategiaChartLegend;
    } */

    render() {
        const { classes } = this.props;
        const geoChartLegend = this.prepareGeoChartLegend();
        const tipologiaChartLegend = this.prepareTipologiaChartLegend();
        const usersSessionsChartLegend = this.prepareUsersSessionsChartLegend();
        const statiChartLegend = this.prepareStatiChartLegend();
        //const strategiaChartLegend = this.prepareStrategiaChartLegend();
        const geographicalChart = {
            data: {
                labels: this.state.geoChartLabels,
                series: this.state.geoChartSeries
            },
            options: {
                axisX: {
                    showGrid: false
                },
                low: 0,
                high: this.state.geoChartMaxY,
                chartPadding: {
                    top: 0,
                    right: 5,
                    bottom: 0,
                    left: 0
                }
            },
            responsiveOptions: [
                [
                    "screen and (max-width: 640px)",
                    {
                        seriesBarDistance: 5,
                        axisX: {
                            labelInterpolationFnc: function (value) {
                                return value[0];
                            }
                        }
                    }
                ]
            ],
            animation: {
                draw: function (data) {
                    if (data.type === "bar") {
                        data.element.animate({
                            opacity: {
                                begin: (data.index + 1) * delays2,
                                dur: durations2,
                                from: 0,
                                to: 1,
                                easing: "ease"
                            }
                        });
                    }
                }
            }
        };
        const tipologiaChart = {
            data: {
                labels: this.state.tipologiaChartLabels,
                series: this.state.tipologiaChartSeries
            },
            options: {
                total: this.state.tipologiaChartTotal,
                showLabel: true,
                labelPosition: "outside",
                labelOffset: 3,
                labelDirection: "explode"
            }
        };
        const usersSessionsChart = {
            data: {
                labels: this.state.usersSessionsChartLabels,
                series: this.state.usersSessionsChartSeries
            },
            options: {
                lineSmooth: Chartist.Interpolation.cardinal({
                    tension: 0
                }),
                low: 0,
                high: this.state.usersSessionsChartMax,
                chartPadding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            },
            animation: {
                draw: function (data) {
                    if (data.type === "line" || data.type === "area") {
                        data.element.animate({
                            d: {
                                begin: 600,
                                dur: 700,
                                from: data.path
                                    .clone()
                                    .scale(1, 0)
                                    .translate(0, data.chartRect.height())
                                    .stringify(),
                                to: data.path.clone().stringify(),
                                easing: Chartist.Svg.Easing.easeOutQuint
                            }
                        });
                    } else if (data.type === "point") {
                        data.element.animate({
                            opacity: {
                                begin: (data.index + 1) * delays,
                                dur: durations,
                                from: 0,
                                to: 1,
                                easing: "ease"
                            }
                        });
                    }
                }
            }
        };
        /*
        const statiChart = {
            data: {
                labels: this.state.statiChartLabels,
                series: this.state.statiChartSeries
            },
            options: {
                axisX: {
                    showGrid: false
                },
                low: 0,
                high: this.state.statiChartMaxY,
                chartPadding: {
                    top: 0,
                    right: 5,
                    bottom: 0,
                    left: 0
                }
            },
            responsiveOptions: [
                [
                    "screen and (max-width: 640px)",
                    {
                        seriesBarDistance: 5,
                        axisX: {
                            labelInterpolationFnc: function (value) {
                                return value[0];
                            }
                        }
                    }
                ]
            ],
            animation: {
                draw: function (data) {
                    if (data.type === "bar") {
                        data.element.animate({
                            opacity: {
                                begin: (data.index + 1) * delays2,
                                dur: durations2,
                                from: 0,
                                to: 1,
                                easing: "ease"
                            }
                        });
                    }
                }
            }
        };
        const strategiaChart = {
            data: {
                labels: this.state.strategiaChartLabels,
                series: this.state.strategiaChartSeries
            },
            options: {
                axisX: {
                    showGrid: false
                },
                low: 0,
                high: this.state.strategiaChartMaxY,
                chartPadding: {
                    top: 0,
                    right: 5,
                    bottom: 0,
                    left: 0
                }
            },
            responsiveOptions: [
                [
                    "screen and (max-width: 640px)",
                    {
                        seriesBarDistance: 5,
                        axisX: {
                            labelInterpolationFnc: function (value) {
                                return value[0];
                            }
                        }
                    }
                ]
            ],
            animation: {
                draw: function (data) {
                    if (data.type === "bar") {
                        data.element.animate({
                            opacity: {
                                begin: (data.index + 1) * delays2,
                                dur: durations2,
                                from: 0,
                                to: 1,
                                easing: "ease"
                            }
                        });
                    }
                }
            }
        };*/

        let statiChartSeriesSum = 0;
        if(typeof this.state.statiChartSeries[0] !== 'undefined'){
            statiChartSeriesSum = this.sum(this.state.statiChartSeries[0]);
        }

        let valuesWithClassNames = [];
        for(let i = 0; i < this.state.statiChartLabels.length; i++){
            let stateName = this.state.statiChartLabels[i];
            // if(typeof this.state.statiChartLabels[i] !== "undefined"){
            //     stateName = { css["this.state.statiChartLabels[i]"]};
            // }
            
            let stateVal = this.state.statiChartSeries[0][i];
            let obj = {
                value: stateVal,
                className: stateName
            }
            valuesWithClassNames.push(obj);
        }
        const statiChart = {
            data: {
                // labels: ["WARNING","COMPLETED"],
                labels: this.state.statiChartLabels,
                // series: [31,18]
                // series: [{value: 31, className: 'WARNING']}, {value: 18, className: 'COMPLETED'}]
                series: valuesWithClassNames
            },
            options: {
                total: statiChartSeriesSum,
                showLabel: true,
                labelPosition: "outside",
                labelOffset: 3,
                labelDirection: "explode"
            }
        };

        let showMonitoredControl = null;
        if (this.state.showMonitored === true) {
            showMonitoredControl = (<a href="#"><ToggleOn onClick={(event) => this.handleShowNotMonitoredChange(event)} style={{ float: 'right', margin: '0px 0px 0px 0px', width: '34px', height: '34px' }} /></a>);
        } else {
            showMonitoredControl = (<a href="#"><ToggleOff onClick={(event) => this.handleShowNotMonitoredChange(event)} style={{ float: 'right', margin: '0px 0px 0px 0px', width: '34px', height: '34px' }} /></a>);
        }

        //Attenzione il botone dettaglio non viene passato nella lista dei fields
        let headersList=["Dettaglio","Monitoraggio", "Stato richiesta", "Denominazione societaria", "Provincia o Nazione", "Approfondimenti"];
        let fieldsList=["monitoraggioType","elaborationState","ragioneSociale","paeseProvincia","menu"];

        return (
            <div>
                {this.state.alert}
                <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                        <Card chart className={classes.cardHover}>
                            <CardHeader color="danger" className={classes.cardHeaderHover}>
                                <ChartistGraph
                                    className="ct-chart-white-colors"
                                    data={tipologiaChart.data}
                                    type="Pie"
                                    options={tipologiaChart.options}
                                />
                            </CardHeader>
                            <CardBody>
                                <div className={classes.cardHoverUnder}>
                                    <Tooltip
                                        id="tooltip-top1"
                                        title="Resetta questo filtro"
                                        placement="bottom"
                                        classes={styles.tooltip}
                                    >
                                        <Button simple color="primary" justIcon>
                                            <ResetFilters className={classes.underChartIcons} onClick={() => { this.resetTipologiaFilter(); }} style={{ margin: '0px 10px 0px 10px', width: '25px', height: '25px' }} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        id="tooltip-top2"
                                        title="Ricarica tutti i dati"
                                        placement="bottom"
                                        classes={styles.tooltip}
                                    >
                                        <Button simple color="primary" justIcon>
                                            <ReloadData className={classes.underChartIcons} onClick={() => { this.extractAllCustomersListFromBackend(); }} style={{ margin: '0px 10px 0px 10px', width: '25px', height: '25px' }} />
                                        </Button>
                                    </Tooltip>
                                </div>
                                <h4 className={classes.cardTitle}>Tipologia Monitoraggio<br />
                                    <span style={{ float: "right" }}><span className={classes.stats}>N.</span> {this.state.numOfTipologia} <span className={classes.stats}>posizioni in
                                        <CommonDropdown
                                            id={FILTER_TIPOLOGIA_REPORT}
                                            buttonText={this.state.tipologiaName}
                                            buttonProps={{
                                                round: true,
                                                color: "github",
                                                hoverColor: "primary",
                                                simple: true,
                                                size: "xl",
                                            }}
                                            listener={this}
                                            dropdownList={this.state.tipologiaList}
                                        />
                                    </span></span>
                                </h4>
                            </CardBody>

                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={4}>
                        <Card chart className={classes.cardHover}>
                            <CardHeader color="success" className={classes.cardHeaderHover}>
                                <ChartistGraph
                                    className="ct-chart-white-colors"
                                    data={geographicalChart.data}
                                    type="Bar"
                                    options={geographicalChart.options}
                                    responsiveOptions={geographicalChart.responsiveOptions}
                                    listener={geographicalChart.animation}
                                />
                            </CardHeader>
                            <CardBody>
                                <div className={classes.cardHoverUnder}>
                                    <Tooltip
                                        id="tooltip-top1"
                                        title="Resetta questo filtro"
                                        placement="bottom"
                                        classes={styles.tooltip}
                                    >
                                        <Button simple color="primary" justIcon>
                                            <ResetFilters className={classes.underChartIcons} onClick={() => { this.resetGeoFilter(); }} style={{ margin: '0px 10px 0px 10px', width: '25px', height: '25px' }} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        id="tooltip-top2"
                                        title="Ricarica tutti i dati"
                                        placement="bottom"
                                        classes={styles.tooltip}
                                    >
                                        <Button simple color="primary" justIcon>
                                            <ReloadData className={classes.underChartIcons} onClick={() => { this.extractAllCustomersListFromBackend(); }} style={{ margin: '0px 10px 0px 10px', width: '25px', height: '25px' }} />
                                        </Button>
                                    </Tooltip>
                                </div>
                                <h4 className={classes.cardTitle}>Area Geografica<br />
                                    <span style={{ float: "right" }}><span className={classes.stats}>N.</span> {this.state.numOfGeo} <span className={classes.stats}>posizioni in
                                        <CommonDropdown
                                            id={FILTER_GEO_REPORT}
                                            buttonText={this.state.geoName}
                                            buttonProps={{
                                                round: true,
                                                color: "github",
                                                hoverColor: "primary",
                                                simple: true,
                                                size: "xl",
                                            }}
                                            listener={this}
                                            dropdownList={this.state.geoList}
                                        />
                                    </span></span>
                                </h4>
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={4}>
                        <Card chart className={classes.cardHover}>
                            <CardHeader color="info" className={classes.cardHeaderHover}>
                                <ChartistGraph
                                    className="ct-chart-white-colors"
                                    data={statiChart.data}
                                    type="Pie"
                                    options={statiChart.options}
                                />
                            </CardHeader>
                            <CardBody>
                                <div className={classes.cardHoverUnder}>
                                    <Tooltip
                                        id="tooltip-top1"
                                        title="Resetta questo filtro"
                                        placement="bottom"
                                        classes={styles.tooltip}
                                    >
                                        <Button simple color="primary" justIcon>
                                            <ResetFilters className={classes.underChartIcons} onClick={() => { this.resetStatiFilter(); }} style={{ margin: '0px 10px 0px 10px', width: '25px', height: '25px' }} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        id="tooltip-top2"
                                        title="Ricarica tutti i dati"
                                        placement="bottom"
                                        classes={styles.tooltip}
                                    >
                                        <Button simple color="primary" justIcon>
                                            <ReloadData className={classes.underChartIcons} onClick={() => { this.extractAllCustomersListFromBackend(); }} style={{ margin: '0px 10px 0px 10px', width: '25px', height: '25px' }} />
                                        </Button>
                                    </Tooltip>
                                </div>
                                <h4 className={classes.cardTitle}>Stato Elaborazione<br />
                                    <span style={{ float: "right" }}><span className={classes.stats}>N.</span> {this.state.numOfStati} <span className={classes.stats}>posizioni in
                                        <CommonDropdown
                                            id={FILTER_STATE_REPORT}
                                            buttonText={this.state.statiName}
                                            buttonProps={{
                                                round: true,
                                                color: "github",
                                                hoverColor: "primary",
                                                simple: true,
                                                size: "xl",
                                            }}
                                            listener={this}
                                            dropdownList={this.state.statiList}
                                        />
                                    </span></span>
                                </h4>
                            </CardBody>

                        </Card>
                    </GridItem>
                    
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <FormControlLabel
                            style={{ float: 'right' }}
                            control={
                                <Switch
                                    checked={this.state.showMonitored}
                                    onChange={event => this.handleShowMonitoredChange(event)}
                                    value=""
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar
                                    }}
                                />
                            }
                            classes={{
                                label: classes.label
                            }}
                            label={this.showMonitoredLabel}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <a href="#">
                            <FormControlLabel
                                style={{ float: 'left' }}
                                control={
                                    <Switch
                                        checked={this.state.showNotMonitored}
                                        onChange={event => this.handleShowNotMonitoredChange(event)}
                                        value=""
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}

                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label={this.showNotMonitoredLabel}
                            />
                        </a>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>

                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <Tooltip
                            id="tooltip-x"
                            title={"Aggiorna i dati dalla piattaforma di Analitica"}
                            placement="bottom"
                            classes={styles.tooltip}
                        >
                            <Button simple color="primary" justIcon style={{ float: 'right', margin: '0px 20px 0px 20px' }}>
                                <ReloadData onClick={() => { this.extractAllCustomersListFromBackend(); this.extractAllUsersSessionFromBackend(); }} style={{ width: '34px', height: '34px' }} />
                            </Button>
                        </Tooltip>
                        <Tooltip
                            id="tooltip-x"
                            title={"Resetta i filtri sui dati"}
                            placement="bottom"
                            classes={styles.tooltip}
                        >
                            <Button simple color="primary" justIcon style={{ float: 'right', margin: '0px 20px 0px 20px' }}>
                                <ResetFilters onClick={() => { this.resetFilters(); }} style={{ width: '34px', height: '34px' }} />
                            </Button>
                        </Tooltip>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <span className={classes.stats} style={{ textAlign: 'right', margin: '10px 0px 0px 0px' }}>Aggiornato {this.state.currentMinutesRefreshDelay} min. fa dal server di analisi</span>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <FilterableMultiCustomersManagement
                            allItemsList={this.state.customersList}
                            showMonitored={this.state.showMonitored}
                            showNotMonitored={this.state.showNotMonitored}
                            showGeoName={this.state.geoName}
                            showTipologiaName={this.state.tipologiaName}
                            showStatoName={this.state.statiName}
                            showStrategiaName={this.state.strategiaName}
                            headersList={headersList}
                            fieldsList={fieldsList}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

    deniedOperation  (primaryMsg, secondaryMsg)  {
        this.setState({
            alert:
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "140px" }}
                    title={primaryMsg}
                    customButtons={
                        <React.Fragment>
                            <div style={{ margin: 'auto', textAlign: "center" }}>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                                    color="success"
                                    onClick={() => { this.hideAlert() }}
                                    round>
                                    <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                    </Button>
                            </div>
                        </React.Fragment>
                    }
                >
                    {secondaryMsg}<br />
                </SweetAlert>
        });
        setTimeout(this.hideAlert, AUTO_CLOSE_TIME_DENIED_OPERATION, false);
    }


    extractAllCustomersListFromBackend() {
        let appName = AuthService.getApplicationName();
        //let headerWithAuth = AuthService.getAuthHeaderAsObj();
        // fetch(AuthService.BACKEND_API_TEE_DISCOVERED_SUBJECTS, {
        //     method: 'POST',
        //     headers: headerWithAuth,
        //     body: JSON.stringify({
        //         applicationName: appName
        //     })
        // })
        axiosApiCaller.post(AuthService.getBackendApiTeeDiscoveredSubjects(), { applicationName: appName} )
            .then((res) => {
                if (res.data.success === true) {
                    console.log('POSITION LIST SUCCESSFULLY........');
                    //console.log(res.data.payload);
                    console.log('...........................');

                    let customers = this.cleanData(res.data.payload);
                    // let oggettoDiProva1={
                    //     aggiornato: "No",
                    //     cap: "8009",
                    //     customerId: "37110323207",
                    //     dataAggiornamento: "27 gennaio 2021, 10:23:43",
                    //     elaborationMessage: "TEE Calcolato",
                    //     elaborationState: "PARTIAL",
                    //     foreignActualOwners: [],
                    //     foreignActualOwnersCount: 1,
                    //     indirizzo: "155 A, Route d.Arlon",
                    //     localita: "Strassen",
                    //     monitoraggio: "NO",
                    //     monitoraggioFlag: false,
                    //     monitoraggioType: "No",
                    //     paeseProvincia: "LU",
                    //     partitaIva: "200923332",
                    //     ragioneSociale: "PROVA1"
                    // }

                    // let oggettoDiProva2={
                    //     aggiornato: "No",
                    //     cap: "8009",
                    //     customerId: "37110179207",
                    //     dataAggiornamento: "27 gennaio 2021, 10:23:43",
                    //     elaborationMessage: "TEE Calcolato",
                    //     elaborationState: "ERROR",
                    //     foreignActualOwners: [],
                    //     foreignActualOwnersCount: 1,
                    //     indirizzo: "155 A, Route d.Arlon",
                    //     localita: "Strassen",
                    //     monitoraggio: "NO",
                    //     monitoraggioFlag: false,
                    //     monitoraggioType: "No",
                    //     paeseProvincia: "LU",
                    //     partitaIva: "200912312",
                    //     ragioneSociale: "PROVA2"
                    // }

                    // let oggettoDiProva3={
                    //     aggiornato: "No",
                    //     cap: "8009",
                    //     customerId: "3700009207",
                    //     dataAggiornamento: "27 gennaio 2021, 10:23:43",
                    //     elaborationMessage: "TEE Calcolato",
                    //     elaborationState: "ERROR",
                    //     foreignActualOwners: [],
                    //     foreignActualOwnersCount: 1,
                    //     indirizzo: "155 A, Route d.Arlon",
                    //     localita: "Strassen",
                    //     monitoraggio: "NO",
                    //     monitoraggioFlag: false,
                    //     monitoraggioType: "No",
                    //     paeseProvincia: "LU",
                    //     partitaIva: "200911112",
                    //     ragioneSociale: "PROVA3"
                    // }
                    // customers.push(oggettoDiProva1);
                    // customers.push(oggettoDiProva2);
                    // customers.push(oggettoDiProva3);
                    this.extractGeoList(customers);
                    this.extractTipologiaList(customers);
                    this.extractStateList(customers);
                    //this.extractStrategiaList(customers);
                    this.setState({ customersList: customers });
                    this.resetFilters();
                    this.setState({ currentMinutesRefreshDelay: 0 });
                    this.lastRefreshTime = new Date();
                    setInterval(() => {
                        this.setState({ currentMinutesRefreshDelay: Math.floor((((new Date()) - this.lastRefreshTime) / 1000) / 60) });
                    }, 30001);
                    this.hideAlert();
                } else {
                    console.log('FLAG ERROR ON RESPONSE: ' + res.data.message);
                    this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.');
                    //setTimeout(this.props.history.push("/login"), AUTO_CLOSE_TIME_DENIED_OPERATION, false);
                    //this.props.history.push("/login");
                }
            })
            .catch((error) => {
                console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
                console.log(JSON.stringify(error.message));
                this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
                //setTimeout(this.props.history.push("/login"), AUTO_CLOSE_TIME_DENIED_OPERATION, false);
                //this.props.history.push("/login");
            });
    }

    extractAllUsersSessionFromBackend() {
        let appName = AuthService.getApplicationName();
        //let headerWithAuth = AuthService.getAuthHeaderAsObj();
        // fetch(AuthService.BACKEND_API_TEE_USERS_SESSIONS, {
        //     method: 'POST',
        //     headers: headerWithAuth,
        //     body: JSON.stringify({
        //         applicationName: appName,
        //         maxLastDays: 6
        //     })
        // })
        axiosApiCaller.post(AuthService.getBackendApiTeeUsersSessions(), {
            applicationName: appName,
            maxLastDays: 6
        })
            .then((res) => {
                if (res.data.success === true) {
                    console.log('SESSIONS EXTRACTED SUCCESSFULLY........');
                    console.log(JSON.stringify(res.data.payload));
                    console.log('...........................');
                    this.extractUsersSessionsList(res.data.payload);
                    this.setState({ usersSessionsList: res.data.payload });
                    this.setState({ currentMinutesRefreshDelay: 0 });
                    this.lastRefreshTime = new Date();
                    setInterval(() => {
                        this.setState({ currentMinutesRefreshDelay: Math.floor((((new Date()) - this.lastRefreshTime) / 1000) / 60) });
                    }, 30001);
                    this.hideAlert();
                } else {
                    console.log('FLAG ERROR ON RESPONSE: ' + res.data.message);
                    this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.');
                    //setTimeout(this.props.history.push("/login"), AUTO_CLOSE_TIME_DENIED_OPERATION, false);
                    //this.props.history.push("/login");
                }
            })
            .catch((error) => {
                console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
                console.log(JSON.stringify(error.message));
                this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
                //setTimeout(this.props.history.push("/login"), AUTO_CLOSE_TIME_DENIED_OPERATION, false);
                //this.props.history.push("/login");
            });
    }





    // CLEAN DATA FROM NULL VALUE
    cleanData(rawData) {
        let cleanedData = [];
        if (!rawData || (rawData == null)) {
            return cleanedData;
        }
        rawData.forEach(rawElement => {
            if (!rawElement.ragioneSociale || (rawElement.ragioneSociale == null)) {
                rawElement.ragioneSociale = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
            }
            if (!rawElement.indirizzo || (rawElement.indirizzo == null)) {
                rawElement.indirizzo = "";
            }
            if (!rawElement.localita || (rawElement.localita == null)) {
                rawElement.localita = "";
            }
            if (!rawElement.cap || (rawElement.cap == null)) {
                rawElement.cap = "";
            }
            if (!rawElement.paeseProvincia || (rawElement.paeseProvincia == null)) {
                rawElement.paeseProvincia = "";
            }
            if (!rawElement.partitaIva || (rawElement.partitaIva == null)) {
                rawElement.partitaIva = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
            }
            if (!rawElement.dataAggiornamento || (rawElement.dataAggiornamento == null)) {
                rawElement.dataAggiornamento = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                rawElement.aggiornato='No';
            }else{
                var d1 = Date.parse(rawElement.dataAggiornamento);
                if(AuthService.getLoggedUserPreviousSession()!=null && AuthService.getLoggedUserPreviousSession().startTime!=null){
                    var d2 = Date.parse(AuthService.getLoggedUserPreviousSession().startTime);
                    rawElement.aggiornato=d1 > d2 ? 'Si' : 'No';
                }else{
                    rawElement.aggiornato='Si';
                }
                rawElement.dataAggiornamento = this.formatSessionDateTime(rawElement.dataAggiornamento);
            }
            if (!rawElement.monitoraggioFlag || (rawElement.monitoraggioFlag == null)) {
                rawElement.monitoraggioType = "No";
            }
            if (!rawElement.foreignActualOwners || (rawElement.foreignActualOwners == null)) {
                rawElement.foreignActualOwnersCount = 0;
            } else {
                rawElement.foreignActualOwnersCount = rawElement.foreignActualOwners.length;
                rawElement.foreignActualOwners.forEach(ownerElement => {
                    if (!ownerElement.completeName || (ownerElement.completeName == null)) {
                        ownerElement.completeName = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.bnfcOwnerType || (ownerElement.bnfcOwnerType == null)) {
                        ownerElement.bnfcOwnerType = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.bnfcOwnerLegalForm || (ownerElement.bnfcOwnerLegalForm == null)) {
                        ownerElement.bnfcOwnerLegalForm = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.bnfcTown || (ownerElement.bnfcTown == null)) {
                        ownerElement.bnfcTown = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.bnfcCountry || (ownerElement.bnfcCountry == null)) {
                        ownerElement.bnfcCountry = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.nationality || (ownerElement.nationality == null)) {
                        ownerElement.nationality = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.bnfcDateBirth || (ownerElement.bnfcDateBirth == null)) {
                        ownerElement.bnfcDateBirth = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.bnfcPostalCode || (ownerElement.bnfcPostalCode == null)) {
                        ownerElement.bnfcPostalCode = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.appointedNotifiedOn || (ownerElement.appointedNotifiedOn == null)) {
                        ownerElement.appointedNotifiedOn = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.documentazione || (ownerElement.documentazione == null)) {
                        ownerElement.documentazione = NULL_BOOLEAN_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.note || (ownerElement.note == null)) {
                        ownerElement.note = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    /* VECCHI */
                    if (!ownerElement.role || (ownerElement.role == null)) {
                        ownerElement.role = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.dateOfBirth || (ownerElement.dateOfBirth == null)) {
                        ownerElement.dateOfBirth = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.appointedNotifiedOn || (ownerElement.appointedNotifiedOn == null)) {
                        ownerElement.appointedNotifiedOn = "";
                    }
                    if (!ownerElement.nationality || (ownerElement.nationality == null)) {
                        ownerElement.nationality = "";
                    }
                    if (!ownerElement.countryOfResidence || (ownerElement.countryOfResidence == null)) {
                        ownerElement.countryOfResidence = "";
                    }
                    if (!ownerElement.societaCollegate || (ownerElement.societaCollegate == null)) {
                        ownerElement.societaCollegate = "";
                    }
                    if (!ownerElement.note || (ownerElement.note == null)) {
                        ownerElement.note = "";
                    }
                });
            }
            cleanedData.push(rawElement);
        });
        return cleanedData;
    }

    sum(arr){
        let tot = 0;
        // for(let num of arr){
        //     tot += num;
        // }
        for(let i = 0; i < arr.length; i++){
            tot += arr[i];
        }
        return tot;
    }

    optimizeAndSetGeoChartInfo(chartLabels, chartSerie, numbOfOut) {
        let retIndex = [];
        let tempGeoChartLabels = [];
        let tempGeoChartSeries = [];
        let tempGeoChartMaxY = 1000;
        if ((chartLabels.length === 0) ||
            (chartSerie.length === 0) ||
            (chartSerie.length !== chartLabels.length) ||
            (numbOfOut <= 0)) {
            let chartSeries = [];
            chartSeries.push(tempGeoChartSeries);
            this.setState({
                geoChartLabels: tempGeoChartLabels,
                geoChartSeries: chartSeries,
                geoChartMaxY: tempGeoChartMaxY
            });
            return retIndex;
        }
        if (numbOfOut > chartLabels.length) {
            numbOfOut = chartLabels.length;
        }
        let continueSearch = true;
        let previousMax = -1;
        let currentMax = 1000000;
        while (continueSearch) {
            previousMax = currentMax;
            let maxSearchedIndex = -1;
            let maxSearchedValue = -1;
            for (let i = 0; i < chartSerie.length; i++) {
                if ((chartSerie[i] > maxSearchedValue) && (chartSerie[i] < previousMax)) {
                    maxSearchedIndex = i;
                    maxSearchedValue = chartSerie[i];
                }
            }
            currentMax = maxSearchedValue;
            retIndex.push(maxSearchedIndex);
            for (let j = (maxSearchedIndex + 1); j < chartSerie.length; j++) {
                if (chartSerie[j] == maxSearchedValue) {
                    if (retIndex.length < numbOfOut) {
                        retIndex.push(j);
                    }
                }
            }
            if (retIndex.length >= numbOfOut) {
                continueSearch = false;
            } else {
                continueSearch = true;
            }
        }
        for (let w = 0; w < numbOfOut; w++) {
            let theIndex = retIndex[w];
            tempGeoChartLabels.push(chartLabels[theIndex]);
            tempGeoChartSeries.push(chartSerie[theIndex]);
            if (w === 0) {
                tempGeoChartMaxY = Math.round(chartSerie[theIndex] * 1.2);
            }
        }
        let chartSeries = [];
        chartSeries.push(tempGeoChartSeries);
        this.setState({
            geoChartLabels: tempGeoChartLabels,
            geoChartSeries: chartSeries,
            geoChartMaxY: tempGeoChartMaxY
        });
        return retIndex;
    }

    extractGeoList(list) {
        let geoList = [];
        let geoListValue = [];
        let geoListTemp = [];
        let geoListValueTemp = [];
        //Calcolo l'elenco delle città e il numero di occorreze
        for (let cust of list) {
            if (!geoListTemp.includes(cust.paeseProvincia.toUpperCase())) {
                geoList.push(cust.paeseProvincia.toUpperCase());
                geoListTemp.push(cust.paeseProvincia.toUpperCase());
                geoListValueTemp.push(0);
            }
            let index = geoListTemp.indexOf(cust.paeseProvincia.toUpperCase());
            let value = geoListValueTemp[index];
            geoListValueTemp[index] = value + 1;
        }
        //ordino l'array delle citta
        geoList.sort(function (citta1, citta2) {
            return citta1.localeCompare(citta2);
        });
        //aggiungo l'occorrenze delle città all'array ordinato
        for (let k in geoList) {
            let index = geoListTemp.indexOf(geoList[k]);
            let value = geoListValueTemp[index];
            geoListValue.push(value);
        }
        //Crea le etichette ed i dati per il chart
        let chartLabels = [];
        for (let c in geoList) {
            let index = geoList.indexOf(geoList[c]);
            let value = geoList[index];
            //console.log('§§ ' + JSON.stringify(value));
            chartLabels.push(value);
        }
        let chartSeries = [];
        let singleSerie = [];
        for (let cv in geoListValue) {
            let index = geoListValue.indexOf(geoListValue[cv]);
            let value = geoListValue[index];
            //console.log('§§ ' + JSON.stringify(value));
            singleSerie.push(value);
        }
        chartSeries.push(singleSerie);
        // Fissa quanti elementi devono vedersi nel grafico
        let maxChartItem = 6;
        this.optimizeAndSetGeoChartInfo(chartLabels, singleSerie, maxChartItem);

        //Aggiunge l'elemento di default in testa
        geoList.unshift(ALL_CITTA_LABEL);
        geoListValue.unshift(list.length);

        this.setState({
            geoList: geoList,
            geoListValue: geoListValue,
            numOfGeo: geoListValue[0]
        });
    }

    //MANAGER TIPOLOGIA (PERSONA FISICA O GIURIDICA) INFORMATION

    optimizeAndSetTipologiaChartInfo(chartLabels, chartSerie) {
        let retIndex = [];
        let tempOChartLabels = [];
        let tempOChartSeries = [];
        let tempOChartTotalMax = 0;
        if ((chartLabels.length === 0) ||
            (chartSerie.length === 0) ||
            (chartSerie.length !== chartLabels.length)) {
            this.setState({
                tipologiaChartLabels: tempOChartLabels,
                tipologiaChartSeries: tempOChartSeries,
                tipologiaChartTotal: 100
            });
            return retIndex;
        }
        let countedValues = [];
        let totalValue = 0;
        for (let index = 0; index < chartLabels.length; index++) {
            tempOChartLabels.push(chartLabels[index]);
            countedValues.push(chartSerie[index]);
            totalValue = totalValue + chartSerie[index];
        }
        for (let index = 0; index < countedValues.length; index++) {
            let approxValue = Math.round(((countedValues[index] / totalValue) * 100));
            tempOChartSeries.push(approxValue);
            tempOChartLabels[index] = "" + approxValue + "%  " + tempOChartLabels[index];
            tempOChartTotalMax = tempOChartTotalMax + approxValue;
        }
        //console.log('§§§§§§ ' + tempOChartTotalMax);
        this.setState({
            tipologiaChartLabels: tempOChartLabels,
            tipologiaChartSeries: tempOChartSeries,
            tipologiaChartTotal: tempOChartTotalMax
        });
        return retIndex;
    }

    extractTipologiaList(list) {
        let oList = [];
        let oListValue = [];
        let oListTemp = [];
        let oListValueTemp = [];
        //Calcolo l'elenco delle tipologie e il numero di occorreze
        for (let cust of list) {
            let tipologiaDescription = cust.monitoraggioType;
            if (!oListTemp.includes(tipologiaDescription.toUpperCase())) {
                oList.push(tipologiaDescription.toUpperCase());
                oListTemp.push(tipologiaDescription.toUpperCase());
                oListValueTemp.push(0);
            }
            let index = oListTemp.indexOf(tipologiaDescription.toUpperCase());
            let value = oListValueTemp[index];
            oListValueTemp[index] = value + 1;
        }
        //ordino l'array delle tipologie
        oList.sort(function (item1, item2) {
            return item1.localeCompare(item2);
        });
        //aggiungo l'occorrenze delle tipologie all'array ordinato
        for (let k in oList) {
            let index = oListTemp.indexOf(oList[k]);
            let value = oListValueTemp[index];
            oListValue.push(value);
        }
        //Crea le etichette ed i dati per il chart
        let chartLabels = [];
        for (let c in oList) {
            let index = oList.indexOf(oList[c]);
            let value = oList[index];
            //console.log('§§ ' + JSON.stringify(value));
            chartLabels.push(value);
        }
        //let chartSeries = [];
        let singleSerie = [];
        for (let cv in oListValue) {
            let index = oListValue.indexOf(oListValue[cv]);
            let value = oListValue[index];
            //console.log('§§ ' + JSON.stringify(value));
            singleSerie.push(value);
        }
        //chartSeries.push(singleSerie);
        // Fissa quanti elementi devono vedersi nel grafico
        //let maxChartItem = 1000;
        this.optimizeAndSetTipologiaChartInfo(chartLabels, singleSerie);

        //Aggiunge l'elemento di default in testa
        oList.unshift(ALL_TIPOLOGIA_LABEL);
        oListValue.unshift(list.length);

        this.setState({
            tipologiaList: oList,
            tipologiaListValue: oListValue,
            numOfTipologia: oListValue[0],
            tipologiaName: oList[0]
        });

    }


    //MANAGER TIPOLOGIA (PERSONA FISICA O GIURIDICA) INFORMATION

    optimizeAndSetUsersSessionsChartInfo(chartLabels, chartSerie, maxValue) {       
        maxValue = Math.round(maxValue * 1.5);
        this.setState({
            usersSessionsChartLabels: chartLabels,
            usersSessionsChartSeries: chartSerie,
            usersSessionsChartMax: maxValue
        });
        return;
    }

    extractUsersSessionsList(list) {
        //Crea le etichette ed i dati per il chart
        let chartLabels = [];
        let singleSerie = [];
        let maxValue = 0;
        let minValue = 0;
        list.forEach(element => {
            chartLabels.push(element.dateDescription);
            singleSerie.push(element.sessionCounter);
            if (element.sessionCounter > maxValue) {
                maxValue = element.sessionCounter
            }
            if (element.sessionCounter < minValue) {
                minValue = element.sessionCounter
            }            
        });
        let chartSeries = [];
        chartSeries.push(singleSerie);
        this.optimizeAndSetUsersSessionsChartInfo(chartLabels, chartSeries, maxValue);
    }


    
    //MANAGER STATE INFORMATION

    optimizeAndSetStateChartInfo(chartLabels, chartSerie, numbOfOut) {
        let retIndex = [];
        let tempGeoChartLabels = [];
        let tempGeoChartSeries = [];
        let tempGeoChartMaxY = 1000;
        if ((chartLabels.length === 0) ||
            (chartSerie.length === 0) ||
            (chartSerie.length !== chartLabels.length) ||
            (numbOfOut <= 0)) {
            let chartSeries = [];
            chartSeries.push(tempGeoChartSeries);
            this.setState({
                statiChartLabels: tempGeoChartLabels,
                statiChartSeries: chartSeries,
                statiChartMaxY: tempGeoChartMaxY
            });
            return retIndex;
        }
        if (numbOfOut > chartLabels.length) {
            numbOfOut = chartLabels.length;
        }
        let continueSearch = true;
        let previousMax = -1;
        let currentMax = 1000000;
        while (continueSearch) {
            previousMax = currentMax;
            let maxSearchedIndex = -1;
            let maxSearchedValue = -1;
            for (let i = 0; i < chartSerie.length; i++) {
                if ((chartSerie[i] > maxSearchedValue) && (chartSerie[i] < previousMax)) {
                    maxSearchedIndex = i;
                    maxSearchedValue = chartSerie[i];
                }
            }
            currentMax = maxSearchedValue;
            retIndex.push(maxSearchedIndex);
            for (let j = (maxSearchedIndex + 1); j < chartSerie.length; j++) {
                if (chartSerie[j] == maxSearchedValue) {
                    if (retIndex.length < numbOfOut) {
                        retIndex.push(j);
                    }
                }
            }
            if (retIndex.length >= numbOfOut) {
                continueSearch = false;
            } else {
                continueSearch = true;
            }
        }
        for (let w = 0; w < numbOfOut; w++) {
            let theIndex = retIndex[w];
            tempGeoChartLabels.push(chartLabels[theIndex]);
            tempGeoChartSeries.push(chartSerie[theIndex]);
            if (w === 0) {
                tempGeoChartMaxY = Math.round(chartSerie[theIndex] * 1.2);
            }
        }
        let chartSeries = [];
        chartSeries.push(tempGeoChartSeries);
        this.setState({
            statiChartLabels: tempGeoChartLabels,
            statiChartSeries: chartSeries,
            statiChartMaxY: tempGeoChartMaxY
        });
        return retIndex;
    }


    extractStateList(list) {
        let oList = [];
        let oListValue = [];
        let oListTemp = [];
        let oListValueTemp = [];
        //Calcolo l'elenco degli stati e il numero di occorreze
        for (let cust of list) {
            if (!oListTemp.includes(cust.elaborationState.toUpperCase())) {
                oList.push(cust.elaborationState.toUpperCase());
                oListTemp.push(cust.elaborationState.toUpperCase());
                oListValueTemp.push(0);
            }
            let index = oListTemp.indexOf(cust.elaborationState.toUpperCase());
            let value = oListValueTemp[index];
            oListValueTemp[index] = value + 1;
        }
        //ordino l'array degli stati
        oList.sort(function (o1, o2) {
            return o1.localeCompare(o2);
        });
        //aggiungo l'occorrenze degli stati all'array ordinato
        for (let k in oList) {
            let index = oListTemp.indexOf(oList[k]);
            let value = oListValueTemp[index];
            oListValue.push(value);
        }
        //Crea le etichette ed i dati per il chart
        let chartLabels = [];
        for (let c in oList) {
            let index = oList.indexOf(oList[c]);
            let value = oList[index];
            //console.log('§§ ' + JSON.stringify(value));
            chartLabels.push(value);
        }
        let chartSeries = [];
        let singleSerie = [];
        for (let cv in oListValue) {
            let index = oListValue.indexOf(oListValue[cv]);
            let value = oListValue[index];
            //console.log('§§ ' + JSON.stringify(value));
            singleSerie.push(value);
        }
        chartSeries.push(singleSerie);
        // Fissa quanti elementi devono vedersi nel grafico
        let maxChartItem = 4;
        this.optimizeAndSetStateChartInfo(chartLabels, singleSerie, maxChartItem);

        //Aggiunge l'elemento di default in testa
        oList.unshift(ALL_STATI_LABEL);
        oListValue.unshift(list.length);

        this.setState({
            statiList: oList,
            statiListValue: oListValue,
            numOfStati: oListValue[0],
            statiName: oList[0]
        });
    }

    /*

    //MANAGER STRATEGIA INFORMATION

    optimizeAndSetStrategiaChartInfo(chartLabels, chartSerie, numbOfOut) {
        let retIndex = [];
        let tempChartLabels = [];
        let tempChartSeries = [];
        let tempChartMaxY = 1000;
        if ((chartLabels.length === 0) ||
            (chartSerie.length === 0) ||
            (chartSerie.length !== chartLabels.length) ||
            (numbOfOut <= 0)) {
            let chartSeries = [];
            chartSeries.push(tempChartSeries);
            this.setState({
                strategiaChartLabels: tempChartLabels,
                strategiaChartSeries: chartSeries,
                strategiaChartMaxY: tempChartMaxY
            });
            return retIndex;
        }
        if (numbOfOut > chartLabels.length) {
            numbOfOut = chartLabels.length;
        }
        let continueSearch = true;
        let previousMax = -1;
        let currentMax = 1000000;
        while (continueSearch) {
            previousMax = currentMax;
            let maxSearchedIndex = -1;
            let maxSearchedValue = -1;
            for (let i = 0; i < chartSerie.length; i++) {
                if ((chartSerie[i] > maxSearchedValue) && (chartSerie[i] < previousMax)) {
                    maxSearchedIndex = i;
                    maxSearchedValue = chartSerie[i];
                }
            }
            currentMax = maxSearchedValue;
            retIndex.push(maxSearchedIndex);
            for (let j = (maxSearchedIndex + 1); j < chartSerie.length; j++) {
                if (chartSerie[j] == maxSearchedValue) {
                    if (retIndex.length < numbOfOut) {
                        retIndex.push(j);
                    }
                }
            }
            if (retIndex.length >= numbOfOut) {
                continueSearch = false;
            } else {
                continueSearch = true;
            }
        }
        for (let w = 0; w < numbOfOut; w++) {
            let theIndex = retIndex[w];
            tempChartLabels.push(chartLabels[theIndex]);
            tempChartSeries.push(chartSerie[theIndex]);
            if (w === 0) {
                tempChartMaxY = Math.round(chartSerie[theIndex] * 1.2);
            }
        }
        let chartSeries = [];
        chartSeries.push(tempChartSeries);
        this.setState({
            strategiaChartLabels: tempChartLabels,
            strategiaChartSeries: chartSeries,
            strategiaChartMaxY: tempChartMaxY
        });
        return retIndex;
    }


    extractStrategiaList(list) {
        let oList = [];
        let oListValue = [];
        let oListTemp = [];
        let oListValueTemp = [];
        //Calcolo l'elenco delle strategie e il numero di occorreze
        for (let cust of list) {
            if (!oListTemp.includes(cust.strategia.toUpperCase())) {
                oList.push(cust.strategia.toUpperCase());
                oListTemp.push(cust.strategia.toUpperCase());
                oListValueTemp.push(0);
            }
            let index = oListTemp.indexOf(cust.strategia.toUpperCase());
            let value = oListValueTemp[index];
            oListValueTemp[index] = value + 1;
        }
        //ordino l'array delle strategie
        oList.sort(function (o1, o2) {
            return o1.localeCompare(o2);
        });
        //aggiungo l'occorrenze delle strategie all'array ordinato
        for (let k in oList) {
            let index = oListTemp.indexOf(oList[k]);
            let value = oListValueTemp[index];
            oListValue.push(value);
        }
        //Crea le etichette ed i dati per il chart
        let chartLabels = [];
        for (let c in oList) {
            let index = oList.indexOf(oList[c]);
            let value = oList[index];
            //console.log('§§ ' + JSON.stringify(value));
            chartLabels.push(value);
        }
        let chartSeries = [];
        let singleSerie = [];
        for (let cv in oListValue) {
            let index = oListValue.indexOf(oListValue[cv]);
            let value = oListValue[index];
            //console.log('§§ ' + JSON.stringify(value));
            singleSerie.push(value);
        }
        chartSeries.push(singleSerie);
        // Fissa quanti elementi devono vedersi nel grafico
        let maxChartItem = 3;
        this.optimizeAndSetStrategiaChartInfo(chartLabels, singleSerie, maxChartItem);

        //Aggiunge l'elemento di default in testa
        oList.unshift(ALL_STRATEGIA_LABEL);
        oListValue.unshift(list.length);

        this.setState({
            strategiaList: oList,
            strategiaListValue: oListValue,
            numOfStrategia: oListValue[0],
            strategiaName: oList[0]
        });
    } */




formatSessionDateTime(dateTime) {
  //console.log(JSON.stringify(dateTime));
  var date = new Date(dateTime);
  return (new Intl.DateTimeFormat("it", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }).format(date));
}

}

export default withStyles(styles)(Dashboard);
