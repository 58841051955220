import React from 'react'

import * as FileSaver from 'file-saver';

import * as XLSX from 'xlsx';


import Button from 'components/CustomButtons/Button'
import UploadFile from "@material-ui/icons/InsertDriveFile";

export const ExcelFileDownloader = ({entries, fileName}) => {



    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';




    const createAndSave = (entries, fileName) => {

        const ws = XLSX.utils.json_to_sheet(entries);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], {type: fileType});

        FileSaver.saveAs(data, fileName + fileExtension);

    }



    return (
        // <button  onClick={(e) => exportToCSV(entries,fileName)}>Export</button>

        <Button 
                        style={{fontSize: '15px'}}                   
                        color="reddit"
                        
                        onClick={(e) => createAndSave(entries, fileName)}
                        round>
                          Download template&nbsp;&nbsp;<UploadFile style={{fontSize: '28px'}} />
                      </Button>
    )

}