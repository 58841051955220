/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const classes = makeStyles(styles);

export default function Footer(props) {
  return (
    <Card style={{height: '100px', margin: 'auto'}}>        
      <CardBody style={{height: '40px'}}>
      <footer className={classes.footer} >
        <div className={classes.container} style={{position: 'relative', margin: 'auto', padding: '20px', textAlign: 'center', verticalAlign: 'top'}}>
          <div style={{float:'left'}}>
            <p >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="http://www.arval.it/"
                  target="_blank"
                  className={classes.block} >   
                  Servizi di Noleggio
                </a>        
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://www.arval.it/arval-italia"
                  target="_blank"
                  className={classes.block} >   
                  Arval Italia
                </a>                
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://www.arval.it/assistenza-noleggio"
                  target="_blank"
                  className={classes.block} >   
                  Assistenza Noleggio
                </a>                 

            </p>
          </div>
          <div style={{float:'right'}}>
          <p >
            <span >
              &copy; {1900 + new Date().getYear()}{" "}
              powered by &nbsp;
              <a
                href="http://www.sintegra.it/"
                target="_blank"
                className={classes.a}
              >
                Sintegra Group
              </a>
            </span>
          </p>
          </div>
        </div>
      </footer>
      </CardBody>
    </Card>
  );
}