import React from 'react';
import PropTypes from 'prop-types';

import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";

import * as AuthService from 'services/AuthService.js';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import {
  tooltip
} from "assets/jss/material-dashboard-pro-react.js";
import Tooltip from '@material-ui/core/Tooltip';
import SwapVerticalCircle from "@material-ui/icons/SwapVerticalCircle";
import Flag from "@material-ui/icons/Flag";
import Score from "@material-ui/icons/Score";
import Description from "@material-ui/icons/Description";

import * as CustomersManagementTabs from 'views/COMMON/CustomersManagement';

export const SCORING_BASE_ARROW = 0;
export const SCORING_INTERMEDIATE_FLAG = 1;
export const SCORING_ADVANCED_NUMERIC = 2;
export const SCORING_REPORT = 3;


const localStyles = {
  icon: {
    verticalAlign: "middle",
    textAlign: "center",
    width: "23px",
    height: "23px",
    top: "0px",
    left: "2px",
    position: "relative"
  },
  actionButton: {
    margin: "5px 5px 5px 5px",
    padding: "0px",
    width: "30px",
    height: "30px",
    minWidth: "auto",
    fontSize: "20px"
  },  
  dialogConfirmButton: {
    margin: "0 0 0 5px",
    padding: "20px", 
    width: "80px",
    height: "auto",
    minWidth: "auto"
  },
  dialogCancelButton: {
    margin: "0 0 0 5px",
    padding: "7px", 
    width: "auto",
    height: "auto",
    minWidth: "auto"
  }
}

const styles = {
  ...localStyles,
  ...extendedTablesStyle
}


class CustomersList extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {tableData: [], 
            currentLoggedUser: AuthService.getLoggedUserObject(),
            currentLoggedToken: AuthService.getLoggedUserJwtToken(),
            currentAppName: AuthService.getApplicationName(),
            message: '',
            alert: null};
  }

  componentDidMount() {
    this.extractAllUsersList();  
  }

  componentWillUnmount() {
  }

  render() {
    const { classes } = this.props;
    let tableHead = [];
    if (!this.props.readOnly) {
      tableHead.push("Scoring CASCO");
    }
    tableHead.push("Denominazione societaria");
    tableHead.push("Forma societaria");
    tableHead.push("Settore di attività");
    tableHead.push("Città");
    let actionButtonsColumnClasses = [];
    let actionButtonsColumn = [];
    let actionButtonsColumnHeaderClasses = [];
    let actionButtonsColumnHeader = [];
    if (!this.props.readOnly) {
      actionButtonsColumnClasses = [classes.centeredQuadrupleActionButtonsColumn];
      actionButtonsColumn = [0];
      actionButtonsColumnHeaderClasses = [classes.centeredQuadrupleActionButtonsColumnHead];
      actionButtonsColumnHeader = [0];
    }
    return (
      <div style={{width: "99%", marginLeft: "3px"}}>
        {this.state.alert}
        <Table
          tableHeaderColor="info"
          tableHead={tableHead}
          tableData={this.state.tableData}
          customCellClasses={actionButtonsColumnClasses}
          customClassesForCells={actionButtonsColumn}
          customHeadCellClasses={actionButtonsColumnHeaderClasses}
          customHeadClassesForCells={actionButtonsColumnHeader}
        />
      </div>
    )
  }

  doActionForEnabledUsers(buttonIndex, rowKeyForActions) {
    this.props.customTabsRef.current.setTypeForDynamicTabs(buttonIndex);
    this.props.customTabsRef.current.setItemForDynamicTabs(rowKeyForActions);
    if (buttonIndex === SCORING_BASE_ARROW) {
      this.props.customTabsRef.current.setIconForDynamicTabs(SwapVerticalCircle);
    } else if (buttonIndex === SCORING_INTERMEDIATE_FLAG) {
      this.props.customTabsRef.current.setIconForDynamicTabs(Flag);
    } else if (buttonIndex === SCORING_ADVANCED_NUMERIC) {
      this.props.customTabsRef.current.setIconForDynamicTabs(Score);
    } else if (buttonIndex === SCORING_REPORT) {
      this.props.customTabsRef.current.setIconForDynamicTabs(Description);
    }
    this.props.customTabsRef.current.setSelectedTab(CustomersManagementTabs.CUSTOMER_SCORING_TAB_INDEX);
    this.props.customTabsRef.current.showDynamicTabs();
  }

 
  getButtonsGroupForEnabledUsers(rowKeyForActions) {
    return [
      { color: "infoMini_0", icon: SwapVerticalCircle, tooltipText: "Visualizza i dettagli dello scoring di tipo Arrow (Base)..." },
      { color: "infoMini_1", icon: Flag, tooltipText: "Visualizza i dettagli dello scoring di tipo Flag (Intermediate)..." },
      { color: "infoMini_2", icon: Score, tooltipText: "Visualizza i dettagli dello scoring di tipo Indicatore Numerico (Advanced)..." },
      { color: "infoMini_3", icon: Description, tooltipText: "Visualizza i dettagli del Rapporto di Scoring (Report)..." }
    ].map((prop, key) => {
      return (
        <Tooltip
          id="tooltip-x"
          title={prop.tooltipText}
          placement="bottom"
          classes={styles.tooltip}
        >        
          <Button
            color={prop.color}
            style={localStyles.actionButton}
            key={key}
            onClick={() => { this.doActionForEnabledUsers(key, rowKeyForActions) }}          
          >
            <prop.icon style={localStyles.icon} />
          </Button>
        </Tooltip>          
      );
    });
  }

  convertCustomersListToTableData(jsonUsersData) {
    let rowKeyForActions = '';
    let retArray = [];
    jsonUsersData.forEach(element => {
      rowKeyForActions = element.denominazione;
      let singleArray = [];      
      if (!this.props.readOnly) {
        singleArray.push(this.getButtonsGroupForEnabledUsers(rowKeyForActions));
      }
      singleArray.push((element.denominazione)? element.denominazione: '');
      singleArray.push((element.formaSocietaria)? element.formaSocietaria: '');
      singleArray.push((element.settore)? element.settore: '');    
      singleArray.push((element.citta)? element.citta: '');      
      retArray.push(singleArray);
    });
    return retArray;
  }


  extractAllUsersList() {
    let customers = [
      {
        "id": 1,
        "denominazione": "Mario Rossi Servizi",
        "formaSocietaria": "Società in accomandita semplice",
        "settore": "Servizi alle imprese",
        "citta": "Milano"
      },
      {
        "id": 2,
        "denominazione": "Bianchi Componenti Meccanici",
        "formaSocietaria": "Società per azioni",
        "settore": "Forniture meccaniche",
        "citta": "Torino"
      },
      {
        "id": 3,
        "denominazione": "Verdi & Giallo",
        "formaSocietaria": "Società a responsabilità limitata",
        "settore": "Gestioni finanziarie",
        "citta": "Roma"
      }
    ];
    let dataForTable = this.convertCustomersListToTableData(customers);          
    this.setState({tableData: dataForTable, message: ''});  
  }

}

CustomersList.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  customTabsRef: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomersList);