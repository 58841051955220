import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  appMainColors,
  utpGeoChartColors,
  utpTipologiaChartColors,
  utpStateChartColors,
  utpStrategiaChartColors
} from "assets/jss/material-dashboard-pro-react.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Badge from "components/Badge/Badge.js";

import styles from "assets/jss/material-dashboard-pro-react/components/timelineStyle.js";

const useStyles = makeStyles(styles);

export default function Timeline(props) {
  const classes = useStyles();
  const { stories, simple } = props;
  const timelineClass =
    classes.timeline +
    " " +
    cx({
      [classes.timelineSimple]: simple
    });
  return (
    <ul className={timelineClass}>
      {stories.map((prop, key) => {
        const panelClasses =
          classes.timelinePanel +
          " " +
          cx({
            [classes.timelinePanelInverted]: prop.inverted || simple,
            [classes.timelineSimplePanel]: simple
          });
        const timelineBadgeClasses =
          classes.timelineBadge +
          " " +
          classes[prop.badgeColor] +
          " " +
          cx({
            [classes.timelineSimpleBadge]: simple
          });
        return (
          <li className={classes.item} key={key}>
            {prop.badgeIcon ? (
              <div className={timelineBadgeClasses}>
                <table style={{width: '100%'}}>
                  <tbody>
                  <tr style={{width: '100%', verticalAlign: 'middle!important'}}>
                    <td style={{width: '20%'}}>
                      <prop.badgeIcon className={classes.badgeIcon} />
                    </td>
                    <td style={{width: '20%', verticalAlign: 'middle'}}>
                      <span style={{verticalAlign: 'middle!important'}}>{prop.title}</span>
                    </td>                    
                  </tr>   
                  </tbody>               
                </table> 
              </div>
            ) : null}
            <div className={panelClasses}>
              <div className={classes.timelineBody}>{prop.body}</div>
              {prop.footerTitle ? (
                <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
              ) : null}
              {prop.footer ? <hr className={classes.footerLine} /> : null}
              {prop.footer ? (
                <div className={classes.timelineFooter}>{prop.footer}</div>
              ) : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

Timeline.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool
};
