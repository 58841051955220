import React from 'react';
import PropTypes from 'prop-types';

import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select/Select";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";
import Divider from '@material-ui/core/Divider';
import CommonDropdown from 'components/COMMON/CommonDropDown.js';

import * as AuthService from 'services/AuthService.js';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import {
    tooltip
} from "assets/jss/material-dashboard-pro-react.js";
import Tooltip from '@material-ui/core/Tooltip';
import Contacts from "@material-ui/icons/Contacts";
import Business from "@material-ui/icons/Business";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import SettingApplication from "@material-ui/icons/SettingsApplications";

import * as FilterableMultiCustomersManagementTabs from 'components/COMMON/FilterableMultiCustomersManagement';

import * as Dashboard from 'views/TEE/Dashboard';

import CommonCustomIcon from "views/COMMON/svgicons/CommonCustomIcon.js";

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import {axiosApiCaller} from 'layouts/AxiosService';
import axios from 'axios';
import AssignmentIcon from '@material-ui/icons/Assignment';
import RequestResult from 'views/COMMON/RequestResult';
import {Link} from "react-router-dom";


const localStyles = {
    icon: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "21px",
        height: "21px",
        top: "0px",
        left: "2px",
        position: "relative"
    },
    selectIcon: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "21px",
        height: "21px",
        top: "0px",
        marginLeft: "1px",
        marginRight: "6px",
        position: "relative"
    },
    actionButton: {
        margin: "5px 5px 5px 5px",
        padding: "0px",
        width: "30px",
        height: "30px",
        minWidth: "auto",
        fontSize: "20px",
    },
    dialogConfirmButton: {
        margin: "0 0 0 5px",
        padding: "20px",
        width: "80px",
        height: "auto",
        minWidth: "auto"
    },
    dialogCancelButton: {
        margin: "0 0 0 5px",
        padding: "7px",
        width: "auto",
        height: "auto",
        minWidth: "auto"
    },
    actionIconButton: {
        margin: "5px 5px 5px 5px",
        padding: "0px",
        minWidth: "auto",
        fontSize: "20px",
        background: "none",
        cursor: "pointer"
    },
}

const styles = {
    ...localStyles,
    ...extendedTablesStyle,
    ...customSelectStyle
}

var delays2 = 80,
    durations2 = 500;

class FilterableMultiRequestsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        //this.handleChangeScoreType = this.handleChangeScoreType.bind(this);
        //this.resetFilters = this.resetFilters.bind(this);
    }

    getInitialState() {
        let initialState = {
            currentLoggedUserCanManageVisualizeResult: (AuthService.authenticathedUserCanManageUsers()),
            //tableData: [],
            message: '',
            alert: null
        };
        return initialState;
    }

    
    componentWillMount() {
        //this.lastRefreshTime = new Date();
        //setInterval(() => {
            //this.setState({ currentMinutesRefreshDelay: Math.floor((((new Date()) - this.lastRefreshTime) / 1000) / 60) });
            //console.log(')($($)£.........');
        //}, 30001);
    }

    componentDidMount() {
        
    }


formatSessionDateTime(dateTime) {
  //console.log(JSON.stringify(dateTime));
  var date = new Date(dateTime);
  return (new Intl.DateTimeFormat("it", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }).format(date));
}

    render() {
        let filteredDataTable = this.extractList();
        const { classes } = this.props;


        let tableHead = this.props.headersList;

        let actionButtonsColumnClasses = [];
        let actionButtonsColumn = [];
        let actionButtonsColumnHeaderClasses = [];
        let actionButtonsColumnHeader = [];
        /*if (!this.props.readOnly) {
            actionButtonsColumnClasses = [classes.centeredSingleActionButtonsColumnMedium, classes.centeredSingleActionButtonsColumnMedium, classes.centeredSingleActionButtonsColumnLarge];
            actionButtonsColumn = [0, 1, 2];
            actionButtonsColumnHeaderClasses = [classes.centeredSingleActionButtonsColumnMedium, classes.centeredSingleActionButtonsColumnMedium, classes.centeredSingleActionButtonsColumnLarge];
            actionButtonsColumnHeader = [0, 1, 2];
        } else {
            actionButtonsColumnClasses = [classes.centeredBooleanColumn];
            actionButtonsColumn = [0, 1];
            actionButtonsColumnHeaderClasses = [, classes.centeredBooleanColumnHead];
            actionButtonsColumnHeader = [0, 1];
        }*/

        return (
            <div style={{ width: "99%", marginLeft: "3px" }}>

                <Divider style={{ width: '98%' }} />
                <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Table
                            tableHeaderColor="info"
                            tableHead={tableHead}
                            tableData={filteredDataTable}
                            customCellClasses={actionButtonsColumnClasses}
                            customClassesForCells={actionButtonsColumn}
                            customHeadCellClasses={actionButtonsColumnHeaderClasses}
                            customHeadClassesForCells={actionButtonsColumnHeader}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }

    doActionForEnabledUsers(event, buttonIndex, descriptKeyForActions, objKeyForActions) {
        let isControlPressed = false;
        if (event) {
            if (event.ctrlKey) {
                isControlPressed = true;
            }
        }
        let type = null;
        let icon = null;
        let key = null;
            type = Dashboard.PERSONA_GIURIDICA_LABEL;
            icon = Business;

        if (objKeyForActions.ragioneSociale) {
            key = objKeyForActions.ragioneSociale;
        }
        this.props.customTabsRef.current.showDynamicTab(key, descriptKeyForActions, icon, objKeyForActions, isControlPressed);
    }


    getButtonsGroupForEnabledUsers(descriptKeyForActions, objKeyForActions, strategia, dataInserimento) {
        return (
            <Tooltip
                id="tooltip-x"
                title="Visualizza dettagli della posizione"
                placement="bottom"
                classes={styles.tooltip}
            >
                <div>
                    <a
                        style={localStyles.actionIconButton}
                        onClick={(e) => { this.doActionForEnabledUsers(e, 1, descriptKeyForActions, objKeyForActions) }}
                    >
                        <CommonCustomIcon icon={"Posizione"} />
                    </a>
                    <div style={{ color: 'black', fontSize: '12px' }}>{dataInserimento ? ((new Date(dataInserimento)).toLocaleDateString()) : ""}</div>
                </div>
            </Tooltip>
        );
    }

    addDownloadButton(idRequest){
        return ( 
            <div>
                <IconButton aria-label="download" onClick={() => { this.downloadFileOfRequest(idRequest) }}>
                <CloudDownloadIcon />
                </IconButton>
            </div>
        );
    }

    // addViewResponseOfRequestButton(idRequest){
    //     return ( 
    //         <div>
    //             <Link to={{pathname: '/consolle/request-result', state: { idRequest: idRequest }}}>
    //                 <IconButton aria-label="info">
    //                 <AssignmentIcon />
    //                 </IconButton>
    //             </Link>                
    //         </div>
    //     );
    // }

    downloadFileOfRequest(idRequest){
        axios.get(AuthService.getBackendApiTeeRequestFileDownload() + "?idRequest=" + idRequest,
        {
            responseType: 'blob',
            headers: {
                'Accept': 'application/octet-stream',
                'Authorization' : AuthService.getLoggedUserJwtToken()
            }
        })
        .then((response) => {
            console.log(response);
            var headers = new Headers(response.headers);
            var contentDisposition= headers.get('Content-Disposition');
            var indiceFilename = contentDisposition.search("="); //filename=nomeFileIniziale...Mi prendo l'indice dell'uguale e faccio substring
            var nomeDefault = contentDisposition.substring(indiceFilename+1, contentDisposition.length);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nomeDefault);
            //document.body.appendChild(link);
            link.click();
        })
        .catch((error) => console.log(error));
    }

    downloadFileOfRequestV2(idRequest){
            axios.get(AuthService.getBackendApiTeeRequestFileV2Download() + "?idRequest=" + idRequest,
            {
                responseType: 'blob',
                headers: {
                    'Accept': 'application/octet-stream',
                    'Authorization' : AuthService.getLoggedUserJwtToken()
                }
            })
            .then((response) => {
                console.log(response);
                var headers = new Headers(response.headers);
                var contentDisposition= headers.get('Content-Disposition');
                var indiceFilename = contentDisposition.search("="); //filename=nomeFileIniziale...Mi prendo l'indice dell'uguale e faccio substring
                var nomeDefault = contentDisposition.substring(indiceFilename+1, contentDisposition.length);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', nomeDefault);
                //document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));
        }

        downloadFileOfRequestXlsV2(idRequest){
                axios.get(AuthService.getBackendApiTeeRequestFileXlsV2Download() + "?idRequest=" + idRequest,
                {
                    responseType: 'blob',
                    headers: {
                        'Accept': 'application/octet-stream',
                        'Authorization' : AuthService.getLoggedUserJwtToken()
                    }
                })
                .then((response) => {
                    console.log(response);
                    var headers = new Headers(response.headers);
                    var contentDisposition= headers.get('Content-Disposition');
                    var indiceFilename = contentDisposition.search("="); //filename=nomeFileIniziale...Mi prendo l'indice dell'uguale e faccio substring
                    var nomeDefault = contentDisposition.substring(indiceFilename+1, contentDisposition.length);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', nomeDefault);
                    //document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => console.log(error));
            }

    getIconStatoLavorazione(statoLavorazione) {
        return (
            <div>
                <CommonCustomIcon icon={statoLavorazione} />
            </div>
        );
    }

    convertCustomersListToTableData(jsonUsersData,fields) {
        let descriptiveKeyForActions = '';
        let objectKeyForActions = null;
        let idRequest = null;
        let retArray = [];
        jsonUsersData.forEach(element => {
            objectKeyForActions = element;
            descriptiveKeyForActions = (element.ragioneSociale && element.ragioneSociale!=null)? element.ragioneSociale: element.idSoggetto;
            descriptiveKeyForActions += (element.paeseProvincia &&  element.paeseProvincia!=null)? ' (' + element.paeseProvincia + ')': '';
            idRequest = element.id;
            
            let singleArray = [];

            //singleArray.push(this.addDownloadButton(idRequest));
            // if(this.state.currentLoggedUserCanManageVisualizeResult){
            //     singleArray.push(this.addViewResponseOfRequestButton(idRequest));
            // }            
            if (!this.props.readOnly) {
                //singleArray.push(this.getButtonsGroupForEnabledUsers(descriptiveKeyForActions, objectKeyForActions, element.strategia, element.dataCaricamento));
                //singleArray.push(this.addDownloadButton(idRequest));
            }


            let fieldsList=this.props.fieldsList;
            fieldsList.forEach(field => {
            if(field==="receivedDate"){
                singleArray.push(element[field]!==null || element[field]!==undefined ?
                                        <Link
                                            style={{ textDecoration: 'none', color: 'black', padding: '20px', cursor: 'pointer' }}
                                            type={field==="receivedDate"?"date":null}
                                            to={{
                                                pathname: "/consolle/request-result",
                                                state: { idRequest: element.id }
                                            }}
                                        >
                                            {element[field]}
                                        </Link>

                                        : '');
            }else{
                singleArray.push((element[field]) ? element[field] : '');
                }
            });
            retArray.push(singleArray);

        });
        return retArray;
    }

    filterList() {
        let allItemsList = this.props.itemsList;
        let filteredList = [];
        if ((allItemsList !== null) && (allItemsList.length > 0)) {
            let listLength = allItemsList.length;
            for (let item of allItemsList) {
                //  console.log('%%%%%%%%%%%%%');
                // console.log('ONE ITEM');
                let filterInItem = true;

                if(this.props.showGeoName==null || this.props.showTipologiaName==null){
                    filterInItem=true;
                }else{
                if (this.props.showMonitored === false) {
                    //console.log('CRITERIA NOT SHOW MONITORED');
                    if (item.monitoraggio != "NO") {
                        filterInItem = filterInItem && false;
                    } else {
                        filterInItem = filterInItem && true;
                    }
                }
                if (this.props.showNotMonitored === false) {
                    //console.log('CRITERIA NOT SHOW NOT MONITORED');
                    if (item.monitoraggio == "NO") {
                        filterInItem = filterInItem && false;
                    } else {
                        filterInItem = filterInItem && true;
                    }
                }
                //if (this.state.filterType !== null) {

                    if (this.props.showGeoName.toUpperCase() === (Dashboard.ALL_CITTA_LABEL).toUpperCase()) {
                        filterInItem = filterInItem && true;
                    } else if (item.paeseProvincia != null) {
                        if (item.paeseProvincia.toUpperCase() === this.props.showGeoName.toUpperCase()) {
                            filterInItem = filterInItem && true;
                        } else {
                            filterInItem = filterInItem && false;
                        }
                    } else {
                        filterInItem = filterInItem && false;
                    }
                    //END MANAGER GEO FILTER

                    if (this.props.showTipologiaName.toUpperCase() === (Dashboard.ALL_TIPOLOGIA_LABEL).toUpperCase()) {
                        filterInItem = filterInItem && true;
                    } else if (item.monitoraggioType != null) {
                        if (item.monitoraggioType.toUpperCase() === this.props.showTipologiaName.toUpperCase()) {
                            filterInItem = filterInItem && true;
                        } else {
                            filterInItem = filterInItem && false;
                        }
                    } else {
                        filterInItem = filterInItem && false;
                    }
                    }
                    /*
                    //END MANAGER TIPOLOGIA FILTER
                    if (this.props.showStatoName.toUpperCase() === (Dashboard.ALL_STATI_LABEL).toUpperCase()) {
                        filterInItem = filterInItem && true;
                    } else if (item.statoLavorazione != null) {
                        if (item.statoLavorazione.toUpperCase() === this.props.showStatoName.toUpperCase()) {
                            filterInItem = filterInItem && true;
                        } else {
                            filterInItem = filterInItem && false;
                        }
                    } else {
                        filterInItem = filterInItem && false;
                    }
                    //END MANAGER STATE FILTER
                    if (this.props.showStrategiaName.toUpperCase() === (Dashboard.ALL_STRATEGIA_LABEL).toUpperCase()) {
                        filterInItem = filterInItem && true;
                    } else if (item.strategia != null) {
                        if (item.strategia.toUpperCase() === this.props.showStrategiaName.toUpperCase()) {
                            filterInItem = filterInItem && true;
                        } else {
                            filterInItem = filterInItem && false;
                        }
                    } else {
                        filterInItem = filterInItem && false;
                    }
                    //END MANAGER STRATEGIA FILTER
                    */
                //}//END FILTER
                if (filterInItem) {
                    filteredList.push(item);
                }
            }
        }
        return filteredList;
    }


    extractList() {
        let dataForTable = this.convertCustomersListToTableData(this.filterList());
        //this.setState({tableData: dataForTable, message: ''});  
        return dataForTable;
    }


}

FilterableMultiRequestsList.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    customTabsRef: PropTypes.object.isRequired,
    itemsList: PropTypes.arrayOf(
        PropTypes.shape({
            personaFisica: PropTypes.bool,
            ragioneSociale: PropTypes.string,
            codiceFiscale: PropTypes.string,
            comune: PropTypes.string,
            dataCaricamento: PropTypes.string,
            statoLavorazione: PropTypes.string,
            monitoraggioAttivo: PropTypes.bool,
            strategia: PropTypes.string
        })
    ).isRequired,
    showMonitored: PropTypes.bool.isRequired,
    showNotMonitored: PropTypes.bool.isRequired,
    showGeoName: PropTypes.string.isRequired,
    showTipologiaName: PropTypes.string.isRequired,
    showStatoName: PropTypes.string.isRequired,
    showStrategiaName: PropTypes.string.isRequired
};

export default withStyles(styles)(FilterableMultiRequestsList);