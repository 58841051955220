import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
//import Chartist from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";
import ResetFilters from "@material-ui/icons/LayersClear";
import ReloadData from "@material-ui/icons/CloudDownload";
import ToggleOn from "@material-ui/icons/ToggleOn";
import ToggleOff from "@material-ui/icons/ToggleOff";
import SweetAlert from "react-bootstrap-sweetalert";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Tooltip from '@material-ui/core/Tooltip';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CommonDropdown from 'components/COMMON/CommonDropDown.js';
import * as AuthService from 'services/AuthService.js';
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import checkBoxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import FilterableMultiCustomersManagement from "components/COMMON/FilterableMultiCustomersManagement";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from '@material-ui/core/FormControl';
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select/Select";

import {axiosApiCaller} from 'layouts/AxiosService';

const localStyles = {
    icon: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "23px",
        height: "23px",
        top: "0px",
        left: "2px",
        position: "relative"
    },
    actionButton: {
        margin: "5px 5px 5px 5px",
        padding: "0px",
        width: "30px",
        height: "30px",
        minWidth: "auto",
        fontSize: "20px"
    },
    dialogConfirmButton: {
        margin: "0 0 0 5px",
        padding: "20px",
        width: "80px",
        height: "auto",
        minWidth: "auto"
    },
    dialogCancelButton: {
        margin: "0 0 0 5px",
        padding: "7px",
        width: "auto",
        height: "auto",
        minWidth: "auto"
    }
}

const styles = {
    ...localStyles,
    ...dashboardStyles,
    ...checkBoxStyles
}

// COSTANTI PER I VALORI NON DEFINITI O NULLI
export const NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE = '(Non disponibile)';
export const NULL_STRATEGIA_SUBSTITUTION_VALUE = 'Nessuna Strategia';
export const NULL_STATO_SUBSTITUTION_VALUE = 'Nessun Stato di Elaborazione';
export const NULL_BOOLEAN_DATA_SUBSTITUTION_VALUE = false;

const AUTO_CLOSE_TIME_DENIED_OPERATION = 4000;

var delays = 80,
    durations = 500;
var delays2 = 80,
    durations2 = 500;


class RequestResult extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.lastRefreshTime = new Date();

    this.onKeyUp = this.onKeyUp.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleShowMonitoredChange = this.handleShowMonitoredChange.bind(this);
    this.deniedOperation = this.deniedOperation.bind(this);
    this.handleChangeSelectedElaborationState = this.handleChangeSelectedElaborationState.bind(this);
  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.extractAllCustomersListFromBackend();
    }
  }

    getInitialState() {
        return {
            alert: null,
            usersSessionsList: [],
            customersList: [],
            usersSessionsChartLabels: [],
            usersSessionsChartSeries: [],
            usersSessionsChartMax: 100,
            currentMinutesRefreshDelay: 0,
            currentSelectedElaborationState: null
        };
    }

    hideAlert () {
        this.setState({ alert: null });
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.extractAllCustomersListFromBackend();
    }

    componentWillUnmount() {
    }


handleChangeName(event) {
  let newValue = event.target.value.trim();
  this.setState({ name: newValue });
  if (newValue!= null) {
    if (newValue.length<3) {
      this.setState({ nameValid: false });
      this.setState({ errorMessage: 'La lunghezza della denominazione non è valida' });
    } else {
      this.setState({ nameValid: true });
      this.setState({ errorMessage: null });
    }
  } else {
    this.setState({ nameValid: false });
    this.setState({ errorMessage: 'La denominazione non può essere vuota' });
  }
}
    handleShowMonitoredChange  (event)  {
        this.setState({ showMonitored: event.target.checked });
        if (event.target.checked) {
            this.showMonitoredLabel = "Includi le posizioni monitorate";
        } else {
            this.showMonitoredLabel = "Escludi le posizioni monitorate";
        }
    };


    handleFiltering(filter) {
    }

    resetFilters() {
        this.setState(
            {
                showMonitored: true
            }
        );
    }

    handleChangeSelectedElaborationState(event) {
      event.preventDefault();
      if (typeof event.target != "undefined") {
        const newValue = event.target.value;
        this.setState({ currentSelectedElaborationState: newValue });
        this.extractAllCustomersListFromBackend( );
      }
  }
 

    render() {
        const { classes } = this.props;

        //Attenzione il bottone dettaglio non viene passato nella lista dei fields
        let headersList=["Dettaglio","Ragione Sociale", "Data aggiornamento", "Stato di elaborazione","Messaggio elaborazione"];
        let fieldsList=["ragioneSociale","dataAggiornamento","elaborationState","elaborationMessage"];

        let showMonitoredControl = null;
        if (this.state.showMonitored === true) {
            showMonitoredControl = (<a href="#"><ToggleOn onClick={(event) => this.handleShowNotMonitoredChange(event)} style={{ float: 'right', margin: '0px 0px 0px 0px', width: '34px', height: '34px' }} /></a>);
        }
        return (
      <div>
        {this.state.alert}
        <form id="create-search-form">
          <GridContainer>
            <FormControl fullWidth onKeyPress={this.onKeyUp}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ fontSize: "15px", margin: "0px 30px 0px 30px" }}
              >
                <CustomInput
                  success={this.state.nameValid == true}
                  error={this.state.nameValid == false}
                  labelText="Cerca"
                  inputProps={{
                    value: this.state.name,
                    disabled: this.props.readOnly,
                    type: "text",
                    onChange: (event) => {
                      this.handleChangeName(event);
                    },
                  }}
                  customStyle={{ minWidth: "500px" }}
                />
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  displayEmpty
                  value={this.state.currentSelectedElaborationState}
                  onChange={this.handleChangeSelectedElaborationState}
                  formControlProps={{
                    fullWidth: true,
                  }}
                >
                  <MenuItem
                    value={null}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"Select elaboration state*"}
                  </MenuItem>
                  <MenuItem
                    key={1}
                    value={"ATTENZIONE"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"ATTENZIONE"}
                  </MenuItem>
                  <MenuItem
                    key={2}
                    value={"SOSPESO"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"SOSPESO"}
                  </MenuItem>
                  <MenuItem
                    key={3}
                    value={"CARICAMENTO"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"CARICAMENTO"}
                  </MenuItem>
                  <MenuItem
                    key={4}
                    value={"COMPLETATO"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"COMPLETATO"}
                  </MenuItem>
                  <MenuItem
                    key={5}
                    value={"ERRORE"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"ERRORE"}
                  </MenuItem>
                  <MenuItem
                    key={6}
                    value={"IN_ELABORAZIONE"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"IN_ELABORAZIONE"}
                  </MenuItem>
                  <MenuItem
                    key={7}
                    value={"ANNULLATO"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"ANNULLATO"}
                  </MenuItem>
                </Select>
                <Button
                  style={{ fontSize: "15px", margin: "0px 30px 0px 30px" }}
                  color="success"
                  onClick={() => {
                    this.extractAllCustomersListFromBackend();
                  }}
                  onK
                  round
                >
                  Cerca&nbsp;&nbsp;&nbsp;
                  <SearchIcon style={{ fontSize: "28px" }} />
                </Button>
              </GridItem>
            </FormControl>

            <GridItem xs={12} sm={12} md={12}>
              <FilterableMultiCustomersManagement
                allItemsList={this.state.customersList}
                headersList={headersList}
                isEditModeUsable={true}
                fieldsList={fieldsList}
              />
            </GridItem>
          </GridContainer>
        </form>
      </div>
    );
    }

    deniedOperation  (primaryMsg, secondaryMsg)  {
        this.setState({
            alert:
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "140px" }}
                    title={primaryMsg}
                    customButtons={
                        <React.Fragment>
                            <div style={{ margin: 'auto', textAlign: "center" }}>
                                <Button
                                    style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                                    color="success"
                                    onClick={() => { this.hideAlert() }}
                                    round>
                                    <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                    </Button>
                            </div>
                        </React.Fragment>
                    }
                >
                    {secondaryMsg}<br />
                </SweetAlert>
        });
        setTimeout(this.hideAlert, AUTO_CLOSE_TIME_DENIED_OPERATION, false);
    }


    extractAllCustomersListFromBackend() {
        //let querySearch=this.state.name;
        //let appName = AuthService.APPLICATION_NAME;
        //let headerWithAuth = AuthService.getAuthHeaderAsObj();
        // fetch(AuthService.BACKEND_API_TEE_DISCOVERED_SUBJECTS, {
        //     method: 'POST',
        //     headers: headerWithAuth,
        //     body: JSON.stringify({
        //         applicationName: appName,
        //         querySearch: querySearch
        //     })
        // })
        let query = this.state.name ? '&querySearch=' + this.state.name : '';
        query=this.state.currentSelectedElaborationState ?query+'&elaborationState='+this.state.currentSelectedElaborationState:query;
        axiosApiCaller.get(AuthService.getBackendApiTeeRequestResult()+'?idRequest=' +this.props.location.state.idRequest + query)
            .then((res) => {
                if (res.data.success === true) {
                    localStorage.setItem("idRichiesta",this.props.location.state.idRequest);
                    console.log('POSITION LIST SUCCESSFULLY........');
                    //console.log(JSON.stringify(res.data.payload));
                    // console.log('...........................');
                    // console.log(res);
                    let customers = this.cleanData(res.data.payload);
                    this.setState({ customersList: customers });
                    this.resetFilters();
                    this.setState({ currentMinutesRefreshDelay: 0 });
                    this.lastRefreshTime = new Date();
                    setInterval(() => {
                        this.setState({ currentMinutesRefreshDelay: Math.floor((((new Date()) - this.lastRefreshTime) / 1000) / 60) });
                    }, 30001);
                    this.hideAlert();
                } else {
                    console.log('FLAG ERROR ON RESPONSE: ' + res.data.message);
                    this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.');
                }
            })
            .catch((error) => {
                console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
                console.log(JSON.stringify(error.message));
                this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
            });
    }


    /*
    extractAllCustomersListFromBackend() {
        let querySearch=this.state.name;
        let appName = AuthService.APPLICATION_NAME;
        //let headerWithAuth = AuthService.getAuthHeaderAsObj();
        // fetch(AuthService.BACKEND_API_TEE_DISCOVERED_SUBJECTS, {
        //     method: 'POST',
        //     headers: headerWithAuth,
        //     body: JSON.stringify({
        //         applicationName: appName,
        //         querySearch: querySearch
        //     })
        // })
        axiosApiCaller.post(AuthService.BACKEND_API_TEE_DISCOVERED_SUBJECTS, {
            applicationName: appName,
            querySearch: querySearch
        })
            .then((res) => {
                if (res.data.success === true) {
                    console.log('POSITION LIST SUCCESSFULLY........');
                    //console.log(JSON.stringify(res.data.payload));
                    console.log('...........................');
                    console.log(res);
                    let customers = this.cleanData(res.data.payload);
                    this.setState({ customersList: customers });
                    this.resetFilters();
                    this.setState({ currentMinutesRefreshDelay: 0 });
                    this.lastRefreshTime = new Date();
                    setInterval(() => {
                        this.setState({ currentMinutesRefreshDelay: Math.floor((((new Date()) - this.lastRefreshTime) / 1000) / 60) });
                    }, 30001);
                    this.hideAlert();
                } else {
                    console.log('FLAG ERROR ON RESPONSE: ' + res.data.message);
                    this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.');
                }
            })
            .catch((error) => {
                console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
                console.log(JSON.stringify(error.message));
                this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
            });
    }

    */

    // CLEAN DATA FROM NULL VALUE
    cleanData(rawData) {
        let cleanedData = [];
        if (!rawData || (rawData == null)) {
            return cleanedData;
        }
        rawData.forEach(rawElement => {
            if (!rawElement.ragioneSociale || (rawElement.ragioneSociale == null)) {
                rawElement.ragioneSociale = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
            }
            if (!rawElement.indirizzo || (rawElement.indirizzo == null)) {
                rawElement.indirizzo = "";
            }
            if (!rawElement.localita || (rawElement.localita == null)) {
                rawElement.localita = "";
            }
            if (!rawElement.cap || (rawElement.cap == null)) {
                rawElement.cap = "";
            }
            if (!rawElement.paeseProvincia || (rawElement.paeseProvincia == null)) {
                rawElement.paeseProvincia = "";
            }
            if (!rawElement.partitaIva || (rawElement.partitaIva == null)) {
                rawElement.partitaIva = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
            }
            if (!rawElement.elaborationState || (rawElement.elaborationState == null)) {
                rawElement.elaborationState = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
            }
            if (!rawElement.elaborationMessage || (rawElement.elaborationMessage == null)) {
                rawElement.elaborationMessage = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
            }
            if (!rawElement.dataAggiornamento || (rawElement.dataAggiornamento == null)) {
                rawElement.dataAggiornamento = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                rawElement.aggiornato='No';
            }else{
                var d1 = Date.parse(rawElement.dataAggiornamento);
                if(AuthService.getLoggedUserPreviousSession()!=null && AuthService.getLoggedUserPreviousSession().startTime!=null){
                    var d2 = Date.parse(AuthService.getLoggedUserPreviousSession().startTime);
                    rawElement.aggiornato=d1 > d2 ? 'Si' : 'No';
                }else{
                    rawElement.aggiornato='Si';
                }
                rawElement.dataAggiornamento = this.formatSessionDateTime(rawElement.dataAggiornamento);
            }
            if (!rawElement.monitoraggio || (rawElement.monitoraggio == null)) {
                rawElement.monitoraggio = "No";
            }

            if (!rawElement.foreignActualOwners || (rawElement.foreignActualOwners == null)) {
                rawElement.foreignActualOwnersCount = 0;
            } else {
                rawElement.foreignActualOwnersCount = rawElement.foreignActualOwners.length;
                rawElement.foreignActualOwners.forEach(ownerElement => {
                    if (!ownerElement.name || (ownerElement.name == null)) {
                        ownerElement.name = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.bnfcOwnerType || (ownerElement.bnfcOwnerType == null)) {
                        ownerElement.bnfcOwnerType = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.bnfcOwnerLegalForm || (ownerElement.bnfcOwnerLegalForm == null)) {
                        ownerElement.bnfcOwnerLegalForm = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.bnfcTown || (ownerElement.bnfcTown == null)) {
                        ownerElement.bnfcTown = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.countryOfResidence || (ownerElement.countryOfResidence == null)) {
                        ownerElement.countryOfResidence = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.nationality || (ownerElement.nationality == null)) {
                        ownerElement.nationality = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.bnfcDateBirth || (ownerElement.bnfcDateBirth == null)) {
                        ownerElement.bnfcDateBirth = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.bnfcPostalCode || (ownerElement.bnfcPostalCode == null)) {
                        ownerElement.bnfcPostalCode = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.appointedNotifiedOn || (ownerElement.appointedNotifiedOn == null)) {
                        ownerElement.appointedNotifiedOn = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.documentazione || (ownerElement.documentazione == null)) {
                        ownerElement.documentazione = NULL_BOOLEAN_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.note || (ownerElement.note == null)) {
                        ownerElement.note = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.role || (ownerElement.role == null)) {
                        ownerElement.role = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.dateOfBirth || (ownerElement.dateOfBirth == null)) {
                        ownerElement.dateOfBirth = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
                    }
                    if (!ownerElement.appointedNotifiedOn || (ownerElement.appointedNotifiedOn == null)) {
                        ownerElement.appointedNotifiedOn = "";
                    }
                    if (!ownerElement.nationality || (ownerElement.nationality == null)) {
                        ownerElement.nationality = "";
                    }
                    if (!ownerElement.countryOfResidence || (ownerElement.countryOfResidence == null)) {
                        ownerElement.countryOfResidence = "";
                    }
                    if (!ownerElement.societaCollegate || (ownerElement.societaCollegate == null)) {
                        ownerElement.societaCollegate = "";
                    }
                    if (!ownerElement.note || (ownerElement.note == null)) {
                        ownerElement.note = "";
                    }
                });
            }
            cleanedData.push(rawElement);
        });
        return cleanedData;
    }

formatSessionDateTime(dateTime) {
  //console.log(JSON.stringify(dateTime));
  var date = new Date(dateTime);
  return (new Intl.DateTimeFormat("it", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }).format(date));
}
}

export default withStyles(styles)(RequestResult);
