import React from "react";
import PropTypes from 'prop-types';


import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select/Select";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SweetAlert from "react-bootstrap-sweetalert";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import Save from "@material-ui/icons/Save";
import UploadFile from "@material-ui/icons/InsertDriveFile";
import Warning from "components/Typography/Warning.js";
import WarningIcon from "@material-ui/icons/Warning";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import SwapVerticalCircle from "@material-ui/icons/SwapVerticalCircle";
import Flag from "@material-ui/icons/Flag";
import Score from "@material-ui/icons/Score";
import Description from "@material-ui/icons/Description";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Swal from 'sweetalert2'
/* IMPORT DELLE CONSTANTI PER LE TIPOLOGIE DI RICERCA */
import * as Dashboard from 'views/TEE/Dashboard';
import Base64 from  'base-64'

import axios from "axios"

import * as AuthService from 'services/AuthService.js';

import {axiosApiCaller} from 'layouts/AxiosService';

//utilizzo <Link> per la redirect alla dashboard
import {Link} from 'react-router-dom';

import {ExcelFileDownloader} from './ExcelFileDownloader';

const classes = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
   
  },
}));

const localStyles = {
  icon: {
    verticalAlign: "middle",
    textAlign: "center",
    width: "21px",
    height: "21px",
    top: "0px",
    left: "2px",
    position: "relative"
  },
  selectIcon: {
    verticalAlign: "middle",
    textAlign: "center",
    width: "21px",
    height: "21px",
    top: "0px",
    marginLeft: "1px",
    marginRight: "6px",
    position: "relative"
  },  
  actionButton: {
    margin: "5px 5px 5px 5px",
    padding: "0px",
    width: "30px",
    height: "30px",
    minWidth: "auto",
    fontSize: "20px"
  },
  dialogConfirmButton: {
    margin: "0 0 0 5px",
    padding: "20px",
    width: "80px",
    height: "auto",
    minWidth: "auto"
  },
  dialogCancelButton: {
    margin: "0 0 0 5px",
    padding: "7px",
    width: "auto",
    height: "auto",
    minWidth: "auto"
  }
}

const styles = {
  ...localStyles,
  ...extendedTablesStyle,
  ...customSelectStyle
}

// function that returns true if fiscalCode is valid, false otherwise
const verifyFiscalCode = value => {
  var fiscalCodeRex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i;
  var pivaRex=/^[0-9]{11}$/;
  if (fiscalCodeRex.test(value)) {
    return true;
  }
  if (pivaRex.test(value)) {
    return true;
  }
  return false;
};



const AUTO_CLOSE_TIME_DENIED_OPERATION = 2400;
const AUTO_CLOSE_TIME_SUCCESS_OPERATION = 3000;
const CHANGE_VIEW_TIME = 1000;

class NewScoringSearch extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.initialState = this.getInitialState();
    this.hideAlert = this.hideAlert.bind(this);
    this.showSuccessOperation = this.showSuccessOperation.bind(this);
    this.deniedOperation = this.deniedOperation.bind(this);
    // this.showRedirectAlert = this.showRedirectAlert.bind(this);
  }

  
getInitialState() {
    return {
      codiceFiscale: '',
      codiceFiscaleValid: true,
      errorMessage: null,
      alert: null,
      idDifferita: '',
    };
  }
	
resetForm() {
    document.getElementById("create-search-form").reset();
    this.setState(this.initialState);
 }


componentDidMount() {
}

componentWillUnmount() {
}

componentDidUpdate() {
}


handleChangeCodiceFiscale(event) {
  let newValue = event.target.value.trim();
  this.setState({ codiceFiscale: newValue });
  if (newValue!= null) {
    if (newValue.length<11) {
      this.setState({ codiceFiscaleValid: false });
      this.setState({ errorMessage: 'La lunghezza del codice fiscale non è valida' });
    } else {
      this.setState({ codiceFiscaleValid: true });
      this.setState({ errorMessage: null });
    }
  } else {
    this.setState({ codiceFiscaleValid: false });
    this.setState({ errorMessage: 'Il codice fiscale non può essere vuoto' });
  }
}

handleChangeIdDifferita(event) {
  let newValue = event.target.value.trim();
  this.setState({ idDifferita: newValue });
}
render() {
    const { classes } = this.props;
    let topMessage = null;
    let msg = '';
    if (this.state.errorMessage !== null) {
      msg = (<Warning variant="h4"><WarningIcon/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.errorMessage}</Warning>);
      topMessage = (
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} style={{textAlign: "center"}}>
            {msg}
          </GridItem>
        </GridContainer> 
      )
    } else topMessage = (<br/>);
    return (
      <div style={{marginLeft: "23px"}}>
       {this.state.alert}
        {topMessage}
        <form id="create-search-form">
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <FormControl fullWidth>
                <CustomInput
                  success={this.state.codiceFiscaleValid == true}
                  error={this.state.codiceFiscaleValid == false}
                  labelText="Codice fiscale *"
                  inputProps={{
                    value: this.state.codiceFiscale,
                    disabled: (this.props.readOnly),
                    type: "text",
                    onChange: event => {this.handleChangeCodiceFiscale(event)}
                  }}
                />
               </FormControl>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <FormControl fullWidth>
                <CustomInput
                  labelText="Id Differita *"
                  inputProps={{
                    value: this.state.idDifferita,
                    disabled: (this.props.readOnly),
                    type: "text",
                    onChange: event => {this.handleChangeIdDifferita(event)}
                  }}
                />
               </FormControl>
            </GridItem>
          </GridContainer>
          <br/>
          <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div style={{margin: '0px 0px 30px 0px', textAlign: "center"}}>        
                <Button 
                    style={{fontSize: '15px', margin: '0px 30px 0px 0px'}}                   
                    color="warning"
                    onClick={() => { this.resetForm() }}
                    round>
                      Annulla&nbsp;&nbsp;&nbsp;<Cancel style={{fontSize: '28px'}} />
                  </Button>                     
                  {(this.props.readOnly) ? (null) : (
                    <> 
                      <Button 
                        style={{fontSize: '15px', margin: '0px 30px 0px 0px'}}                   
                        color="success"
                        onClick={() => { this.manageNewSearch() }}
                        round>
                          Conferma Richiesta&nbsp;&nbsp;&nbsp;<Save style={{fontSize: '28px'}} />
                      </Button>
                    </>
                    )
                  }
                </div>
              </GridItem>
            </GridContainer>       
        </form>
      </div>
    )
  }




  checkFieldValidity(fireMessageBox) {
    let msg = '';
    if (msg !== '') {
      if (fireMessageBox === true) {
        this.deniedOperation('Impossibile completare la richiesta', msg);
      }      
      return false;
    } else {
      return true;
    }    
  }

  deniedOperation (primaryMsg, secondaryMsg) {
    this.setState({alert: 
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "140px" }}
        title={primaryMsg}
        customButtons={
          <React.Fragment>
              <div style={{margin: 'auto', textAlign: "center"}}>                
                <Button 
                  style={{fontSize: '13px', margin: '0px 0px 0px 0px'}}                   
                  color="error"
                  onClick={() => { this.hideAlert() }}
                  round>
                    <CheckCircle style={{fontSize: '28px'}} />&nbsp;Ok
                </Button>                                            
              </div>
          </React.Fragment>
        }
      >
        {secondaryMsg}<br/>
      </SweetAlert>  
    });
    setTimeout(this.hideAlert, AUTO_CLOSE_TIME_DENIED_OPERATION, false); 
  }

  showSuccessOperation (primaryMsg, secondaryMsg) {
    this.setState({alert: 
      <SweetAlert
        success
        style={{ display: "block", marginTop: "140px" }}
        title={primaryMsg}
        customButtons={
          <React.Fragment>
              <div style={{margin: 'auto', textAlign: "center"}}>
                <Button
                  style={{fontSize: '13px', margin: '0px 0px 0px 0px'}}
                  color="danger"
                  onClick={() => { this.hideAlert() }}
                  round>
                    <Cancel style={{fontSize: '28px'}} />&nbsp;Annulla
                </Button>
                <Button 
                  style={{fontSize: '13px', margin: '0px 0px 0px 10px'}}
                  color="success"
                  //onClick={() => { this.hideAlert() }}
                  onClick={() => { this.collectInfoAndSend() }}
                  round>
                    <CheckCircle style={{fontSize: '28px'}} />&nbsp;Ok
                </Button>                                            
              </div>
          </React.Fragment>
        }
      >
        {secondaryMsg}<br/>
      </SweetAlert>  
    });
  }
  
  manageNewSearch() {
    if (!this.checkFieldValidity(true)) {
      return;
    }
    this.setState({ 
      errorMessage: null,
      alert: null 
    });

this.showSuccessOperation("Conferma Richiesta","Confermando la richiesta la nuova posizione verrà addebbitata al prezzo e con le modalità previste dal contratto in essere legato alla sua utenza.");

  }


  hideAlert (redirectToListView) {
    this.setState({alert: null}); 
  }

  collectInfoAndSend(){
    //prendo i dati inseriti dall utente, dallo state
    let codiceFiscale = this.state.codiceFiscale;
    let idDifferita = this.state.idDifferita;

    //creo l'oggetto contenitore con i dati inseriti dell'utente
    let infoContainer = this.getNewRequestContainer(codiceFiscale,idDifferita);
                                                  
    //ora devo mandare la postRequest
    this.sendRequest(infoContainer);
  }

  getNewRequestContainer(codiceFiscale,idDifferita){

    //funzione constructor: crea un oggetto di tipo TeeSingleRequest
    function ReportingSystemInputEmail(codiceFiscale,idDifferita){
      this.codiceFiscale = codiceFiscale;
      this.idDifferita = idDifferita;
    }

    let contenitoreDatiNuovaRichiesta = new ReportingSystemInputEmail(codiceFiscale,idDifferita);

    return contenitoreDatiNuovaRichiesta;
  }

  sendRequest(infoContainer){

    axiosApiCaller.post(AuthService.getBackendApiReportingSystemNewRequest(), infoContainer)
    .then((res) => {
        if (res.data.success === true) {
            console.log('il campo success è true '+ res.data.message);
            let title = 'Richiesta inserita correttamente ';
            let message ='Stai per essere rediretto allo storico richieste.. '
            this.showRedirectAlert(title, message);
        } else {
            console.log('il campo success è false '+ res.data.message);
            
        }
    })
    .catch((error) => {
      console.log('Clausola catch')  
      console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
        console.log(JSON.stringify(error.message));
        
    });

  }

  
  showRedirectAlert(primaryMsg, secondaryMsg){
    this.setState({alert: 
      <SweetAlert
        success
        style={{ display: "block", marginTop: "140px" }}
        title={primaryMsg}
        customButtons={
          <React.Fragment>
              <div style={{margin: 'auto', textAlign: "center"}}>
                <Link to='/consolle/storico-richieste'>
                <Button 
                  style={{fontSize: '13px', margin: '0px 0px 0px 10px'}}
                  color="success"
                  onClick={() => { this.hideAlert() }}
                  round>
                    <CheckCircle style={{fontSize: '28px'}} />&nbsp;Ok
                </Button>
                </Link>
              </div>
          </React.Fragment>
        }
      >
        {secondaryMsg}<br/>
      </SweetAlert>  
    });
  }

  // confirmUpload =(dataJson) =>{
  //   let headerWithAuth = AuthService.getAuthHeaderAsObj();
  //   //headerWithAuth["Content-Type"] = "multipart/form-data";
  //   // headerWithAuth["Accept"] = "*/*";
  //   //delete headerWithAuth["Content-Type"];
  //   //headerWithAuth["Content-Type"] = "undefined";
  //   console.log(headerWithAuth);
  //   //let formData = new FormData();
  //   console.log(dataJson);
  //   //formData.append("file", data);
  //   return  fetch(AuthService.BACKEND_API_TEE_NEW_FILE_REQUEST, {
  //     method: 'POST',
  //     headers: headerWithAuth,
      
  //     body: JSON.stringify({
  //       applicationName: AuthService.APPLICATION_NAME,
  //       user:AuthService.getLoggedUserObject()["fullName"],
  //       //data: JSON.stringify(dataJson)
  //       data: dataJson
  //       }) 
  //     })

  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Errore connessione al server');
  //       }
  //       return response.json()
  //     })
  //     .catch(error => {
  //       //Swal.showValidationMessage(
  //       //  'Request failed: ${error}'
  //      // )
  //      Swal.fire({
  //       icon: 'error',
  //       title: 'Oops...',
  //       text: 'Si sono verificati degli errori durante l\'elaborazione'
  //     });
  //     });
  // }
  
    
}

  


NewScoringSearch.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  createOrModify: PropTypes.bool.isRequired
};

export default withStyles(styles)(NewScoringSearch);