import React from "react";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Timeline from "components/Timeline/Timeline.js";
import DetailVisualizer from "components/COMMON/DetailVisualizer.js";
import SingleColumnDetailVisualizer from "components/COMMON/SingleColumnDetailVisualizer.js";
import CardTravel from "@material-ui/icons/CardTravel";
import Extension from "@material-ui/icons/Extension";
import Anagraphical from "@material-ui/icons/Fingerprint";
import Family from "@material-ui/icons/People";
import Process from "@material-ui/icons/Settings";
import Work from "@material-ui/icons/Work";
import Finance from "@material-ui/icons/AccountBalance";
import Guarantee from "@material-ui/icons/ShoppingBasket";
import Warn from "@material-ui/icons/Warning";
import * as CommonFunctions from 'services/CommonFunctions.js';
import Economics from "@material-ui/icons/Shop";
import BusinessBasicData from "@material-ui/icons/BusinessCenter";
import Accordion from "components/Accordion/TitolareEffettivoAccordion.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Warning from "../Typography/Warning";
import SmallMenu from "views/COMMON/SmallMenu";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import Select from "@material-ui/core/Select/Select";
import MenuItem from '@material-ui/core/MenuItem';

import * as AuthService from 'services/AuthService.js';

import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const localStyles = {
    allCentered: {
        margin: '0',
        position: 'absolute',
        top: '50%',
        left: '50%',
        msTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
    },
    allCenteredMessage: {
        textAlign: 'center',
        height: '30px',
        lineHeigth: '30px',
        padding: '0px'
    },
    completedRound: {
        color: "#34a853"
    },
    warningRound: {
        color: "#fba706"
    },
    errorRound: {
        color: "#ea4335"
    },
    partialRound: {
        background: "grey",
        paddingTop: "12px",
        paddingBottom: "12px"
    }
}

const styles = {
    ...localStyles,
    ...dashboardStyles
}

class CompanyReportVisualizer extends React.Component {

    constructor(props) {
        super(props);
        this.cleanedData = this.cleanData(this.props.itemForDetail);
        this.state = this.getInitialState();
        this.handleChangeItem = this.handleChangeItem.bind(this);
        this.handleChangeForeignOwnersInDeleteCaseFromChild = this.handleChangeForeignOwnersInDeleteCaseFromChild.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
    }

    getInitialState() {
        return {
            errorMessage: this.props.errorMessage,
            imageIndex: -1,
            editMode: false,
            documentazione: this.cleanedData.documentazione
            //foreignOwnersList: this.cleanedData.foreignActualOwners
        };
    }

    handleChangeForeignOwnersInDeleteCaseFromChild(newList) {
        this.cleanedData.foreignActualOwners = newList;
    }

    getItemForDetail() {
        return this.props.itemForDetail;
    }

    cleanData() {
        //console.log('%%%%%%%%%%%%%%%%%%% \n' + JSON.stringify(this.props.itemForDetail));
        let theData = this.props.itemForDetail;
        /*theData.ragioneSociale = CommonFunctions.formatTextualValueAvailability(this.props.itemForDetail.ragioneSociale);
        theData.idSoggetto = CommonFunctions.formatTextualValueAvailability(this.props.itemForDetail.idSoggetto);
        theData.partitaIva = CommonFunctions.formatTextualValueAvailability(this.props.itemForDetail.partitaIva);
        theData.indirizzo = this.props.itemForDetail.indirizzo;
        theData.localita = this.props.itemForDetail.localita;
        theData.cap = this.props.itemForDetail.cap;
        theData.paeseProvincia = CommonFunctions.formatTextualValueAvailability(this.props.itemForDetail.paeseProvincia);
        theData.monitoraggio = CommonFunctions.formatTextualValueAvailability(this.props.itemForDetail.monitoraggio);
        theData.dataAggiornamento = CommonFunctions.formatDateTimeAsDateAvailability(this.props.itemForDetail.dataAggiornamento);
        theData.foreignActualOwners = this.props.itemForDetail.foreignActualOwners;*/
        return theData;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate() {
    }

    handleChangeItem(event) {
        if (event.target.id == "partitaIva") {
            this.cleanedData.partitaIva = event.target.value;
        } else if (event.target.id == "indirizzo") {
            this.cleanedData.indirizzo = event.target.value;
        }
        else if (event.target.id == "localita") {
            this.cleanedData.localita = event.target.value;
        }
        else if (event.target.id == "cap") {
            this.cleanedData.cap = event.target.value;
        }
        else if (event.target.id == "provincia") {
            this.cleanedData.provincia = event.target.value;
        }
        else if (event.target.id == "note") {
            this.cleanedData.note = event.target.value;
        }
        else if (event.target.id == "documentazione") {
            this.cleanedData.documentazione = event.target.checked;
            this.setState({ documentazione: event.target.checked }); //Cambiamento di stato
        }
    }

    formatSessionDateTime(dateTime) {
        //console.log(JSON.stringify(dateTime));

        var date = new Date(dateTime);
        if (date instanceof Date && isFinite(date)) {
            return (new Intl.DateTimeFormat("it", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            }).format(date));
        } else {
            return dateTime;
        }
    }

    handleChangeState(event) {
        console.log(event.target.value);
        this.cleanedData.elaborationState = event.target.value;
        this.setState({ editMode: this.state.editMode }); //Cambiamento fittizio
    }

    // componentDidMount() {
    //     ReactDOM.findDOMNode(this).scrollIntoView();
    // }

    render() {
        const { classes } = this.props;
        this.cleanedData = this.cleanData(this.props.itemForDetail);
        let monitoraggioInfo = null;
        if (this.cleanedData.monitoraggioFlag === true) {
            monitoraggioInfo = (<h4 style={{ margin: '0px 0px 15px 0px' }} className={classes.activatedCardTitle} ><b>Monitoraggio Attivo</b></h4>);
        } else {
            monitoraggioInfo = (<h4 style={{ margin: '0px 0px 15px 0px' }} className={classes.deactivatedCardTitle} ><b>Monitoraggio Non Attivo</b></h4>);
        }

        let elaborationState = "";
        if (this.cleanedData.elaborationState == "COMPLETATO") {
            elaborationState = <div>
                <div style={localStyles.completedRound}>
                    <b>  {this.cleanedData.elaborationState}</b>
                </div>
            </div>
        } else
            if (this.cleanedData.elaborationState == "ATTENZIONE") {
                elaborationState = <div>
                    <div style={localStyles.warningRound}>
                        <b> {this.cleanedData.elaborationState}</b>
                    </div>
                </div>
            } else
                if (this.cleanedData.elaborationState == "ERRORE") {
                    elaborationState = <div><div style={localStyles.errorRound}><b>{this.cleanedData.elaborationState}</b></div></div>
                } else
                    if (this.cleanedData.elaborationState == "IN_ELABORAZIONE") {
                        elaborationState = <div><div style={localStyles.partialRound}><b style={{ color: "white" }}>{this.cleanedData.elaborationState}</b></div></div>
                    } else {
                        elaborationState = this.cleanedData.elaborationState;
                    }


        let labelMarginLeft = "";
        let labelBackgroundColor = "";
        let labelBorder = "";
        console.log("--------------------this.cleanedData.elaborationState----------------------")
        console.log(this.cleanedData.elaborationState)
        switch (this.cleanedData.elaborationState) {
            case "COMPLETATO":
                labelMarginLeft = "86%";
                labelBackgroundColor = "rgb(52, 168, 83)";
                labelBorder = "1px solid rgb(34, 112, 55)";
                break;
            case "ATTENZIONE":
                labelMarginLeft = "88%";
                labelBackgroundColor = "rgb(255, 152, 0)";
                labelBorder = "1px solid rgb(170, 101, 0)";
                break;
            case "ERRORE":
                labelMarginLeft = "90%";
                labelBackgroundColor = "rgb(215, 2, 6)";
                labelBorder = "1px solid rgb(143, 1, 4)";
                break;
            case "IN_ELABORAZIONE":
                labelMarginLeft = "81%";
                labelBackgroundColor = "rgb(178, 178, 178)";
                labelBorder = "1px solid rgb(118, 118, 118)";
                break;
            case "CARICAMENTO":
                labelMarginLeft = "84%";
                labelBackgroundColor = "rgb(23, 105, 255)";
                labelBorder = "1px solid rgb(15, 70, 170)";
                break;
            case "SOSPESO":
                labelMarginLeft = "88%";
                labelBackgroundColor = "rgb(103, 17, 141)";
                labelBorder = "1px solid rgb(68, 11, 94)";
                break;
            case "ANNULLATO":
                labelMarginLeft = "88%";
                labelBackgroundColor = "rgb(5, 23, 56)";
                labelBorder = "1px solid rgb(3, 15, 37)";
                break;
        }

        let labelStyle = {
            display: "inline-block",
            display: "inline-block",
            marginLeft: labelMarginLeft,
            padding: "6px",
            padding: "6px",
            background: labelBackgroundColor,
            border: labelBorder,
            borderRadius: "4px"
        };

        console.log("---------------labelStyle------------------")
        console.log(labelStyle)
        const rightDetailsColumn = [
            {
                // Anagraphical data section
                inverted: false,
                badgeColor: "success",
                badgeIcon: this.props.mainIcon,
                title: "Dati Anagrafici",
                titleColor: "success",
                body: (
                    <div>
                        <h6 style={labelStyle} className={classes.cardTitle}>
                            <b style={{ color: "white" }}>  {this.cleanedData.elaborationState}</b>
                        </h6>
                        <div>
                            <h3 style={{ margin: '0px 0px 5px 0px', display: 'inline-block' }} className={classes.cardTitle}>
                                <b><span>{this.cleanedData.ragioneSociale}</span></b>
                            </h3>
                            {/*<span style={{ position: 'relative', bottom: '4px' }}><SmallMenu ragioneSociale={this.cleanedData.ragioneSociale} /></span> */}
                        </div>
                        {(() => {
                            if (!this.props.editMode) {
                                return <div>
                                    <h6 className={classes.cardTitle}>
                                        <b>Partita iva</b>: {this.cleanedData.partitaIva}
                                    </h6>
                                    <h6 className={classes.cardTitle}>
                                        <b>Sede</b>: {this.cleanedData.indirizzo + ', ' + this.cleanedData.localita + ', ' + this.cleanedData.cap + ', ' + this.cleanedData.paeseProvincia}
                                    </h6>
                                    <h6 className={classes.cardTitle}>
                                        <b>Note</b>: {this.cleanedData.note}
                                    </h6>
                                    <h6 className={classes.cardTitle}>
                                        <b>Documentazione</b>: {this.cleanedData.documentazione ? (this.cleanedData.documentazione == true ? "Sì" : "No") : "No"}
                                    </h6>
                                </div>
                            } else {
                                return <div>
                                    <div>
                                        <TextField style={{ width: "80%", float: "left", margin: '2%' }} id="partitaIva" label="Partita IVA" defaultValue={this.cleanedData.partitaIva} onChange={this.handleChangeItem} />
                                    </div> <br></br>
                                    <div>
                                        <TextField style={{ width: "80%", float: "left", margin: '2%' }} id="indirizzo" label="Indirizzo" defaultValue={this.cleanedData.indirizzo} onChange={this.handleChangeItem} />
                                    </div>
                                    <br></br>
                                    <div className={classes.cardTitle}>
                                        <TextField style={{ width: "80%", float: "left", margin: '2%' }} id={"localita"} label="Località" defaultValue={this.cleanedData.localita} onChange={this.handleChangeItem} />
                                    </div>
                                    <br></br>
                                    <div>
                                        <TextField style={{ width: "80%", float: "left", margin: '2%' }} id="cap" label="CAP" defaultValue={this.cleanedData.cap} onChange={this.handleChangeItem} />
                                    </div>
                                    <br></br>
                                    <div >
                                        <TextField style={{ width: "80%", float: "left", margin: '2%' }} id="provincia" label="Provincia o Nazione" defaultValue={this.cleanedData.paeseProvincia} onChange={this.handleChangeItem} />
                                    </div><br></br>
                                    <div>
                                        <TextField
                                            style={{ width: "80%", float: "left", margin: '2%' }}
                                            id="note"
                                            label="Note"
                                            multiline
                                            defaultValue={this.cleanedData.note}
                                            rows={4}
                                            onChange={this.handleChangeItem}
                                            variant="outlined"
                                        />
                                    </div>                                    
                                    <br></br>
                                    <div style={{ margin: '2%' }}><GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Elaboration state</InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        className: classes.selectMenu
                                                    }}
                                                    classes={{
                                                        select: classes.select
                                                    }}
                                                    displayEmpty
                                                    value={this.cleanedData.elaborationState}
                                                    onChange={this.handleChangeState}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                >
                                                    <MenuItem key={1} value={"ATTENZIONE"}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                    >
                                                        {'ATTENZIONE'}
                                                    </MenuItem>
                                                    <MenuItem key={2} value={"ERRORE"}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                    >
                                                        {'ERRORE'}
                                                    </MenuItem>
                                                    <MenuItem key={3} value={"IN_ELABORAZIONE"}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                    >
                                                        {'IN_ELABORAZIONE'}
                                                    </MenuItem>
                                                    <MenuItem key={4} value={"COMPLETATO"}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                    >
                                                        {'COMPLETATO'}
                                                    </MenuItem>
                                                    <MenuItem key={5} value={"CARICAMENTO"}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                    >
                                                        {'CARICAMENTO'}
                                                    </MenuItem>
                                                   
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer> </div>
                                    <br></br>
                                    <FormControlLabel style={{ float: "left", margin: '2%' }}                                        
                                        checked= {this.cleanedData.documentazione}
                                        control={<Checkbox id= "documentazione" color="primary" onChange={this.handleChangeItem} />}
                                        label="Documentazione"
                                        labelPlacement="start"
                                    />
                                </div>;
                            }
                        })()}
                    </div>
                )
            }
        ];

        const leftDetailsColumn = [
            {
                inverted: false,
                badgeColor: "danger",
                badgeIcon: Process,
                title: "Dati di Processo",
                titleColor: "warning",
                body: (
                    <div>
                        {monitoraggioInfo}
                        <h6 className={classes.cardTitle}>
                            <b>Monitoraggio</b>: {this.cleanedData.monitoraggio}
                        </h6>
                        <h6 className={classes.cardTitle}>
                            <b>Data di aggiornamento</b>: {this.formatSessionDateTime(this.cleanedData.dataAggiornamento)}
                        </h6>
                    </div>
                ),
            }
        ];

        const ownersDetailsColumn = [
            {
                inverted: false,
                badgeColor: "warning",
                badgeIcon: Family,
                title: "Soci e Titolari",
                titleColor: "danger",
                body: (
                    <Card>
                        <CardHeader>
                            <h3 className={classes.cardTitle}><b>Titolari effettivi esteri</b></h3>
                        </CardHeader>
                        <CardBody>
                            <Accordion
                                active={0}
                                editMode={this.props.editMode}
                                collapses={this.cleanedData.foreignActualOwners}
                                onChange={this.handleChangeForeignOwnersInDeleteCaseFromChild}
                            />
                        </CardBody>
                    </Card>
                )
            }
        ]



        let maybeErrorMsg = null;
        if (this.props.errorMessage !== null) {
            maybeErrorMsg = (<div className={classes.allCenteredMessage} >
                <Warning><Warn style={{ verticalAlign: "middle" }} />&nbsp;&nbsp;ATTENZIONE: {this.props.errorMessage} I dati mostrati sono quelli disponibili nella sezione generale.</Warning>
            </div>);
        }
        return (
            <div style={{ textAlign: "left" }}>
                {maybeErrorMsg}
                <GridContainer style={{ textAlign: "center" }}>
                    <GridItem xs={12} sm={12} md={12}>
                        <DetailVisualizer simple leftDetails={leftDetailsColumn} rightDetails={rightDetailsColumn} showIcons={true} showTitles={true} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <SingleColumnDetailVisualizer simple details={ownersDetailsColumn} showIcons={true} showTitles={true} />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

CompanyReportVisualizer.propTypes = {
    itemForDetail: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    mainIcon: PropTypes.object.isRequired
}

export default withStyles(styles)(CompanyReportVisualizer);
