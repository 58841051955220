import React from "react";

// react component plugin for creating a beautiful datetime dropdown picker
import 'react-nice-dates/build/style.css';

// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';

// core components
import PropTypes from 'prop-types';
import * as AuthService from 'services/AuthService.js';
import * as CustomersManagementTabs from 'views/COMMON/CustomersManagement.js';
import Cancel from "@material-ui/icons/Cancel";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import * as CascoDashboard from 'views/TEE/Dashboard';


const classes = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));


const CHANGE_VIEW_TIME_GO_TO_LIST = 1200;

class ScoreVisualizer extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }


  getInitialState() {
    return {
      errorMessage: null,
      alert: null,
      descriptionForDetail: (this.props.customTabsRef.current.getItemDescriptionForDynamicTabs()),
      itemForDetail: (this.props.customTabsRef.current.getItemObjectForDynamicTabs()),
      typeForDetail: (this.props.customTabsRef.current.getTypeForDynamicTabs()),
      iconForDetail: (this.props.customTabsRef.current.getIconForDynamicTabs())
    };
  }

  cancelEditing() {
    this.props.customTabsRef.current.setItemDescriptionForDynamicTabs('');
    this.props.customTabsRef.current.setItemObjectForDynamicTabs(null);
    this.props.customTabsRef.current.setTypeForDynamicTabs(null);
    this.props.customTabsRef.current.setIconForDynamicTabs(null);
    this.props.customTabsRef.current.setSelectedTab(CustomersManagementTabs.CUSTOMERS_LIST_TAB_INDEX);
    this.props.customTabsRef.current.hideDynamicTabs();
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let message = '';
    let specificScoreComponent = null;
    switch (this.state.typeForDetail) {
      default:
      // code block
    }
    return (
      <div style={{ marginLeft: "23px" }}>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>        
            {specificScoreComponent}
          </GridItem>
        </GridContainer>        
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <div style={{ margin: 'auto', textAlign: "center" }}>
              <Button
                style={{ fontSize: '15px' }}
                color="warning"
                onClick={() => { this.cancelEditing() }}
                round>
                Chiudi&nbsp;&nbsp;&nbsp;<Cancel style={{ fontSize: '28px' }} />
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

ScoreVisualizer.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  customTabsRef: PropTypes.object.isRequired
};

export default ScoreVisualizer;