import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import jss from 'jss';
//import withStyles from 'react-jss';
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui components
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/components/customTabsStyle.js";

//const usedStyles = jss.createStyleSheet(styles).attach();

class CustomTabs extends React.Component {
//export default function CustomTabs(props) {

  constructor(props) {
    super(props);
    //this.classes = props;
    this.handleChange = this.handleChange.bind(this);
    this.state = this.getInitialState();
    this.numberOfStandardTabs = this.props.tabs.length;
  }

  getInitialState() {
    return {      
      value: (this.props.selectedTabIndex),
      showDynamicTabs: (this.props.showDynamicTabs),
      itemForDynamicTabs: (this.props.itemForDynamicTabs)
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  setSelectedTab(tabIndex) {
    this.setState({value: tabIndex});
  }

  getSelectedTab() {
    return this.state.value;
  }

  showDynamicTabs() {
    this.setState({showDynamicTabs: true});
  }

  hideDynamicTabs() {
    this.setState({showDynamicTabs: false});
  }

  setItemForDynamicTabs(item) {
    this.setState({itemForDynamicTabs: item});
  }

  getItemForDynamicTabs() {
    return this.state.itemForDynamicTabs;
  }

  handleChange (event, value) {
    this.setSelectedTab(value);
  };

  render() {
    const { classes } = this.props;
    let cardTitle = classNames({
      [classes.cardTitle]: true,
      [classes.cardTitleRTL]: this.props.rtlActive
    });    
    return (
      <Card plain={this.props.plainTabs}>
        <CardHeader color={this.props.headerColor} plain={this.props.plainTabs}>
          {this.props.title !== undefined ? <div className={cardTitle}>{this.props.title}</div> : null}
          

          <Tabs
            value={this.state.value}
            onChange={this.handleChange}                       
            classes={{
              root: classes.tabsRoot,
              indicator: classes.displayBlock,
              indicatorColor: "white"            
            }}
          >
            {this.props.tabs.map((prop, key) => {
              var icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />
                };
              }
              return (

               

                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper
                  }}
                  key={key}
                  label={prop.tabName}
                  {...icon}
                />
              );
            })}


            {(this.state.showDynamicTabs)? (
              this.props.dynamicTabs.map((prop, key) => {
              var icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />
                };
              }
              return (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper
                  }}
                  key={key}
                  label={prop.tabName + ':  ' + this.getItemForDynamicTabs()}
                  {...icon}
                />
              );
            })
          ) : (null)}


          </Tabs>
                    
        </CardHeader>
        <CardBody>
          {this.props.tabs.map((prop, key) => {
            if (key === this.state.value) {
              return <div key={key}>{prop.tabContent}</div>;
            }
            return null;
          })}
          {(this.state.showDynamicTabs)? (
            this.props.dynamicTabs.map((prop, key) => {
              if ((this.numberOfStandardTabs + key) === this.state.value) {
                return <div key={key}>{prop.tabContent}</div>;
              }
              return null;
            })
          ) : (null)}
        </CardBody>
      </Card>
    );
  }
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  dynamicTabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
  selectedTabIndex: PropTypes.number,
  showDynamicTabs: PropTypes.bool,
  itemForDynamicTabs: PropTypes.string
};

export default withStyles(styles)(CustomTabs);