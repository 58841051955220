import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TEE_SOCIO_TYPE } from 'services/CommonValues';
import { TEE_EXECUTIVES_TYPE } from 'services/CommonValues';
import { TEE_CEO_TYPE } from 'services/CommonValues';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import TextField from '@material-ui/core/TextField';

// @material-ui/icons
import ExpandMore from "@material-ui/icons/ExpandMore";

import styles from "assets/jss/material-dashboard-pro-react/components/accordionStyle.js";

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(styles);

export default function TitolareEffettivoAccordion(props) {
  const [active, setActive] = React.useState(props.active);
  const [editMode, setEditMode] = React.useState(props.editMode);
  const handleChange = panel => (event, expanded) => {
    setActive(expanded ? panel : -1);
  };  
  const classes = useStyles();
  //let { collapses } = props;
  let [value, setValue] = React.useState(props.collapses);
  function deleteSocio(foreignOwner) {
    let nuovoArray = [];
    value.forEach(element => {
      if(element.completeName !== foreignOwner.completeName){
        nuovoArray.push(element);
      }
    });
    setValue(value = nuovoArray); // update the state to force render
    props.onChange(value);
  };
  function handleChangeItem(event, foreignOwner) {
    if (event.target.id == "completeName") {
      foreignOwner.completeName = event.target.value;
      console.log(foreignOwner)
    } else if (event.target.id == "role") {
      foreignOwner.role = event.target.value;
    }
    else if (event.target.id == "dateOfBirth") {
      foreignOwner.dateOfBirth = event.target.value;
    }
    else if (event.target.id == "appointedNotifiedOn") {
      foreignOwner.appointedNotifiedOn = event.target.value;
    }
    else if (event.target.id == "nationality") {
      foreignOwner.nationality = event.target.value;
    }
    else if (event.target.id == "countryOfResidence") {
      foreignOwner.countryOfResidence = event.target.value;
    }
    else if (event.target.id == "livello") {
      foreignOwner.livello = event.target.value;
    }
    else if (event.target.id == "documentazione") {
      foreignOwner.documentazione = event.target.checked;
      let nuovoArray = [];
      value.forEach(element => {
        if(element.completeName !== foreignOwner.completeName){
          nuovoArray.push(element);
        }
      });
      nuovoArray.push(foreignOwner);
      setValue(value = nuovoArray); // update the state to force render
      props.onChange(value);
    }
    else if (event.target.id == "societaCollegate") {
      foreignOwner.societaCollegate = event.target.value;
    }
    else if (event.target.id == "note") {
      foreignOwner.note = event.target.value;
    }
  
  };
  return (
    <div className={classes.root}>
      {value.map((prop, key) => {
        if(prop){
          return (
            <ExpansionPanel>
              <ExpansionPanelSummary
                classes={{
                  content: classes.expansionPanelSummaryContent,
                  expandIcon: classes.expansionPanelSummaryExpandIcon
                }}
              >
                {(function () {
                  if (!props.editMode) {
                    return <div>
                      <div style={{ float: 'left', minWidth: '300px', textAlign: 'left' }}><b>Name:</b>&nbsp;&nbsp;{prop.completeName}</div>
                      { /* <div style={{float:'left',textAlign:'left'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Role:</b>&nbsp;&nbsp; {prop.role!=null && prop.role!=''?prop.role:'-'}</div> */}
                      <div style={{ float: 'left', textAlign: 'left' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Role:</b>&nbsp;&nbsp; {prop.role != null && prop.role != '' ? prop.role : '-'}</div>
                    </div>
                  } else {
                    return <div>
                      <div style={{ float: 'left', minWidth: '400px', textAlign: 'left' }}><TextField style={{ width: '85%' }} id="completeName" label="Name" defaultValue={prop.completeName} onChange={(event) => { handleChangeItem(event, prop) }} /> </div>
                      <div style={{ float: 'left', minWidth: '400px', textAlign: 'left' }}> <TextField style={{ width: '85%' }} id="role" label="Role" defaultValue={prop.role != null && prop.role != '' ? prop.role : '-'} onChange={(event) => { handleChangeItem(event, prop) }} /> </div>
                        <IconButton color="secondary" style={{ float: 'right' }} aria-label="elimina" onClick={() => { deleteSocio(prop); }}>
                          <DeleteIcon />
                        </IconButton>
                    </div>
                  }
                })()}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                  </TableHead>
                  {(function () {
                    if (!props.editMode) {
                      return <TableBody>
                        <TableRow key={prop.completeName}>
                          <TableCell component="th" scope="row">
                            <b>Data di nascita</b><br /> {prop.dateOfBirth != null && prop.dateOfBirth != '' ? prop.dateOfBirth : '-'}</TableCell>
                          <TableCell component="th" scope="row">
                            <b>Appointed on</b><br /> {prop.appointedNotifiedOn != null && prop.appointedNotifiedOn != '' ? prop.appointedNotifiedOn : '-'}</TableCell>
                          <TableCell component="th" scope="row">
                            <b>Nazionalità</b><br /> {prop.nationality != null && prop.nationality != '' ? prop.nationality : '-'}</TableCell>
                          <TableCell component="th" scope="row">
                            <b>Nazione</b><br /> {prop.countryOfResidence != null && prop.countryOfResidence != '' ? prop.countryOfResidence : '-'}</TableCell>
  
                          <TableCell component="th" scope="row">
                            <b>Livello</b><br /> {prop.livello != null && prop.livello != '' ? prop.livello : '-'}</TableCell>
                          <TableCell component="th" scope="row">
                            <b>Documentazione</b><br /> {prop.documentazione ? (prop.documentazione == true ? "Sì" : "No") : "No"}</TableCell>
                          <TableCell component="th" scope="row">
                            <b>Società collegate</b><br /> {prop.societaCollegate != null && prop.societaCollegate != '' ? prop.societaCollegate : '-'}</TableCell>
                          <TableCell component="th" scope="row">
                            <b>Note</b><br /> {prop.note != null && prop.note != '' ? prop.note : '-'}</TableCell>
                        </TableRow>
                      </TableBody>
                    } else {
                      return <TableBody>
                        <TableRow key={prop.completeName}>
                          <TableCell component="th" scope="row">
                            <TextField id="dateOfBirth" label="Data di nascita" defaultValue={prop.dateOfBirth != null && prop.dateOfBirth != '' ? prop.dateOfBirth : '-'} onChange={(event) => { handleChangeItem(event, prop) }} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <TextField id="appointedNotifiedOn" label="Appointed on" defaultValue={prop.appointedNotifiedOn != null && prop.appointedNotifiedOn != '' ? prop.appointedNotifiedOn : '-'} onChange={(event) => { handleChangeItem(event, prop) }} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <TextField id="nationality" label="Nazionalità" defaultValue={prop.nationality != null && prop.nationality != '' ? prop.nationality : '-'} onChange={(event) => { handleChangeItem(event, prop) }} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <TextField id="countryOfResidence" label="Nazione" defaultValue={prop.countryOfResidence != null && prop.countryOfResidence != '' ? prop.countryOfResidence : '-'} onChange={(event) => { handleChangeItem(event, prop) }} />
                          </TableCell>
  
                          <TableCell component="th" scope="row">
                            <TextField id="livello" label="Livello" defaultValue={prop.livello != null && prop.livello != '' ? prop.livello : '-'} onChange={(event) => { handleChangeItem(event, prop) }} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                          <FormControlLabel                                        
                                        checked= {prop.documentazione}
                                        control={<Checkbox id= "documentazione" color="primary" onChange={(event) => { handleChangeItem(event, prop) }} />}
                                        label="Documentazione"
                                        labelPlacement="top"
                                        fontSize = {10}
                                    />
                                    </TableCell>
                          <TableCell component="th" scope="row">
                            <TextField id="societaCollegate" label="Società collegate" defaultValue={prop.societaCollegate != null && prop.societaCollegate != '' ? prop.societaCollegate : '-'} onChange={(event) => { handleChangeItem(event, prop) }} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <TextField id="note" label="Note" defaultValue={prop.societaCollegate != null && prop.societaCollegate != '' ? prop.societaCollegate : '-'} onChange={(event) => { handleChangeItem(event, prop) }} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    }
  
                  })()}
  
                </Table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            );    
        }
      })}
    </div>
  );
}
TitolareEffettivoAccordion.defaultProps = {
  active: -1
};

TitolareEffettivoAccordion.propTypes = {
  // index of the default active collapse
  active: PropTypes.number,
  editMode: PropTypes.bool,
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node
    })
  ).isRequired
};



