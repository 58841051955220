

// FORMATTAZIONE DI VARI DATI CON GESTIONE DEL CASO NULL
export function formatDateTimeAsDateTime(dateTime) {
    if ((dateTime === undefined) || (dateTime === null)) {
        return '';
    } else {
        try {
            var date = new Date(dateTime);
            return (new Intl.DateTimeFormat("it", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            }).format(date));
        } catch (err) {
            return '';
        }
    }
}

export function formatDateTimeAsDate(dateTime) {
    if ((dateTime === undefined) || (dateTime === null)) {
        return '';
    } else {
        try {
            var date = new Date(dateTime);
            return (new Intl.DateTimeFormat("it", {
                year: "numeric",
                month: "long",
                day: "numeric"
            }).format(date));
        } catch (err) {
            return '';
        }
    }
}

export function formatDateTimeAsDateAvailability(dateTime) {
    if ((dateTime === undefined) || (dateTime === null)) {
        return 'data non disponibile';
    } else {
        try {
            var date = new Date(dateTime);
            return (new Intl.DateTimeFormat("it", {
                year: "numeric",
                month: "long",
                day: "numeric"
            }).format(date));
        } catch (err) {
            return 'data non disponibile';
        }
    }
}

export function formatCurrency(value) {
    if ((value === undefined) || (value === null)) {
        return '';
    } else {
        try {
            const esCurrencyFormat = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' })
            return esCurrencyFormat.format(value); // "100.10 €"
        } catch (err) {
            return '';
        }
    }
}

export function formatCurrencyAvailability(value) {
    if ((value === undefined) || (value === null)) {
        return 'importo non disponibile';
    } else {
        try {
            const esCurrencyFormat = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' })
            return esCurrencyFormat.format(value); // "100.10 €"
        } catch (err) {
            return 'importo non disponibile';
        }
    }
}

export function formatTextualValue(value) {
    if ((value === undefined) || (value === null)) {
        return '';
    } else {
        try {
            return value.trim();
        } catch (err) {
            return '';
        }
    }
}

export function formatTextualValueAvailability(value) {
    if ((value === undefined) || (value === null)) {
        return 'dato non disponibile';
    } else {
        try {
            return value.trim();
        } catch (err) {
            return 'dato non disponibile ';
        }
    }
}

export function parseBooleanString(str) {
    if (str === 'Si') {
        return true;
    } else {
        return false;
    }
}

export function formatBooleanValue(value) {
    if ((value === undefined) || (value === null)) {
        return '';
    } else {
        if (value === true) {
            return 'Si';
        } else if (value === false) {
            return 'No';
        } else {
            return '';
        }
    }
}


//
export function formatBooleanValueAvailability(value) {
    if ((value === undefined) || (value === null)) {
        return 'informazione non disponibile';
    } else {
        if (value === true) {
            return 'Si';
        } else if (value === false) {
            return 'No';
        } else {
            return 'informazione non disponibile';
        }
    }
}


export function dateDiffInMinutes(date1, date2) {
    if ((!date1) || (date1 == null) || (!date2) || (date2 == null)) {
        return 0;
    }
    try {
        var diff = (date2.getTime() - date1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    } catch (err) {
        return 0;
    }

}