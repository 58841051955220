// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import SaveIcon from "@material-ui/icons/Save";
import SearcdIcon from "@material-ui/icons/Search";
import AccountBox from "@material-ui/icons/AccountBox";
import TimeIcon from "@material-ui/icons/Timer";
import Group from "@material-ui/icons/Group";
import AccountingIcon from "@material-ui/icons/EventNote";
import ExitToApp from "@material-ui/icons/ExitToApp";
import UsersManagement from "views/COMMON/UsersManagement";
import ProfileEditing from "views/COMMON/UserProfileEditing";
import Dashboard from "views/TEE/Dashboard";
import NewRequest from "views/COMMON/NewRequest";
import Search from "views/COMMON/Search";
import Accounting from "views/COMMON/Accounting";
// import ExternalSources from "views/COMMON/ExternalSources";
import Icons from "views/Components/Icons.js";
import Logout from "layouts/Logout";
import * as AuthService from "services/AuthService";
import RequestResult from "views/COMMON/RequestResult";
import VerifyCode from "layouts/VerifyCode";
import QRCodePage from "layouts/QRCodePage";

var dashRoutes = [ 
  {
    path: "/dashboard",
    name: "TEE Dashboard",
    description: "Dashboard generale e dati di sintesi",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/consolle",
    fixed: "false",
    roles:
      [

      ]
  },
  {
    path: "/nuovaRichiesta",
    name: "Nuova richiesta",
    description: "Nuova richiesta",
    rtlName: "لوحة القيادة",
    icon: SaveIcon,
    component: NewRequest,
    layout: "/consolle",
    fixed: "false",
    roles:
      [
        AuthService.ADMINISTRATIVE_ROLE_NAME,
        AuthService.OPERATIVE_ROLE_NAME
      ]
  },
  {
    path: "/search",
    name: "Ricerca",
    description: "Ricerca",
    rtlName: "لوحة القيادة",
    icon: SearcdIcon,
    component: Search,
    layout: "/consolle",
    fixed: "false",
    roles:
      [
      ]
  },
  {
    path: "/users",
    name: "Gestione Utenti",
    description: "Gestione degli utenti dell'applicazione",
    rtlName: "لوحة القيادة",
    icon: Group,
    component: UsersManagement,
    layout: "/consolle",
    fixed: "false",
    roles:
      [
        AuthService.ADMINISTRATIVE_ROLE_NAME
      ]    
  },
  {
    path: "/storico-richieste",
    name: "Storico richieste",
    description: "Gestione dello storico richieste",
    rtlName: "لوحة القيادة",
    icon: AccountingIcon,
    component: Accounting,
    layout: "/consolle",
    fixed: "false",
    roles:
      [
        AuthService.ADMINISTRATIVE_ROLE_NAME,
        AuthService.OPERATIVE_ROLE_NAME
      ]
  },
  {
    path: "/request-result",
    name: "Risultati richiesta",
    description: "Gestione dei risultati di una richiesta",
    icon: AccountingIcon,
    component: RequestResult,
    layout: "/consolle",
    fixed: "true",
    visible: false,
    roles:
      [
        AuthService.ADMINISTRATIVE_ROLE_NAME,
        AuthService.OPERATIVE_ROLE_NAME
      ]
  },
  {
    path: "/verify-code",
    name: "Verifica accesso",
    description: "Autenticazione a due fattori: verifica codice di sicurezza",
    icon: AccountingIcon,
    component: VerifyCode,
    //layout: "/consolle",
    fixed: "true",
    visible: false,
    roles: null
    /*
    roles:
      [
        AuthService.ADMINISTRATIVE_ROLE_NAME,
        AuthService.COORDINATIVE_ROLE_NAME,
        AuthService.OPERATIVE_ROLE_NAME,
        AuthService.VOLKSBANK_ADMINISTRATIVE_ROLE_NAME
      ]
      */
  },
  {
    path: "/scan-qr-code",
    name: "Verifica accesso",
    description: "Autenticazione a due fattori: scannerizza codice QR per effettuare associazione con Authenticator",
    icon: AccountingIcon,
    component: QRCodePage,
    layout: "/consolle",
    fixed: "true",
    visible: false,
    roles:
      [
        AuthService.ADMINISTRATIVE_ROLE_NAME,
        AuthService.OPERATIVE_ROLE_NAME
      ]
  },
  // {
  //   path: "/richieste-sospese",
  //   name: "Richieste Sospese",
  //   description: "Gestione richieste in waiting",
  //   rtlName: "لوحة القيادة",
  //   icon: TimeIcon,
  //   component: ExternalSources,
  //   layout: "/consolle",
  //   fixed: "false",
  //   roles:
  //     [
  //       AuthService.ADMINISTRATIVE_ROLE_NAME,
  //       AuthService.COORDINATIVE_ROLE_NAME
  //     ]
  // },
  /*
  {
    path: "/task-sospesi",
    name: "Task Sospesi",
    description: "Gestione task in waiting",
    rtlName: "لوحة القيادة",
    icon: TimeIcon,
    component: ExternalSources,
    layout: "/consolle",
    fixed: "false",
    roles:
      [
        AuthService.ADMINISTRATIVE_ROLE_NAME,
        AuthService.COORDINATIVE_ROLE_NAME
      ]
  },
  */
  /*
  {
    path: "/customers",
    name: "Scoring Clienti",
    description: "Gestione dei clienti e delle relative valutazioni di scoring",
    rtlName: "لوحة القيادة",
    icon: BarChart,
    component: CascoCustomersManagement,
    layout: "/consolle",
    fixed: "false",
    roles:
      [
        AuthService.CASCO_ADMINISTRATIVE_ROLE_NAME,
        AuthService.CASCO_COORDINATIVE_ROLE_NAME
      ]    
  },  
  */
  {
    path: "/profile",
    name: "Gestione Profilo",
    description: "Gestione del profilo personale",
    rtlName: "لوحة القيادة",
    icon: AccountBox,
    component: ProfileEditing,
    layout: "/consolle",
    fixed: "true",
    roles:
      [
        AuthService.ADMINISTRATIVE_ROLE_NAME,
        AuthService.OPERATIVE_ROLE_NAME
      ]    
  },  
  {
    path: "/logout",
    name: "Logout",
    description: "Esci dall'applicazione",
    rtlName: "لوحة القيادة",
    icon: ExitToApp,
    component: Logout,
    layout: "/consolle",
    fixed: "true",
    roles:
      [
        AuthService.ADMINISTRATIVE_ROLE_NAME,
        AuthService.OPERATIVE_ROLE_NAME,
      ]    
  }/*,
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    mini: "I",
    rtlMini: "و",
    component: Icons,
    layout: "/consolle",
    fixed: "false",
    roles:
      [
        AuthService.CASCO_ADMINISTRATIVE_ROLE_NAME,
        AuthService.CASCO_COORDINATIVE_ROLE_NAME   
      ]
  }*/
    /*
  {
    path: "/settings",
    name: "Impostazioni",
    description: "Impostazioni dell'applicazione",
    rtlName: "لوحة القيادة",
    icon: Settings,
    component: ProfileEditing,
    layout: "/consolle"
  },
  */
  /*{
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/pricing-page",
        name: "Pricing Page",
        rtlName: "عالتسعير",
        mini: "PP",
        rtlMini: "ع",
        component: PricingPage,
        layout: "/auth"
      },
      {
        path: "/rtl-support-page",
        name: "RTL Support",
        rtlName: "صودعم رتل",
        mini: "RS",
        rtlMini: "صو",
        component: RTLSupport,
        layout: "/rtl"
      },
      {
        path: "/timeline-page",
        name: "Timeline Page",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: TimelinePage,
        layout: "/consolle"
      },
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth"
      },
      {
        path: "/register-page",
        name: "Register Page",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: RegisterPage,
        layout: "/auth"
      },
      {
        path: "/lock-screen-page",
        name: "Lock Screen Page",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: LockScreenPage,
        layout: "/auth"
      },
      {
        path: "/user-page",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: UserProfile,
        layout: "/consolle"
      },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/auth"
      }
    ]
  },*/
  
  /*{
    collapse: true,
    name: "Components",
    rtlName: "المكونات",
    icon: Apps,
    state: "componentsCollapse",
    views: [
      {
        collapse: true,
        name: "Multi Level Collapse",
        rtlName: "انهيار متعدد المستويات",
        mini: "MC",
        rtlMini: "ر",
        state: "multiCollapse",
        views: [
          {
            path: "/buttons",
            name: "Buttons",
            rtlName: "وصفت",
            mini: "B",
            rtlMini: "ب",
            component: Buttons,
            layout: "/consolle"
          }
        ]
      },
      {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: Buttons,
        layout: "/consolle"
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: GridSystem,
        layout: "/consolle"
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: Panels,
        layout: "/consolle"
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        rtlName: "الحلو تنبيه",
        mini: "SA",
        rtlMini: "ومن",
        component: SweetAlert,
        layout: "/consolle"
      },
      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: Notifications,
        layout: "/consolle"
      },
      {
        path: "/icons",
        name: "Icons",
        rtlName: "الرموز",
        mini: "I",
        rtlMini: "و",
        component: Icons,
        layout: "/consolle"
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: Typography,
        layout: "/consolle"
      }
    ]
  },
  */

  /*
  {
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "content_paste",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: RegularForms,
        layout: "/consolle"
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: ExtendedForms,
        layout: "/consolle"
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: ValidationForms,
        layout: "/consolle"
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: Wizard,
        layout: "/consolle"
      }
    ]
  },
  {
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: GridOn,
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: RegularTables,
        layout: "/consolle"
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: ExtendedTables,
        layout: "/consolle"
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: ReactTables,
        layout: "/consolle"
      }
    ]
  },
  {
    collapse: true,
    name: "Maps",
    rtlName: "خرائط",
    icon: Place,
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        rtlName: "خرائط جوجل",
        mini: "GM",
        rtlMini: "زم",
        component: GoogleMaps,
        layout: "/consolle"
      },
      {
        path: "/full-screen-maps",
        name: "Full Screen Map",
        rtlName: "خريطة كاملة الشاشة",
        mini: "FSM",
        rtlMini: "ووم",
        component: FullScreenMap,
        layout: "/consolle"
      },
      {
        path: "/vector-maps",
        name: "Vector Map",
        rtlName: "خريطة المتجه",
        mini: "VM",
        rtlMini: "تم",
        component: VectorMap,
        layout: "/consolle"
      }
    ]
  },
  {
    path: "/widgets",
    name: "Widgets",
    rtlName: "الحاجيات",
    icon: WidgetsIcon,
    component: Widgets,
    layout: "/consolle"
  },
  {
    path: "/charts",
    name: "Charts",
    rtlName: "الرسوم البيانية",
    icon: Timeline,
    component: Charts,
    layout: "/consolle"
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "التقويم",
    icon: DateRange,
    component: Calendar,
    layout: "/consolle"
  } */
];
export default dashRoutes;
