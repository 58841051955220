/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import HomeHead from "layouts/parts/Home-Head.js";
import { Slide } from 'react-slideshow-image';
import 'assets/css/slide-show.css';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import homeimg from 'assets/img/TEE/foto_home2.jpg';
import homeimg from 'assets/img/TEE/home-header.png';
import { createBrowserHistory } from "history";
import * as AuthService from 'services/AuthService.js';

import Login from "layouts/Login"


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "25x", 
      fontWeight: "500",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "30px",
    fontWeight: "700",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardAvatarRightAlign: {
    textAlign: "right"
  },
};

const slideImages = [
  homeimg,
  homeimg,
  homeimg,
  homeimg,
  homeimg,
  homeimg  
];

/*
const slideImages = [
  intro,
  indicatori,
  arrows,
  flag,
  advancedScore,
  informazioni
];*/

const properties = {
  duration: 4000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  pauseOnHover: true,
  onChange: (oldIndex, newIndex) => {
    console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  }
}
 
const Slideshow = () => {
    return (
      <Card>
        <CardBody>

      <div className="slide-container">
        <Slide {...properties}>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${slideImages[0]})`}}>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${slideImages[1]})`}}>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${slideImages[2]})`}}>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${slideImages[3]})`}}>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${slideImages[4]})`}}>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${slideImages[5]})`}}>
            </div>
          </div>                                        
        </Slide>
      </div>
      </CardBody>
        </Card>
    )
}

const FixedHome = () => {
  return (
    <Card>
      <CardBody style={{'position': 'relative', 'maxWidth': '100%'}}>
        {/* <img src={homeimg} alt={AuthService.APPLICATION_DESCRIPTION}  style={{'width':'40%', 'height':'40%', 'object-fit': 'contain'}} /> */}
        <img src={homeimg} alt={AuthService.APPLICATION_DESCRIPTION}  style={{'maxWidth':'100%', 'maxHeight':'40%'}} />
      </CardBody>
      <p style={{position:"relative", top:"10px", fontSize:"11px", fontWeight:"bold", textAlign:"center"}}>
        Complify è un marchio di proprietà di Esquad s.r.l. con sede legale in via XXIV Maggio 16 87100 Cosenza – CF/PI 10229340962
      </p>
    </Card>
  )
}

const useStyles = makeStyles(styles);
const hist = createBrowserHistory();

export default function Home() {
  const classes = useStyles();
  // return (
  //   <div style={{width: '75%', height: '80%', margin: 'auto'}}>
      
  //     <GridContainer style={{width: '92.0%', margin: 'auto'}}>
  //       <GridItem xs={12} sm={12} md={12}>
  //         <HomeHead/>
  //       </GridItem>
  //     </GridContainer>


  //     <div style={{marginLeft: "27px"}}>
  //     <GridContainer style={{width: '96%', margin: 'auto'}}>
  //       <GridItem xs={12} sm={12} md={12}>
  //         <Login withMargin={true}/>
  //       </GridItem>
  //     </GridContainer>
  //     </div>
 
      
  //     <GridContainer style={{width: '92.0%', height: '60%', maxHeight: '80%', margin: 'auto', marginTop: '-40px'}}>
      
  //       <GridItem xs={12} sm={12} md={12}>
  //         <FixedHome/>
  //       </GridItem>
  //     </GridContainer>
      
      
  //   </div>
  // );

  return(
    <div style={{position:"relative", background:"white", width:"100%", height:"100%"}}>
      <img src={homeimg} style={{'maxWidth':'95%', 'maxHeight':'20%'}}></img>
      <div className={"home-layout"}>
        <Login/>
      </div>
     <div className={"div-home-footer-layout"}>
      <p className={"home-footer-layout"}>
        Complify è un marchio di proprietà di Esquad s.r.l. con sede legale in via XXIV Maggio 16 87100 Cosenza – CF/PI 10229340962
      </p>
     </div>
    </div>
  );
}
