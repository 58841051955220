import React from "react";
import {
    BrowserRouter as Router,
    Link,
    withRouter
} from "react-router-dom";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import HowToReg from "@material-ui/icons/HowToReg";
import Security from "@material-ui/icons/Security";
import Home from "@material-ui/icons/Home";
import NoEncryption from "@material-ui/icons/NoEncryption";
import * as AuthService from 'services/AuthService.js';
import { AuthConsumer } from "context/AuthContext.js";
import { appMainColors } from "assets/jss/material-dashboard-pro-react.js"

import * as AxiosService from 'layouts/AxiosService';
const styles = {
    cardCategoryWhite: {
        color: "#FFFFFF",
        margin: "0",
        fontSize: "18px",
        fontWeight: "700",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontSize: "31px",
        fontWeight: "700",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    cardAvatarRightAlign: {
        textAlign: "right"
    },
    cardCategoryPowered: {
        color: "#FFFFFF",
        margin: "0",
        fontSize: "13px",
        fontWeight: "600",
        marginTop: "0",
        marginBottom: "0"
    },
    centeredLoginForm: {
        width: "55%",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    }
};


class VerifyCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        let email = '';
        if(localStorage.getItem('loginEmail')){
            email = localStorage.getItem('loginEmail');
            localStorage.removeItem('loginEmail');
        }
        return {
            codice: '', message: '', loginEmail: email,
            submitted: false, success: false, successRedirect: '/admin', failureRedirect: ''
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handleCodeInsertedChange(event) {
        this.setState({ codice: event.target.value });
    }

    render() {
        let topMessage = null;
        let msg = '';
        const loginButtonsColor = appMainColors[0];
        if (this.state.submitted) {
            if (this.state.success) {
                msg = (<Success variant="h4"><Security />{this.state.message}</Success>);
            } else {
                msg = (<Warning variant="h4"><Security />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message}</Warning>);
            }
            topMessage = (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                        <br />{msg}
                    </GridItem>
                </GridContainer>
            )
        } else topMessage = (<br />);
        return (
            // <div style={!this.props.withMargin? styles.centeredLoginForm:{backgroundColor:"white"}}>
            <div style={{ backgroundColor: "white" }}>
                <AuthConsumer>
                    {({ isAuth, login, logout }) => (

                        <GridContainer style={{ width: '100%' }}>
                            <GridItem xs={12} sm={12} md={12}>
                                <div style={{ margin: "auto", minWidth: "400px" }}>
                                    <Card>
                                        <div style={{ position: "relative", border: "2px solid rgb(188, 188, 188)" }}>
                                            <div style={{ position: "absolute", top: "-30px", left: "30px", padding: "0 17px", backgroundColor: "white" }}>
                                                <p style={{ fontStyle: "italic" }}>
                                                    <b style={{ fontStyle: "normal", fontSize: "1.6em" }}>{AuthService.APPLICATION_DESCRIPTION}</b> <b style={{ fontSize: "1.5em", color: "rgb(65,65,65)" }}> Security Code</b>
                                                    <br />
                                                    <b style={{ fontSize: "0.85em" }}>Inserisci il codice di sicurezza generato dall'Authenticator</b>
                                                </p>
                                            </div>

                                            <CardBody>
                                                {topMessage}
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <CustomInput
                                                            labelText="Codice di sicurezza"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <AccountCircle />
                                                                    </InputAdornment>
                                                                ),
                                                                type: "email",
                                                                autoFocus: true,
                                                                onChange: (e) => { this.handleCodeInsertedChange(e) }
                                                            }}
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                            </CardBody>
                                            <CardFooter>
                                                <div style={{ position: "relative", top: "40px", marginLeft: "auto", marginRight: "auto", mtextAlign: "center" }}>
                                                    <Button
                                                        style={{ fontSize: '17px' }}
                                                        onClick={() => { this.VerifyCodeAndAutenticateInTeeApplication(isAuth, login, logout) }}
                                                    >
                                                        Conferma&nbsp;&nbsp;&nbsp;<HowToReg style={{ fontSize: '28px' }} />
                                                    </Button>
                                                </div>
                                                <br />
                                            </CardFooter>
                                        </div>
                                    </Card>
                                </div>
                            </GridItem>
                        </GridContainer>

                    )}
                </AuthConsumer>
            </div>
        );
    }


    VerifyCodeAndAutenticateInTeeApplication(isAuth, login, logout) {    
        //this.setState({message: '', submitted: false, success: false});       
        //this.setState({submitted: true});
        let tokenWithPrefix = AuthService.getLoggedUserJwtToken();
        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        fetch(AuthService.getBackendApiVerifyCode(), {
          method: 'POST',
          headers: headerWithAuth,
          body: JSON.stringify({
            code: this.state.codice
          })
        })
          .then(res => res.json())
          .then((data) => {
            let jwtToken = null;
            if (data.success === true) {
                jwtToken = data.payload;
                AuthService.setLoggedUserJwtToken(jwtToken);
                //let tokenWithPrefix = AuthService.getLoggedUserJwtToken();
                //let authHeader = AuthService.AUTHORIZATION_HEADER_NAME;
                let appName = AuthService.getApplicationName();
                //let headerWithAuth = AuthService.getAuthHeaderAsObj();
                headerWithAuth = AuthService.getAuthHeaderAsObj();
                AxiosService.setInterceptor();
                fetch(AuthService.getBackendApiApplicationLogin(), {
                  method: 'POST',
                  headers: headerWithAuth,
                  body: JSON.stringify({
                    loginEmail: this.state.loginEmail,
                    applicationName: appName
                  })
                })
                  .then(res => res.json())
                  .then((data) => {
                    if (data.success === true) {
                      console.log('LOGIN SUCCESSFULLY........');
                      let loggedUser = data.payload;
                      AuthService.setLoggedUserInfos(loggedUser, jwtToken);
                      login();
                      console.log('LOGGED USER:' + JSON.stringify(AuthService.getLoggedUserObject()));
                      console.log('LOGGED TOKEN:' + JSON.stringify(AuthService.getLoggedUserJwtToken()));
                      this.setState({ message: data.message, submitted: true, success: true });
                      this.props.history.push("/consolle");
                    } else {
                      console.log('ERROR........');
                      AuthService.clearLoggedUserInfos();
                      logout();
                      this.setState({ message: data.message, submitted: true, success: false });
                      //this.props.history.push('/login');        
                    }
                  })
                  .catch((error) => {
                    console.log('ERROR COMUNICATION WITH APP LOGIN SERVICE..');
                    console.log(JSON.stringify(error.message));
                    AuthService.clearLoggedUserInfos();
                    logout();
                    this.setState({ message: error.message, submitted: true, success: false });
                  });
              } else {
                console.log('ERROR........');
                //AuthService.clearLoggedUserInfos();
                logout();
                this.setState({ message: data.message, submitted: true, success: false });
              }
            })
            .catch((error) => {
              console.log('ERROR........');
              console.log(JSON.stringify(error));
              AuthService.clearLoggedUserInfos();
              this.props.history.push("/home");
              this.setState({ message: error.message, submitted: true, success: false });
            });
      }    

}

export default VerifyCode;
