import React from "react";

// react component plugin for creating a beautiful datetime dropdown picker
import 'react-nice-dates/build/style.css';

// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';

// core components
import PropTypes from 'prop-types';
import * as AuthService from 'services/AuthService.js';
import * as CustomersManagementTabs from 'views/COMMON/CustomersManagement.js';
import Cancel from "@material-ui/icons/Cancel";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import * as CascoDashboard from 'views/TEE/Dashboard';
import CompanyReportVisualizer from "./CompanyReportVisualizer";
import ReactToPrint from 'react-to-print';
import Print from "@material-ui/icons/Print";
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import EditIcon from '@material-ui/icons/Edit';
import { axiosApiCaller } from 'layouts/AxiosService';

import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import SmallMenu from 'views/COMMON/SmallMenu';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Swal from 'sweetalert2'
import ReactDOM from "react-dom";

// Create styles
const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const classes = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));


const CHANGE_VIEW_TIME_GO_TO_LIST = 1200;

class ReportMultiVisualizer extends React.Component {

  constructor(props) {
    super(props);
    //this.cancelEditing = this.cancelEditing.bind(this);
    this.componentRef = React.createRef();
    this.state = this.getInitialState();
  }


  getInitialState() {
    return {
      errorMessage: null,
      typeForDetail: CascoDashboard.PERSONA_FISICA_LABEL,
      itemForDetail: this.props.itemObj,
      editMode: false,
      isEditModeUsable: this.props.isEditModeUsable ? this.props.isEditModeUsable : false,
      specificScoreComponent: null
    };
  }

  componentWillMount() {
    console.log(this.props.itemObj);
    if (this.props.itemObj) {
      if (this.props.itemObj.codiceFiscale) {
        let positionDetail = this.extractPositionDetailFromBackend(this.props.itemObj.codiceFiscale);
      }
    }
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  changeMode() {
    this.setState({ editMode: !this.state.editMode });
  }

  saveData() {
    let teeDiscoveredJuridicalSubjecBoxDaModificare = {};
    teeDiscoveredJuridicalSubjecBoxDaModificare.cap = this.componentRef.current.cleanedData.cap;
    teeDiscoveredJuridicalSubjecBoxDaModificare.customerId = this.componentRef.current.cleanedData.customerId;
    teeDiscoveredJuridicalSubjecBoxDaModificare.elaborationMessage = this.componentRef.current.cleanedData.elaborationMessage;
    teeDiscoveredJuridicalSubjecBoxDaModificare.elaborationState = this.componentRef.current.cleanedData.elaborationState;
    teeDiscoveredJuridicalSubjecBoxDaModificare.foreignActualOwners = this.componentRef.current.cleanedData.foreignActualOwners;
    teeDiscoveredJuridicalSubjecBoxDaModificare.indirizzo = this.componentRef.current.cleanedData.indirizzo;
    teeDiscoveredJuridicalSubjecBoxDaModificare.localita = this.componentRef.current.cleanedData.localita;
    teeDiscoveredJuridicalSubjecBoxDaModificare.paeseProvincia = this.componentRef.current.cleanedData.paeseProvincia;
    teeDiscoveredJuridicalSubjecBoxDaModificare.partitaIva = this.componentRef.current.cleanedData.partitaIva;
    teeDiscoveredJuridicalSubjecBoxDaModificare.ragioneSociale = this.componentRef.current.cleanedData.ragioneSociale;
    teeDiscoveredJuridicalSubjecBoxDaModificare.monitoraggioType = this.componentRef.current.cleanedData.monitoraggioType;
    teeDiscoveredJuridicalSubjecBoxDaModificare.monitoraggioFlag = this.componentRef.current.cleanedData.monitoraggioFlag;
    teeDiscoveredJuridicalSubjecBoxDaModificare.monitoraggio = this.componentRef.current.cleanedData.monitoraggio;
    teeDiscoveredJuridicalSubjecBoxDaModificare.documentazione = this.componentRef.current.cleanedData.documentazione;
    teeDiscoveredJuridicalSubjecBoxDaModificare.note = this.componentRef.current.cleanedData.note;
    teeDiscoveredJuridicalSubjecBoxDaModificare.platformId = this.componentRef.current.cleanedData.platformId;
    /*
    teeDiscoveredJuridicalSubjecBoxDaModificare.foreignActualOwners.forEach(foreignOwner => {
      if (foreignOwner.documentazione === "(Non disponibile)") {
        foreignOwner.documentazione = false;
      } else {
        foreignOwner.documentazione = true;
      }
    });
    */
    let request={teeDiscoveredJuridicalSubjecBox: teeDiscoveredJuridicalSubjecBoxDaModificare, idRichiesta:localStorage.getItem("idRichiesta")};
    let apiUrlToCall = '';
    apiUrlToCall = AuthService.getBackendApiTeeDiscoveredJuridicalSubjectBoxUpdateUrl();
    axiosApiCaller.post(apiUrlToCall, request)
    this.changeMode();
  }

  addSocio() {
    (async () => {
      const { value: name } = await Swal.fire({
        title: 'Nuovo titolare estero effettivo',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Conferma',
        reverseButtons: true,
        input: 'text',
        inputLabel: 'Name',
        inputPlaceholder: 'Enter name',
        inputValidator: (value) => {
          if (!value) {
            return 'You need to write something!'
          }
        }
      });
      let newForeignActualOwners = {
        completeName: name,
        appointedNotifiedOn: "(Non disponibile)",
        bnfcCountry: "(Non disponibile)",
        bnfcDateBirth: "(Non disponibile)",
        bnfcOwnerLegalForm: "(Non disponibile)",
        bnfcOwnerType: "(Non disponibile)",
        bnfcPostalCode: "(Non disponibile)",
        bnfcTown: "(Non disponibile)",
        countryOfResidence: "(Non disponibile)",
        dateOfBirth: "(Non disponibile)",
        documentazione: false,
        idSoggetto: "(Non disponibile)",
        livello: 0,
        nationality: "(Non disponibile)",
        note: "(Non disponibile)",
        progr: 1,
        role: "(Non disponibile)",
        societaCollegate: "(Non disponibile)",
      };
      this.state.itemForDetail.foreignActualOwners.push(newForeignActualOwners);
      this.setState({ itemForDetail: this.state.itemForDetail });
    })()
  };

  /*

    descriptionForDetail: (this.props.itemDescription),
    keyForDetail: (this.props.itemKey),
    itemForDetail: (this.props.itemObj),
    typeForDetail: CascoDashboard.PERSONA_FISICA_LABEL,
    iconForDetail: (this.props.itemIcon)

*/
  render() {
    let message = '';
    //let specificScoreComponent = null;
    message = 'Componente per la valutazione di persone giuridiche';
    this.specificScoreComponent = (
      <CompanyReportVisualizer
        itemForDetail={this.state.itemForDetail}
        errorMessage={this.state.errorMessage}
        editMode={this.state.editMode}
        mainIcon={this.props.itemIcon}
        ref={this.componentRef}
        /* ref={el => (this.componentRef = el)} */ />
    );

    return (
      <div style={{ marginLeft: "10px" }}>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <span style={{ float: 'right', margin: '-23px 30px 10px 0px' }}>
              <SmallMenu ragioneSociale={this.props.itemKey} menuPosition={"inDetails"} />
            </span>
            <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return <a href="#"><Print style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '30px', height: '30px' }} /></a>;
              }}
              content={() => this.componentRef}
            />
            {(() => {
              if (!this.state.editMode && this.state.isEditModeUsable) {
                if(this.state.itemForDetail.elaborationState != "SOSPESO" &&  this.state.itemForDetail.elaborationState != "ANNULLATO"){
                  return <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label="download" onClick={() => { this.changeMode(); }}>
                    <EditIcon />
                  </IconButton>;
                }
              }
              else if (this.state.editMode) {
                return <IconButton color="secondary" style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label="download" onClick={() => { this.changeMode(); }}>
                  <CancelIcon />
                </IconButton>;
              }
            })()}
            {(() => {
              if (this.state.editMode) {
                return <IconButton style={{ color: 'green', float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label="addSocio" onClick={() => { this.addSocio(); }}>
                  <AddCircleIcon />
                </IconButton>;
              }
            })()}
            {(() => {
              if (this.state.editMode) {
                return <IconButton color="primary" style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label="download" onClick={() => { this.saveData(); }}>
                  <SaveIcon />
                </IconButton>;
              }
            })()}
            {this.specificScoreComponent}
          </GridItem>
        </GridContainer>
        <br />
      </div >
    )
  }

  extractPositionDetailFromBackend(positionFiscalCode) {
    let appName = AuthService.getApplicationName();
    //let headerWithAuth = AuthService.getAuthHeaderAsObj();
    let apiUrlToCall = '';
    if (this.props.itemObj.personaFisica && ((this.props.itemObj.personaFisica === true))) {
      apiUrlToCall = AuthService.getBackendApiUtpPersonDetail();
    } else {
      apiUrlToCall = AuthService.getBackendApiUtpCompanyDetail();
    }
    // fetch(apiUrlToCall, {
    //   method: 'POST',
    //   headers: headerWithAuth,
    //   body: JSON.stringify({
    //     applicationName: appName,
    //     fiscalCode: positionFiscalCode
    //   })
    // })
    axiosApiCaller.post(apiUrlToCall, {
      applicationName: appName,
      fiscalCode: positionFiscalCode
    })
      .then((res) => {
        if (res.data.success === true) {
          this.setState({ itemForDetail: res.data.payload });
          if (res.data.payload.personaFisica && ((res.data.payload.personaFisica === true))) {
            this.setState({ typeForDetail: CascoDashboard.PERSONA_FISICA_LABEL });
          } else {
            this.setState({ typeForDetail: CascoDashboard.PERSONA_GIURIDICA_LABEL });
          }
          this.setState({ errorMessage: null });
        } else {
          this.setState({ errorMessage: res.data.message });
          console.log('ERROR: ' + res.data.message);
        }
      })
      .catch((error) => {
        console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
        console.log(JSON.stringify(error.message));
        this.setState({ errorMessage: error.message });
      });
  }

}

ReportMultiVisualizer.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  itemKey: PropTypes.string.isRequired,
  itemObj: PropTypes.object.isRequired,
  itemDescription: PropTypes.string.isRequired,
  itemIcon: PropTypes.object.isRequired
};

export default ReportMultiVisualizer;