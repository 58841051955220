export const LOGGED_USER_ITEM_NAME = 'loggedUser';
export const USER_APPLICATION_NAME = 'applicationName';
export const USER_APPLICATION_DESCRIPTION = 'applicationDescription';
export const AUTHORIZATION_JWT_TOKEN_PREFIX = 'Sintegra Analytics - ';
export const LOGGED_USER_JWT_TOKEN_ITEM_NAME = 'loggedToken';
export const LOGGED_USER_CURRENT_SESSION_ITEM_NAME = 'currentSession';
export const LOGGED_USER_PREVIOUS_SESSION_ITEM_NAME = 'previousSession';

export const APPLICATION_DESCRIPTION = 'Reporting System';
export const AUTHORIZATION_HEADER_NAME = 'Authorization';
export const TOKEN_REFRESH = 'TokenRefresh';
export const LOGGED_USER_JWT_TOKEN_REFRESH_ITEM_NAME = "loggedRefreshToken";
export const NULL_AUTHORIZATION_INFO = null;

export const ADMINISTRATIVE_ROLE_NAME = 'REPORTING_SYSTEM Administrator';
export const OPERATIVE_ROLE_NAME = 'REPORTING_SYSTEM Operator';

export function isUserAuthenticathed() {
    if ((getLoggedUserObject() === NULL_AUTHORIZATION_INFO) ||
        (getLoggedUserJwtToken() === NULL_AUTHORIZATION_INFO)) {
        //console.log('return false  return false  return false');
        return false;
    } else {
        //console.log('return true  return true  return true');
        return true;
    }
}

export function setLoggedUserInfos(newUserInfo, newUserToken,jwtTokenRefresh) {
    this.setLoggedUserObject(newUserInfo);
    this.setLoggedUserJwtToken(newUserToken);
    this.setLoggedUserJwtTokenRefresh(jwtTokenRefresh);
}

export function setLoggedUserObject(newUserInfo) {
    localStorage.setItem(LOGGED_USER_ITEM_NAME, JSON.stringify(newUserInfo));
    if (newUserInfo.sessionHistory) {
        let counter = 0;
        newUserInfo.sessionHistory.forEach(sessionElement => {
            if (counter === 0) {
                this.setLoggedUserCurrentSession(sessionElement);
            } else if (counter === 1) {
                this.setLoggedUserPreviousSession(sessionElement);
            } else {
                return;
            }
            counter++;
        });
    }    
}

export function setLoggedUserJwtToken(newUserToken) {
    localStorage.setItem(LOGGED_USER_JWT_TOKEN_ITEM_NAME, AUTHORIZATION_JWT_TOKEN_PREFIX + newUserToken);
}

export function setLoggedUserCurrentSession(userSession) {
    localStorage.setItem(LOGGED_USER_CURRENT_SESSION_ITEM_NAME, JSON.stringify(userSession));
}

export function setLoggedUserJwtTokenRefresh(jwtTokenRefresh) {
    localStorage.setItem(LOGGED_USER_JWT_TOKEN_REFRESH_ITEM_NAME,AUTHORIZATION_JWT_TOKEN_PREFIX + jwtTokenRefresh);
}

export function setLoggedUserPreviousSession(userSession) {
    localStorage.setItem(LOGGED_USER_PREVIOUS_SESSION_ITEM_NAME, JSON.stringify(userSession));
}

export function getLoggedUserObject() {
    if (localStorage.getItem(LOGGED_USER_ITEM_NAME) === null) {
        return NULL_AUTHORIZATION_INFO;
    } else {
        return JSON.parse(localStorage.getItem(LOGGED_USER_ITEM_NAME));
    }  
}

export function getLoggedUserJwtToken(){
    if (localStorage.getItem(LOGGED_USER_JWT_TOKEN_ITEM_NAME) === null) {
        return NULL_AUTHORIZATION_INFO;
    } else {
        return localStorage.getItem(LOGGED_USER_JWT_TOKEN_ITEM_NAME);
    }      
}

export function getLoggedUserCurrentSession() {
    if (localStorage.getItem(LOGGED_USER_CURRENT_SESSION_ITEM_NAME) === null) {
        return NULL_AUTHORIZATION_INFO;
    } else {
        return JSON.parse(localStorage.getItem(LOGGED_USER_CURRENT_SESSION_ITEM_NAME));
    }  
}

export function getLoggedUserPreviousSession() {
    if (localStorage.getItem(LOGGED_USER_PREVIOUS_SESSION_ITEM_NAME) === null) {
        return NULL_AUTHORIZATION_INFO;
    } else {
        return JSON.parse(localStorage.getItem(LOGGED_USER_PREVIOUS_SESSION_ITEM_NAME));
    }  
}

export function clearLoggedUserInfos(){
    localStorage.removeItem(LOGGED_USER_ITEM_NAME);
    localStorage.removeItem(LOGGED_USER_JWT_TOKEN_ITEM_NAME);
    localStorage.removeItem(LOGGED_USER_CURRENT_SESSION_ITEM_NAME);
    localStorage.removeItem(LOGGED_USER_PREVIOUS_SESSION_ITEM_NAME);
    localStorage.clear();   
}

export function getAuthHeaderName() {
    return AUTHORIZATION_HEADER_NAME;
}

export function getAuthHeaderAsObj() {
    let authHeaders = {};
    authHeaders["Accept"] = 'application/json';
    authHeaders["Content-Type"] = 'application/json';
    authHeaders[AUTHORIZATION_HEADER_NAME] = this.getLoggedUserJwtToken();      
    return authHeaders;
}
export function getBackendApiResultFromRequests() {
  return (
    process.env.REACT_APP_BACKEND_HOST +
    "/data-analysis/internal/analysis/discovering/tee/search-in-request"
  );
}

export function authenticathedUserCanManageCustomersScoring() {
    return authenticathedUserHasAtLeastOneRole([
        ADMINISTRATIVE_ROLE_NAME
    ]);
}

export function authenticathedUserCanManageUsers() {
    return authenticathedUserHasAtLeastOneRole([
        ADMINISTRATIVE_ROLE_NAME
    ]);
}

export function authenticathedUserCanViewUsers() {
    return authenticathedUserHasAtLeastOneRole([
        ADMINISTRATIVE_ROLE_NAME
    ]);
}

export function authenticathedUserHasAtLeastOneRole(roles) {
    if ((isUserAuthenticathed() === false) || (!roles) || (roles === null)) {
        return false;
    } else {
        let currentUser = getLoggedUserObject();
        let hasAtLeastOne = false;
        if (currentUser.appRoleNames) {
            // Se ha almeno uno (OR) dei ruoli indicati...
            currentUser.appRoleNames.forEach(userRole => {
                roles.forEach(goodRole => {
                    if (userRole === goodRole) {
                        hasAtLeastOne = true;                        
                    }
                });
            });
            return hasAtLeastOne;
        } else {
            return false;
        }
    }
}

export function filterRoutesByLoggedRole(allRoutes) {
    let currentUser = getLoggedUserObject();
    let loggedRoles = currentUser.appRoleNames;
    console.log('logged user roles: ' + JSON.stringify(loggedRoles));
    console.log('Token: ' + this.getLoggedUserJwtToken());
    let allowed = [];
    if (allRoutes) {
        allRoutes.forEach(currentRoute => {
            let currentRouteAllowed = false;
            if (currentRoute.roles) {
                currentRoute.roles.forEach(currentRouteAllowedRole => {
                    loggedRoles.forEach(currentLoggedRole => {
                        if (currentLoggedRole === currentRouteAllowedRole) {
                            currentRouteAllowed = true;
                            return;
                        }                   
                    });
                    if (currentRouteAllowed) {
                        return;
                    }
                });
            }
            if (currentRouteAllowed) {
                allowed.push(currentRoute);
            }
        });
    }
    return allowed;    
}

export function getApplicationName() {
    if (localStorage.getItem(LOGGED_USER_ITEM_NAME) === null) {
        return "REPORTING_SYSTEM";
    } else {
         return JSON.parse(localStorage.getItem(LOGGED_USER_ITEM_NAME)).appName;
    }
}

export function getApplicationDescription() {
    if (localStorage.getItem(LOGGED_USER_ITEM_NAME) === null) {
        return "REPORTING_SYSTEM";
    } else {
         return JSON.parse(localStorage.getItem(LOGGED_USER_ITEM_NAME)).appDescription;
    }
}

export function getBackendApiBaseUrl() {
        return process.env.REACT_APP_BACKEND_HOST;
}

export function getBackendApiLogin() {
        return process.env.REACT_APP_BACKEND_HOST+ '/login';
}

export function getBackendApiLogout() {
        return process.env.REACT_APP_BACKEND_HOST+ '/logout';
}

export function getBackendApiVerifyCode() {
    return process.env.REACT_APP_BACKEND_HOST+ '/verify';
}

export function getBackendApiApplicationLogin() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/authentication/app-login';
}
export function getBackendApiApplicationUsersRoles() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/users/get-all-by-application';
}
export function getBackendApiApplicationRoles() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/roles/get-all-by-application';
}
export function getBackendApiEnableUserByApplication() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/users/enable-by-application';
}
export function getBackendApiDisableUserByApplication() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/users/disable-by-application';
}
export function getBackendApiDeleteUserByApplication() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/users/remove-by-application';
}
export function getBackendApiAddUserToApplication() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/users/signup-by-application';
}
export function getBackendApiUserDetail() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/users/get-details';
}
export function getBackendApiEditUserToApplication() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/users/update-by-application';
}
export function getBackendApiEditProfileToApplication() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/users/edit-profile-by-application';
}
//UTP
export function getBackendApiUtpSubjectList() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/scoring/utp/get-all-requested-by-application';
}
export function getBackendApiUtpPersonDetail() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/scoring/utp/get-last-processed-of-person';
}
export function getBackendApiUtpCompanyDetail() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/scoring/utp/get-last-processed-of-company';
}


export function getBackendApiTeeDiscoveredSubjects() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/get-all-discovered-subjects-from-requests';
}
export function getBackendApiTeeUsersSessions() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/users/sessions/get-by-application';
}
export function getBackendApiTeeNewRequest() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/new-request';
}
export function getBackendApiTeeNewFileRequest() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/new-file-request';
}
export function getBackendApiTeeAccountingList() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/accounting/get-requests';
}
export function getBackendApiTeeExternalSources() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee';
}
export function getBackendApiTeeRequestFileDownload() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/reporting_system/download-file-request';
}

export function getBackendApiTeeRequestFileV2Download() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/reporting_system/download-file-request-v2';
}

export function getBackendApiTeeRequestFileXlsV2Download() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/reporting_system/download-file-request-xls-v2';
}
export function getBackendApiTeeResponseFileDownload() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/reporting_system/download-file-request';
}
export function getBackendApiTeeResponseFilePdfDownload() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/reporting_system/download-file-pdf';
}
export function getBackendApiTeeResponseFilePdfDownloadConservatoria() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/reporting_system/download-file-pdf-conservatoria';
}
export function getBackendApiTeeResponseFileXmlDownload() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/reporting_system/download-file-xml';
}
export function getBackendApiTeeRequestResult() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/search-in-request';
}
export function getBackendApiTeeDiscoveredJuridicalSubjectBoxUpdateUrl() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/update-juridical-subject-box';
}

export function getSuspendedReqAddress() {
    return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/internal/analysis/discovering/tee/process-suspended-requests';
}

export function getBackendApiReportingSystemNewRequest() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/reporting_system/save-data-mail';
}
export function getBackendApiBppbAccountingList() {
        return process.env.REACT_APP_BACKEND_HOST+ '/data-analysis/reporting_system/get-requests';
}

export function getRefreshedToken (){
    return process.env.REACT_APP_BACKEND_HOST + '/data-analysis/internal/analysis/discovering/tee/get-refreshed-token';
}

/*
export function getAuthHeaderAsString() {
    return 'Accept: application/json, ' + 
           'Content-Type: application/json, ' +
           'Authorization' + ': ' + this.getLoggedUserJwtToken();
}
*/

/*
export function logOut() {
    localStorage.removeItem(LOGGED_USER_ITEM_NAME);
    localStorage.removeItem(LOGGED_USER_JWT_TOKEN_ITEM_NAME);
    //return axios.post(BACKEND_API_BASE_URL + 'logout', {}, this.getAuthHeader());
}
*/
