import axios from 'axios';

import * as AuthService from 'services/AuthService.js';


export const HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED = 410; //Mio errore personale definito tramite la classe Java "ResponseEntityExceptionHandlerControllerAdvice"
export const HTTP_FORBIDDEN_ERROR_CODE = 403; //Quando provo ad accedere a qualche risorsa per cui non sono autorizzato, ovvero non accessibile in base al mio ruolo (Spring Security mi blocca)
export const HTTP_UNAUTHORIZED_ERROR_CODE = 401; //Quando provo ad accedere a qualche path non essendo loggato

export let axiosApiCaller = axios.create();

export function setInterceptor(){
    axiosApiCaller = axios.create();
    let headerWithAuth = AuthService.getAuthHeaderAsObj();
    axiosApiCaller.interceptors.request.use(function (config) {    
        //config.data = Object.assign({}, config.data, {applicationName: "TEE"});
        headerWithAuth = AuthService.getAuthHeaderAsObj();
        config.headers = Object.assign({}, config.headers, headerWithAuth);
        return config
    }, function (err) {        
        return Promise.reject(err)
    });
    axiosApiCaller.interceptors.response.use(function (response) {
        // Do something with response data
        if(response.data.config!=undefined){
            localStorage.removeItem("loggedToken")
            localStorage.removeItem("loggedRefreshToken")
            localStorage.setItem("loggedToken",response.data.config.headers.Authorization);
            localStorage.setItem("loggedRefreshToken",response.data.config.headers.TokenRefresh);
        }
        return response;
      }, function (error) {
        console.log(error.response);
       // Do something with response error
       if(error.response && error.response.status === HTTP_UNAUTHORIZED_ERROR_CODE) {
        AuthService.setLoggedUserJwtToken(null);
            AuthService.setLoggedUserJwtTokenRefresh(null);
            return window.location.href = '/login'; //Possiamo mettere una nostra pagina di errore
    }   
    if(error.response && error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED) {
        const originalRequest = error.config;
        originalRequest._retry = true;
        let refreshToken2 = localStorage.getItem("loggedRefreshToken")
        let tokenVecchio2 = localStorage.getItem("loggedToken");
        let username2 = localStorage.getItem("loginEmail");
        return axiosApiCaller.post(AuthService.getRefreshedToken(),{refreshToken:refreshToken2,tokenVecchio:tokenVecchio2,username:username2}).then((rest)=> {
            localStorage.removeItem("loggedToken")            
            localStorage.removeItem("loggedRefreshToken")
            localStorage.setItem("loggedToken",rest.data.payload.jwtToken);
            localStorage.setItem("loggedRefreshToken",rest.data.payload.jwtTokenRefresh);
            AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
            AuthService.setLoggedUserJwtTokenRefresh(rest.data.payload.jwtTokenRefresh);
            originalRequest.headers['Authorization'] = rest.data.payload.jwtToken;
            originalRequest.headers['TokenRefresh'] = rest.data.payload.jwtTokenRefresh; 
            axiosApiCaller.defaults.headers['Authorization'] = rest.data.payload.jwtToken;
            return axiosApiCaller(originalRequest);
        }).catch((error)=>{
            console.log(error.response);
            AuthService.setLoggedUserJwtToken(null);
            AuthService.setLoggedUserJwtTokenRefresh(null);
            return window.location.href = '/home';
        });
    } 
    if(error.response && error.response.status === HTTP_FORBIDDEN_ERROR_CODE) {
        AuthService.setLoggedUserJwtToken(null);
        AuthService.setLoggedUserJwtTokenRefresh(null);
        return window.location.href = '/home'; 
    }     
    // Trow errr again (may be need for some other catch)
    return Promise.reject(error);
    });
}

export function resetAxiosInstance(){
    axiosApiCaller = axios.create();
}
