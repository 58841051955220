import React from 'react';
import {
    Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "layouts/Login";
import Home from "layouts/Home";
import Consolle from "layouts/Consolle.js";
import PrivateRoute from "services/PrivateRoute.js";

import * as AuthService from 'services/AuthService.js';
import { AuthProvider } from "context/AuthContext.js";

import * as AxiosService from 'layouts/AxiosService';

import "assets/css/material-dashboard-react.css?v=1.8.0";


import VerifyCode from "layouts/VerifyCode";
import QRCodePage from "layouts/QRCodePage";

const hist = createBrowserHistory();

class ApplicationMain extends React.Component {

    constructor(props) { 
        super(props);
        AxiosService.setInterceptor();
    }

    componentDidMount() {
    }
    
    componentWillUnmount() { }

    refreshAuthState() {
        if (AuthService.isUserAuthenticathed() === true) {
            //console.log('$$$$$$$$ WAS AUTHENTICATED');
            this.setState({wasAuthenticated: true});
        } else {
            //console.log('$$$$$$$$ NOOOOOOOOOOOOOT AUTHENTICATED');
            this.setState({wasAuthenticated: false});
        }
    }

    render() {
        //console.log('$$$$$$$$ RENDER RENDER RENDER');
        return(
            <AuthProvider>
                <Router history={hist}>
                    <Switch>
                        <Route path="/home" component={Home} />
                        <Route path="/verify-code" component={VerifyCode} />
                        <Route path="/scan-qr-code" component={QRCodePage} />
                        <Route path="/login" component={Login}/>
                        <PrivateRoute path="/consolle" component={Consolle}/>                        
                        <Redirect from="/" to="/home" />
                    </Switch>
                </Router>
            </AuthProvider>
        )
    }
  }

  export default ApplicationMain;